<template>
    <lee-page bg-img="/images/bg_head_5.jpg" height="250" title-class="margin-top-90" title="研究报告" sub-title="RESEARCH REPORT" :search="false" stop-search to-path="/yanjiu/search" show-year search-time-key="push_time">
	    <div class="wrap1100 mg-t-b-50">
		    <el-row :gutter="30">
			    <el-col :span="12">
				    <div class="hide bd-radius-10 pd-20 rel hand" style="background-image: url('/images/bg_3.jpg');background-repeat: no-repeat;background-size: 100% 100%;height: 323px;" @click="$router.push({path:'/yanjiu/list/industry'})">
					    <div class="f-18 c-b">行业发展报告</div>
					    <div class="f-14 c-b">INDUSTRY DEVELOPMENT REPORT</div>
					    <div class="f-14 dark-5 abs" style="bottom:20px;left:20px;">了解更多&gt;</div>
				    </div>
			    </el-col>
			    <el-col :span="12">
				    <div class="hide bd-radius-10 pd-20 rel hand" style="background-image: url('/images/bg_4.jpg');background-repeat: no-repeat;background-size: 100% 100%;height: 323px;" @click="$router.push({path:'/yanjiu/list/domain'})">
					    <div class="f-18 c-b">细分领域报告及资料</div>
					    <div class="f-14 c-b">SUBDIVISION REPORTS AND DATA</div>
					    <div class="f-14 dark-5 abs" style="bottom:20px;left:20px;">了解更多&gt;</div>
				    </div>
			    </el-col>
		    </el-row>
	    </div>
<!--	    <div class="bg-img-11 padding-top-bottom-100">-->
<!--		    <div class="wrap1250">-->
<!--			    <div class="text-center rel hide rel">-->
<!--				    <img src="/images/img-map2.png" height="650"/>-->
<!--				    <div style="width: 370px;height:370px;top:150px;left:120px;" class="abs hand" @click="$router.push({path:'/yanjiu/list/industry'})">-->
<!--					    <img src="/images/icon/icon_56_big.png" class="vm margin-top-80" height="70"/>-->
<!--					    <div class="f-lq f30 light margin-top-30">行业发展报告</div>-->
<!--					    <span class="inline-block border-w-1 border-light border-radius-h margin-top-20" style="width: 30px; height: 30px;"><i class="margin-top-5 f16 light el-icon-right"></i></span>-->
<!--				    </div>-->
<!--				    <div style="width: 370px;height:370px;top:150px;right:120px;" class="abs hand" @click="$router.push({path:'/yanjiu/list/domain'})">-->
<!--					    <img src="/images/icon/icon_55_big.png" class="vm margin-top-80" height="70"/>-->
<!--					    <div class="f-lq f30 dark-0 margin-top-30">研究报告及资料</div>-->
<!--					    <span class="inline-block border-w-1 border-light border-radius-h margin-top-20" style="width: 30px; height: 30px;"><i class="margin-top-5 f16 dark-0 el-icon-right"></i></span>-->
<!--				    </div>-->
<!--			    </div>-->
<!--		    </div>-->
<!--	    </div>-->
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    methods:{

    }
}
</script>
