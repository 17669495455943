<template>
	<lee-page bg-img="/images/bg_head_6.jpg" title="问答中心" height="250" title-class="margin-top-70" sub-title="QUESTION AND ANSWER CENTER" search :show-search-type="false">
<!--		<div class="margin-top-50 margin-auto" style="width: 866px" slot="search">-->
<!--			<lee-search @search="onSearch" @clear="clear"></lee-search>-->
<!--		</div>-->
		<div class="wrap1100 mg-t-50 mg-b-100">
			<div class="cl">
				<lee-tabs class="z" :list="orderByList" :default-index="searchForm.orderBy" style="width: 300px;"></lee-tabs>
				<div class="y">
					<el-button type="primary" icon="iconfont icon-mashangtiwen vm mg-r-5 f-14" size="mini" @click="toShowAddQuestion">马上提问</el-button>
					<el-button type="primary" icon="iconfont icon-chakan vm mg-r-5 f-14" size="mini" @click="$router.push({path:'/qa/me'})">我的提问</el-button>
					<el-button type="primary" icon="iconfont icon-huida vm mg-r-5 f-14" plain size="mini" @click="$router.push({path:'/qa/myanswer'})">我的回答</el-button>
				</div>
			</div>
			<div class="mg-t-20">
				<template v-if="list.length > 0">
<!--					<div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{ total }}</b> 条相关内容</div>-->
					<div class="bg-gray-5 mg-b-20 qa-list hide" v-for="(item,index) in list">
						<div :class="index === activeIndex ? 'on' : ''" class="title cl padding-20" style="height: 80px;line-height: 40px;">
							<img src="/images/icon/icon_q.png" height="30" class="z margin-top-5"/>
							<span class="z text-line-1 margin-left f16 dark-1" v-html="(parseFloat(item.price) > 0 ? '<span class=\'f12 bg-red border-radius light padding-5 margin-right\'>奖励金额：￥<b class=\'f14\'>'+item.price+'</b></span>' : '') + '问题标题：'+item.title" style="max-width: 850px;"></span>
							<div class="y">
								<span class="inline-block vm orange margin-right hand" @click="toDel(item.id)" v-if="item.uid === uid">
									<i class="el-icon-delete"></i>
									删除
								</span>
								<span class="inline-block vm blue hand" @click="activeIndex = index">
									<i :class="index === activeIndex ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
									详情
								</span>
							</div>
						</div>
						<div class="" :class="index === activeIndex ? '' : 'none'">
							<div class="padding-20" v-html="'问题描述：'+item.content"></div>
							<div class="bg-brand-5 padding-top-bottom padding-left-right-20" v-if="item.answer_list.length > 0">
								<div>
									<div class="dark-2">回答列表：</div>
									<div class="cl border-bottom-1 border-dark-6" v-for="(t,i) in item.answer_list">
										<div class="z padding-top-bottom" style="max-width: 800px" v-html="(i+1)+'. ' + (t.uid === uid ? '<b class=\'orange f-n margin-right\'>[你的回答]</b>' : '') + t.content+(t.bid_state === 0 && t.uid !== uid ? '......<b class=\'dark-3 f-n\'>[需购买]</b>' : '')"></div>
										<div class="y">
											<el-button class="orange" type="text" icon="el-icon-delete" @click="toDelAnswer(t.id)" v-if="t.uid === uid">
												删除
											</el-button>
											<el-button class="gablue" type="text" icon="el-icon-goods" @click="toBuyAnswer(t,item)" v-if="t.uid !== uid && t.bid_state === 0">
												购买
											</el-button>
										</div>
									</div>
								</div>
							</div>
							<div class="text-center mg-t-b-10" v-if="item.self_answer === 0">
								<el-button size="medium" type="primary" class="box-shadow-blue" @click="toShowAddAnswer(item)">立即回答
								</el-button>
							</div>
						</div>
					</div>
					<div class="text-center margin-top-100">
						<el-pagination
							background
							layout="prev, pager, next"
							:page-size="pageSize"
							:current-page.sync="searchForm.page"
							:total="total"
							@current-change="pageChange">
						</el-pagination>
					</div>
				</template>
				<template v-else>
					<div class="text-center padding-top-bottom-40 f20 dark-3">
						未查询到相关内容
					</div>
				</template>
			</div>
		</div>
		<el-dialog
			title="提问"
			:visible.sync="showAddQa">
			<el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" :show-message="true" :inline-message="true">
				<el-form-item label="" prop="title" class="margin-bottom-0">
					<div class="blue">问题标题</div>
					<el-input v-model="formData.title" class="lee-input mobile" placeholder="输入标题"></el-input>
				</el-form-item>
				<el-form-item label="" prop="content" class="margin-bottom-0">
					<div class="blue">问题描述</div>
					<div class="bg-gray-4 cl">
						<el-input
							class="lee-textarea"
							type="textarea"
							:rows="4"
							placeholder="请输入内容"
							v-model="formData.content">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item label="" prop="price" class="margin-bottom-0">
					<div class="blue">奖励金额(元)：</div>
					<el-input v-model="formData.price" class="lee-input mobile" placeholder="输入价格"></el-input>
				</el-form-item>
			</el-form>
			<div class="cl" slot="footer">
				<el-button size="medium" type="primary" class="box-shadow-blue" @click="addQuestion('form')">提交确认</el-button>
			</div>
		</el-dialog>
		<el-dialog title="回答" :visible.sync="showAddAnswer" :close-on-click-modal="false" :close-on-press-escape="false">
			<el-form :model="answerForm" status-icon :rules="answerformrules" ref="answerform" label-width="auto" :show-message="true" :inline-message="true">
				<el-form-item label="" prop="title" class="margin-bottom-0">
					<div class="dark-1">问题标题：{{ answerForm.title }}</div>
					<div class="dark-2" v-if="answerForm.qa_content">问题描述：{{ answerForm.qa_content }}</div>
				</el-form-item>
				<el-form-item label="" prop="content" class="margin-bottom-0">
					<div class="blue">回答内容</div>
					<div class="bg-gray-4 cl">
						<el-input
							class="lee-textarea"
							type="textarea"
							:rows="4"
							placeholder="请输入内容"
							v-model="answerForm.content">
						</el-input>
					</div>
				</el-form-item>
			</el-form>
			<div class="cl" slot="footer">
				<el-button size="medium" type="info" plain class="box-shadow-30" @click="showAddAnswer = false">关闭</el-button>
				<el-button size="medium" type="primary" class="box-shadow-blue" @click="addAnswer('answerform')">提交确认
				</el-button>
			</div>
		</el-dialog>
		<el-dialog
			title="支付"
			:visible.sync="show" @close="closePay">
			<lee-pay-view :name="buyData.title" :price="buyData.price" title="购买答案" sub-title="答案金额" file-size="" :qrcode="'data:image/jpeg;base64,'+qrcode" v-if="buyData"></lee-pay-view>
		</el-dialog>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		var checkPrice = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.ruleForm.pass) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			activeIndex: 0,
			show: false,
			showAddQa: false,
			showAddAnswer: false,
			buyData: null,
			qrcode: '',
			time: null,
			order_id: '',
			formData: {
				content: '',
				title: '',
				price: '',
			},
			answerForm: {
				tid: '',
				content: '',
				title: '',
				qa_content: ''
			},
			keyword: '',
			list: [],
			pageSize: 10,
			total: 0,
			orderByList: [
				{label: '最新问题', value: 'create_time', icon: 'icon-icon_xinyong_xianxing_jijin-1'},
				{label: '热门问题', value: 'answer_count', icon: 'icon-icon_xinyong_xianxing_jijin-'},
				// {title: '最新问题', value: 'create_time'},
				// {title: '热门问题', value: 'answer_count'},
			],
			typeList: [],
			searchForm: {
				page: 1,
				title: '',
				title_type: '',
				orderBy: 'create_time',
				sortBy: 'desc'
			},
			rules: {
				title: [{required: true, message: '请输入问题标题'}],
				content: [{required: true, message: '请输入问题内容'}],
				price: [
					{required: true, message: '请输入奖励金额'},
					{message: '请填写正确的金额', pattern: this.$regexp.money},
					{ validator: checkPrice, message: '奖励金额必须大于1元'}
				],
			},
			answerformrules: {
				tid: [{required: true, message: '请选择问题'}],
				content: [{required: true, message: '请输入回答内容'}],
			},
			uid: this.$user.getUid()
		}
	},
	created() {
		if (!this.$utils.isEmptyObject(this.$route.query)) {
			this.searchForm = {...this.$route.query};
		}
		
		this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
		this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
		this.searchForm.orderBy = this.$route.query.mmtype ? this.$route.query.mmtype : 'create_time';
	},
	mounted() {
		this.getList();
		// this.getTypeList();
	},
	methods: {
		toShowAddQuestion() {
			if (!this.uid) {
				this.$message.error('请先登录');
				return;
			}
			
			this.formData.content = '';
			this.formData.title = '';
			this.formData.price = '';
			
			this.showAddQa = true;
		},
		closePay() {
			this.show = false;
			clearInterval(this.time);
			this.time = null;
			this.qrcode = '';
			this.order_id = '';
		},
		toBuyAnswer(e, e2) {
			// this.show = true;
			this.buyData = e;
			
			this.buyData.price = e2.price;
			
			this.getPayCode();
		},
		getPayCode() {
			let _this = this;
			this.$http.post({
				url: '/Order/getPayCode',
				data: {id: this.buyData.id, order_type: 'answer'},
				success(res) {
					_this.qrcode = res.qrcode;
					_this.order_id = res.order_id;
					_this.show = true;
					_this.timeDown('checkState');
				}
			})
		},
		timeDown(type) {
			if (type === 'checkState') {
				this.time = setInterval(this.checkState, 3000)
			}
		},
		checkState() {
			let _this = this;
			this.$http.post({
				url: '/Common/checkOrder',
				data: {id: this.order_id},
				success(res) {
					if (res.state === 2) {
						clearInterval(_this.time);
						_this.time = null;
						_this.show = false;
						_this.$message.success('支付成功');
						
						_this.getList();
					}
				}
			})
		},
		toShowAddAnswer(e) {
			if (!this.uid) {
				this.$message.error('请先登录');
				return;
			}
			
			if (this.uid) {
				if (e.uid === this.uid) {
					this.$message.error('不能回答自己的提问');
					return;
				}
			}
			
			this.answerForm.content = '';
			// this.answerForm.price = '';
			this.answerForm.title = e.title;
			this.answerForm.qa_content = e.content;
			this.answerForm.tid = e.id;
			
			this.showAddAnswer = true;
		},
		toDel(id) {
			let _this = this;
			this.$confirm('是否删除此问题?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post({
					url: '/Question/del',
					data: {id: id},
					success(res) {
						_this.$message.success('删除成功');
						_this.getList();
					}
				})
			})
		},
		toDelAnswer(id) {
			let _this = this;
			this.$confirm('是否删除此回答内容?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post({
					url: '/AuthAnswer/del',
					data: {id: id},
					success(res) {
						_this.$message.success('删除成功');
						_this.getList();
					}
				})
			})
		},
		changeQaType(v) {
			if (this.$isMobile) {
				this.searchForm.orderBy = this.orderByList[v].value;
			} else {
				this.searchForm.orderBy = v.value;
			}
			
			this.searchForm.page = 1;
			
			this.getList();
		},
		pageChange(page) {
			this.searchForm.page = page;
			this.getList();
		},
		getList() {
			let _this = this;
			this.$http.post({
				url: '/Question/listPage',
				data: {order_by: this.order_by, pageSize: this.pageSize, ...this.searchForm},
				success(res) {
					_this.list = res.list;
					_this.total = res.totalCount;
				}
			})
		},
		getTypeList() {
			let _this = this;
			this.$http.post({
				url: 'question/type',
				data: null,
				success(res) {
					if (res.data) {
						for (let i = 0; i < res.data.length; i++) {
							_this.typeList.push({label: res.data[i].name, value: res.data[i].id.toString()});
						}
					}
				}
			})
		},
		addAnswer(formName) {
			let _this = this;
			this.$refs[formName].validate(valid => {
				if (valid) {
					_this.$http.post({
						url: '/AuthAnswer/add',
						data: _this.answerForm,
						success(res) {
							_this.answerForm.content = '';
							// _this.answerForm.price = '';
							_this.answerForm.title = '';
							_this.answerForm.qa_content = '';
							_this.answerForm.tid = ''
							
							_this.showAddAnswer = false;
							
							_this.getList();
							
							_this.$message.success('回答成功');
						}
					})
				}
			})
		},
		addQuestion(formName) {
			let _this = this;
			this.$refs[formName].validate(valid => {
				if (valid) {
					_this.$http.post({
						url: '/Question/add',
						data: _this.formData,
						success(res) {
							_this.searchForm.page = 1;
							_this.formData = {title: '', content: '', price: ''};
							_this.showAddQa = false;
							// _this.$router.replace({query:{...this.searchForm}});
							_this.getList();
						}
					})
				} else {
					if (_this.$isMobile) {
						_this.$toast.fail('请填写完整信息');
					} else {
						_this.$message.error('请填写完整信息');
					}
				}
			})
		},
		clear() {
			this.$router.replace({query: {keyword: ''}});
			this.getList();
		},
		onSearch(res) {
			this.keyword = res;
			let _this = this;
			
			if (this.keyword) {
				_this.$http.post({
					url: '/Question/search',
					data: {keyword: this.keyword.keyword, current_page: this.page},
					success(res) {
						if (res.data) {
							_this.list = res.data;
							_this.total = res.total;
						}
					}
				})
			} else {
				this.getList();
			}
		}
	}
}
</script>
