<template>
	<div>
		<div class="bg-light box-shadow-base pd-10" v-if="data">
			<div class="lh-40 f-14 bd-b-w-1 bd-dark-7 bd-style-solid">数据平台行业数据分析报告—医药冷链</div>
			<div class="mg-t-10 f-14 dark-3">近几年，医药冷链市场规模持续扩大，据分会推算，{{nowYear}}年医药冷链销售总额约为{{data.now.total_income}}亿元，与{{nowYear - 1}}年相比，同比{{parseFloat(data.now.income_rate) >= 0 ? '增长' : '减少'}}{{ Math.abs(data.now.income_rate)}}%。按照15%的复合增长率计算，预计{{nowYear + 1}}年医药冷链市场规模约为{{ (parseFloat(data.now.total_income) * 1.15).toFixed(2)}}亿元。根据不同产品占比来看，{{data.now.values[0].name}}生物制品占比最高，达到{{ (parseFloat(data.now.values[0].val.income_percent) * 100).toFixed(2)}}%。从品类划分来看，疫苗和血液制品都属于生物制品，为了更清晰统计各细分品类占比，故将疫苗和血液制品单独进行统计。</div>
		</div>
		<div class="mg-t-30 bg-light box-shadow-base pd-30">
			<lee-bar-echarts
				ref="bar10"
				height="400px"
				width="1000px"
				:show-legend="false"
				:show-right="false"
				:bar-width="40"
			></lee-bar-echarts>
		</div>
	</div>
</template>

<script>
export default {
    name: "IndexHyData5",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // },
        type:{
            type:String,
            default:''
        }
    },
    created() {

    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/Hydata/getYiyaoYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear,endYear){
            let _this = this;

            if(this.type) {
                this.$http.post({
                    url: '/Hydata/getTypeData',
                    data: {year: startYear, type: this.type, yearlist: [startYear, endYear]},
                    success(res) {
                        _this.data = res;

                        let _bar10 = {bar:[],bar2:[],xaxis:[]};
                        let _list10_now = res.now;
                        let _list10_last = res.last;

                        if(_list10_now && _list10_last) {
                            for (let i = 0; i < _list10_last.values.length; i++) {
                                _bar10.bar.push(_list10_last.values[i].val.income);
                                _bar10.xaxis.push(_list10_last.values[i].name);
                            }

                            for (let i = 0; i < _list10_now.values.length; i++) {
                                _bar10.bar2.push(_list10_now.values[i].val.income);
                            }

                            if (_this.$refs.bar10) {
                                _this.$refs.bar10.initData(
                                    [
                                        {
                                            name: (parseInt(_this.nowYear) - 1).toString(),
                                            type: 'bar',
                                            color: 'blue',
                                            data: _bar10.bar,dw:'亿元'
                                        },
                                        {name: _this.nowYear.toString(), type: 'bar', color: 'green', data: _bar10.bar2,dw:'亿元'},
                                    ],
                                    _bar10.xaxis
                                );
                            }
                        }
                    }
                })
            }
        },
    }
}
</script>
