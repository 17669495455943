<template>
    <span class="bg-blue f14 light padding border-radius-5 hand inline-block" @click="toUrl">
        <i class="margin-right" :class="leftIcon"></i>
        附件：{{filename}}
        <i class="margin-left" aria-hidden="true" :class="rightIcon"></i>

        <login-dialog v-model="show"></login-dialog>
    </span>
</template>

<script>
export default {
    name: "VipFileDown",
  data(){
    return{
      show:false
    }
  },
    props:{
        filename:String,
        url:String,
        leftIcon:{
            type:String,
            default:'el-icon-tickets'
        },
        rightIcon:{
            type:String,
            default:'fa fa-download'
        },
      tid:{
        type:String,
        default:''
      },
      akey:{
        type:String,
        default:''
      },
    },
    methods:{
      toUrl(check = true){
        let _this = this;
        if(this.url) {
          this.$http.post({
            url:'/Files/checkVip',
            data:{tid:this.tid,akey:this.akey,check:check ? '1' : '0'},
            success(res){
              console.log(res);
              if(res.type === 'ok'){
                if(check){
                  if(res.data.total !== 999999) {
                    _this.$confirm('当前还能下载' + (parseInt(res.data.total - res.data.now)) + '次'+(res.data.vip_title ? '('+res.data.vip_title+')' : '')+'，是否下载？', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      _this.toUrl(false);
                    }).catch(() => {
                    });
                  }else{
                    _this.$confirm('是否下载该文件？', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      _this.toUrl(false);
                    }).catch(() => {
                    });
                  }
                }else {
                  window.open(_this.url.indexOf('http') >= 0 ? _this.url : _this.$config.assetsUrl + _this.url, '_blank');
                }
              }else{
                _this.$confirm(res.msg, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  if(res.type === 'login'){
                    _this.show = true;
                  }else if(res.type === 'count'){
                    _this.$router.push({path:'/user/vip'});
                  }
                }).catch(() => {});
              }
            }
          })
        }
      }
    }
}
</script>
