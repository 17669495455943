<template>
	<lee-page bg-img="/images/bg_head_5.jpg" title="细分领域报告及资料" sub-title="SUBDIVISION REPORTS AND DATA" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'细分领域报告及资料',url:'/yanjiu/list/domain'},{title:data && data.title ? data.title : ''}]" :height="250" title-class="margin-top-90" show-year :search="false" search-time-key="push_time">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<div v-if="data">
				<div class="cl"></div>
				<div class="bg-gray-5 margin-top-30 padding-30">
					<div class="green-line">
						<div class="cl f30">
							<img src="/images/icon/icon-36.png" height="22" class="no-v-mid"/>
							{{data.title}}
						</div>
						<el-row :gutter="20" class="dark-3 margin-top f14">
							<el-col :md="5" :xs="12" v-if="data.department">发布机构：{{data.department.name}}</el-col>
							<el-col :md="5" :xs="12" v-if="data.push_time">发布时间：{{data.push_time}}</el-col>
							<el-col :md="5" :xs="12" v-if="data.doc_number">文号：{{data.doc_number}}</el-col>
						</el-row>
					</div>
					<div class="bg-light pd-20" v-if="data.des">
						<div class="dark-4 f-14">内容简介：</div>
						<div class="f16 dark-5 base-content mg-t-10" style="white-space: pre-wrap;" v-html="data.des" v-if="data.des">{{data.des}}</div>
					</div>
					<vip-check-box :data="data" type="domain" akey="domain" @paydone="getDetail" pay-title="购买报告" pay-sub-title="报告金额"></vip-check-box>
<!--					<div class="f16 dark-1 base-content" v-html="data.html_content ? data.html_content : ''" v-if="data.check.type === 'ok'"></div>-->
<!--					<template v-if="data.check.type === 'ok'">-->
<!--						<div class="margin-top-20" v-if="data.files.length > 0">-->
<!--							<lee-vip-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom" akey="domain" :tid="data.id"></lee-vip-file-down>-->
<!--						</div>-->
<!--					</template>-->
<!--					<vip-check-button :datas="data" :check="data.check" :price="data.price" :title="data.title" pay-title="购买报告" pay-sub-title="报告金额" type="domain" @paydone="getDetail" v-else></vip-check-button>-->
					<lee-good-view akey="domain" :id="data.id" class="margin-top-30" :open-like="data.open_like === 1" :open-favorite="data.open_favorite === 1"></lee-good-view>
					<div class="margin-top-30" v-if="data.open_comment">
						<lee-comment-view akey="domain" :id="data.id"></lee-comment-view>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="text-center padding-top-bottom-40" v-if="httpCode === 401">
					{{message}}
				</div>
			</div>
		</div>
  </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            id:'',
            data:null,
            httpCode:200,
            message:'',
        }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        if(this.id){
            this.getDetail();
        }
    },
    methods:{
        getDetail(){
            let _this = this;
            this.$http.post({
                url:'/Report/detail',
                data:{id:this.id},
                success(res){
                    _this.data = res;

                    // if(res.type === 'domain' && res.bid_state > 1){
                    //     _this.timeDown('checkState');
                    // }
                }
            })
        },
    }
}
</script>
