<template>
    <div id="china" :style="{width:width,height:height}"></div>
</template>

<script>
export default {
    name: "ChinaEcharts",
    props:{
        width:{
            type:[String,Number],
            default:'100%'
        },
        height:{
            type:[String,Number],
            default:'600px'
        }
    },
    data(){
        return{
            charts:null,
            zoom:1,
            maxZoom:5,
            minZoom:1,
            option:{
                grid:{
                    top:0,
                    bottom:0
                },
                tooltip: {
                    trigger: 'item',
                    showDelay: 0,
                    transitionDuration: 0.2,
                    // formatter: '{b}<br/>{c} (p / km2)'
                    formatter: function (params) {
                        console.log(params)
                        let _name = params.name;

                        if(params.data) {
                            let _data = params.data;

                            let _value = _data.value;

                            let _vals = [];

                            for (let i = 0; i < _value.length; i++) {
                                _vals.push(_value[i].name + ':' + _value[i].value);
                            }

                            let _valStr = _vals.join('<br/>');
                            return _name + '<br/>' + _valStr;
                        }else{
                            return _name + '<br/>' + '测试数据：2232<br/>测试数据：2232<br/>测试数据：2232';
                        }
                    }
                },
                toolbox: {
                    show: true,
                    orient: 'vertical',
                    right:0,
                    top:'middle',
                    itemSize:40,
                    feature: {
                        myscaleInc: {
                            show: true,
                            title: '放大',
                            icon: 'image://'+require('@/assets/images/icon_plus.png'),
                            onclick:()=>{
                                this.scaleInc();
                            }
                        },
                        myscaleDec: {
                            show: true,
                            title: '缩小',
                            icon: 'image://'+require('@/assets/images/icon_dec.png'),
                            onclick:()=>{
                                this.scaleDec();
                            }
                        }
                    }
                },
                itemStyle: {
                    normal: {
                        areaColor: '#323c4800', //地图颜色
                        borderColor: '#0258b6',
                        borderWidth: 1
                    },
                    // emphasis: {
                    //   areaColor: '#2a333d'
                    // }
                },
                series: [
                    {
                        // showLegendSymbol:true,
                        name: '',
                        type: 'map',
                        roam: 'move',
                        map: 'China',
                        emphasis: {
                            label: {
                                show: true
                            }
                        },
                        label:{
                            // show:true
                        },
                        itemStyle: {
                            areaColor: '#dfedd6', //地图颜色
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        // nameProperty: 'id',
                        // 文本位置修正
                        textFixed: {
                            42: [20, -20]
                        },
                        data:[
                            {name: '浙江省', value: [{name:'苹果',value:4822023},{name:'樱桃',value:4822023},{name:'西瓜',value:4822023}]}
                        ],
                        nameMap: {
                            '42': '湖北省',
                        }
                    }
                ]
            }
        }
    },
    created() {
        this.option.series[0].zoom = this.zoom;

        this.option.series[0].roam = this.$isMobile ? true : 'move';

        if(this.$isMobile){
            this.option.toolbox.feature = {};
        }
    },
    mounted() {
        this.myEcharts();
    },
    methods:{
        scaleInc(){
            console.log('放大');
            this.zoom += 1;

            this.charts.setOption({series:{zoom:this.zoom >= this.maxZoom ? this.maxZoom : this.zoom}});
        },
        scaleDec(){
            console.log('放大');
            this.zoom -= 1;

            this.charts.setOption({series:{zoom:this.zoom <= this.minZoom ? this.minZoom : this.zoom}});
        },
        myEcharts(){
            console.log(this.$china);
            // let jss = require('../assets/js/china');
            // console.log(jss);
            // 基于准备好的dom，初始化echarts实例
            this.charts = this.$echarts.init(document.getElementById('china'));

            this.$echarts.registerMap('China', this.$china);
            //指定图表的配置项和数据


            // 使用刚指定的配置项和数据显示图表。
            this.charts.setOption(this.option);
        }
    }
}
</script>
