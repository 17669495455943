<template>
	<div>
		<div class="bg-light box-shadow-base pd-10" v-if="data">
			<div class="lh-40 f-14 bd-b-w-1 bd-dark-7 bd-style-solid">数据平台行业数据分析报告—医药终端</div>
			<div class="mg-t-10 f-14 dark-3">{{nowYear}}年度我国公立医院、零售药店和公立基层医疗三大终端销售金额{{data.now.total_income}}亿元，同比{{nowYear-1}}年{{data.now.income_rate >= 0 ? '增长' : '减少'}}{{Math.abs(data.now.income_rate)}}%，终端销售增速持续放缓。</div>
		</div>
		<div class="mg-t-30 bg-light box-shadow-base pd-30">
			<lee-bar-echarts
				ref="bar4"
				height="400px"
				width="1000px"
				:show-legend="false"
				:show-right="false"
				:bar-width="40"
			></lee-bar-echarts>
		</div>
	</div>
</template>

<script>
export default {
    name: "IndexHyData3",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // },
        type:{
            type:String,
            default:''
        }
    },
    created() {

    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/Hydata/getYiyaoYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear,endYear){
            let _this = this;

            if(this.type) {
                this.$http.post({
                    url: '/Hydata/getTypeData',
                    data: {year:startYear, type: this.type, yearlist: [startYear, endYear]},
                    success(res) {
                        _this.data = res;

                        let _bar4 = {bar:[],line:[],xaxis:[]};
                        let _list4 = res.list;

                        for(let i=0;i < _list4.length;i++) {
                            _bar4.bar.push(_list4[i].total_income);
                            _bar4.line.push(_list4[i].income_rate);
                            _bar4.xaxis.push(_list4[i].year.toString());
                        }

                        if (_this.$refs.bar4) {
                            _this.$refs.bar4.initData(
                                [
                                    {name: '市场规模', type: 'bar', color: 'blue', data: _bar4.bar,dw:'亿元'},
                                    // {name: '增长率', type: 'line', color: 'purple', data: _bar4.line,dw:'%'},
                                ],
                                _bar4.xaxis
                            );
                        }
                    }
                })
            }
        },
    }
}
</script>
