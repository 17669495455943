<template>
	<lee-page bg-img="/images/bg_head_1.jpg" title="活动专栏" sub-title="CONTENT COLUMN" height="250" title-class="margin-top-70" :bread="[{title:'活动专栏',url:'/zhuanlan'},{title:'会议介绍',url:'/zhuanlan/detail?id='+id},{title:'会后总结'}]" v-if="info">
		<div class="wrap1100" v-if="info">
			<div class="pd-30" style="background: #F5F7FB;">
				<div class="green-line">
					<div class="cl f34">
						<img src="/images/icon/icon-999.png" height="22" class="no-v-mid margin-right"/>{{ info.title }}
					</div>
				</div>
				<div class="f16 dark-1 base-content" v-html="info.html_content ? info.html_content : ''"></div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "articledetail",
	data() {
		return {
			id:'',
			info:null
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		if(this.id){
			this.getDetail();
		}else{
			this.$alert('会后总结内容不存在 或 被删除', '提示', {
				confirmButtonText: '确定',
				closeOnClickModal:false,
				closeOnPressEscape:false,
				showClose:false,
				callback: action => {
					this.$router.back();
				}
			});
		}
	},
	methods: {
		getDetail() {
			let _this = this;
			
			this.$http.post({
				url: '/Article/detail',
				data: {extid:this.id},
				success(res) {
					if(res) {
						_this.info = res;
					}else{
						_this.$alert('会后总结内容不存在 或 被删除', '提示', {
							confirmButtonText: '确定',
							closeOnClickModal:false,
							closeOnPressEscape:false,
							showClose:false,
							callback: action => {
								_this.$router.back();
							}
						});
					}
				}
			});
		},
	}
}
</script>
