<template>
<!--	<div class="bg-light cl box-shadow-bottom">-->
<!--		<div class="inline-block text-center hy-data-item" v-for="(item,index) in list" style="width: 20%;" :class="{on:dataIndex === index}">-->
<!--			<span @click="dataIndex = index">{{item.title}}</span>-->
<!--		</div>-->
<!--	</div>-->
    <el-row class="bg-light cl box-shadow-bottom hide">
        <el-col :span="24 / list.length" v-for="(item,index) in list">
	        <div class="text-center hy-data-item" :class="{on:type === item.value}">
		        <span @click="change(item)"><i class="iconfont mg-r-5" :class="item.icon"></i>{{item.label}}</span>
	        </div>
<!--            <div class="text-center hy-data-item hand" :class="type === item.value ? 'on' : ''" @click="change(item)">-->
<!--&lt;!&ndash;                <img :src="item.icon+(type === item.value ? 'on' : 'off') + '.png'" height="20" class="margin-right"/>&ndash;&gt;-->
<!--                {{item.label}}-->
<!--            </div>-->
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "Tabs",
    data(){
        return{
            type:''
        }
    },
    props:{
        list:{
            type:Array,
            default:()=>{
                return []
            }
        },
        defaultIndex:{
            type:[String,Number],
            default:''
        },
        activeClass:{
            type:String,
            default:'bg-blue-line'
        }
    },
    created() {
        if(this.defaultIndex){
            this.type = this.defaultIndex;
        }else{
            if(this.list.length > 0){
                this.type = this.list[0].value;
            }
        }

      if(this.$route.query.mmtype){
        this.type = this.$route.query.mmtype;
      }
      console.log(this.type);
      // this.$emit('change',{value:this.type})
    },
    methods:{
        change(item){
            // this.$emit('change',item);
            this.type = item.value;
          this.$router.replace({query:{mmtype:item.value}})
          // this.$emit('change',{value:this.type})
        },
    },
	watch:{
		defaultIndex(n,o){
			this.type = n;
		}
	}
}
</script>
