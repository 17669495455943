<template>
    <lee-user-main-page type="dark">
        <lee-user-page class="padding-bottom-100">
            <lee-safe-progress :percentage="100"></lee-safe-progress>
            <lee-base-cell img="/images/icon/icon_32.png" title="登录密码" sub-title="互联网帐号存在被盗风险，建议您定期更改密码以保护帐号安全。" right-title="修改" sub-title-class="red" @rightClick="$router.push({path:'/user/safe/changepwd'})"></lee-base-cell>
            <lee-base-cell img="/images/icon/icon_33.png" title="邮箱" :sub-title="$user.getEmail() ? '你的邮箱：'+$user.getEmail() : '尚未设置邮箱'" right-title="修改" sub-title-class="dark-2" @rightClick="$router.push({path:'/user/profile'})"></lee-base-cell>
            <lee-base-cell img="/images/icon/icon_34.png" title="手机" :sub-title="'你的手机：'+$utils.secretStr($user.getMobile())" right-title="修改" sub-title-class="dark-2" @rightClick="$router.push({path:'/user/profile'})"></lee-base-cell>
        </lee-user-page>
    </lee-user-main-page>
</template>

<script>
export default {
    name: "SafeIndex",
    data(){
        return{
            progress:0
        }
    },
    mounted() {
        // this.getStrong();
    },
    methods:{
        getStrong(){
            let _this = this;
            this.$http.post({
                url: 'user/secure',
                data: null,
                success(res) {
                    _this.progress = res.strong * 100;
                }
            });
        }
    }
}
</script>
