<template>
    <div :id="id" :style="{height:height,width:width}"></div>
</template>

<script>
export default {
    name: "PidEcharts",
    data(){
        return{
            id:'echarts',
            option:{
                title:{
                    text:'',
                    left:'center',
                    show:false,
                    textStyle:{
                        fontWeight:'normal',
                        fontSize:18
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c} ({d}%)'
                },
                grid:{
                    top:20,
                    containLabel: true
                },
                legend: null,
                color: ["#7ED321", "#5C87F6", "#FBC531", "#E74218", "#02C5C6", "#9980F9","#E056FD"],
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: ['0%','70%'],
                        data: [],
                        label: {
                            formatter: '{d}%',
                            position:'outer',
                            fontSize:16,
                          alignTo:'labelLine'
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    props:{
        width:String,
        height:String,
        title:String,
        radius:{
            type:Array,
            default(){
                return ['0%','70%']
            }
        },
        showLegend:{
            type:Boolean,
            default:true
        },
        legendOrient:{
            type:String,
            default:'horizontal'
        },
        labelPos:{
            type:String,
            default:'inside'
        },
        labelFormat:{
            type:String,
            default:'{d}%'
        },
        titlePos:{
            type:String,
            default:'top'
        },
        titleSize:{
            type:Number,
            default:18
        },
        labelSize:{
            type:Number,
            default:14
        },
        showTitle:{
            type:Boolean,
            default:true
        },
        data:{
            type:Array,
            default(){
                return []
            }
        },
        legendPos:{
            type:String,
            default:'bottom'
        },
        gridBottom:{
            type:Number,
            default:30
        }
    },
    created() {
        this.$echarts.set

        this.id = this.$utils.randomWords(8);

        if(this.titlePos === 'top'){
            this.option.title.top = 0;
        }else if(this.titlePos === 'bottom'){
            this.option.title.bottom = 0;
        }

        this.option.series[0].label.formatter = this.labelFormat;
        this.option.series[0].radius = this.radius;
        this.option.series[0].label.position = this.labelPos;

        this.option.title.textStyle.fontSize = this.titleSize;

        this.option.grid.bottom = this.gridBottom;

        if(this.title) {
            this.option.title.text = this.title;
            this.option.grid.top = 40;
        }

        this.option.title.show = this.showTitle;
        this.option.series[0].label.fontSize = this.labelSize
        this.option.series[0].data = this.data;

        // this.option.tooltip.formatter = (params)=>{
        //   let relVal = params[0].name;
        //   for (let i = 0, l = params.length; i < l; i++) {
        //     relVal += '<div style="display:flex;flex-direction: row;align-items: center;justify-content: space-between;"><span style="flex:1;margin-right:10px;">' + params[i].marker + (params[i].seriesName ? params[i].seriesName : params[i].name) + ' : </span><span style="flex:1;text-align: right;">' + params[i].value + '</span>'+ (data[i] && data[i].dw ? data[i].dw : '') +'</div>'
        //   }
        //   return relVal;
        // }

    },
    mounted() {
        this.echarts = this.$echarts.init(document.getElementById(this.id));
        this.echarts.setOption(this.option,true);
    },
    methods:{
        initData(data,dw){
            // console.log(data);
            if(data.length > 0){
                let _legend = [];
                for (let i = 0;i < data.length;i++){
                    let _data = data[i];

                    _legend.push(_data.name);
                }

                if(this.showLegend) {
                    this.option.legend = this.$echartsJs.getLegend(_legend,this.legendPos,this.legendOrient);
                }

                this.option.series[0].data = data;

                this.option.tooltip.formatter = '{b} : {c} '+(dw ? dw : '')+' ({d}%)'

            }

            this.echarts.setOption(this.option,true);
        }
    }
}
</script>
