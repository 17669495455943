<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="12" class="f16 dark-1" v-if="data">
            近年来，一系列政策频繁调整，驱动着医疗器械流通变革。中物联医疗器械供应链分会经公开数据整理，据不完全统计推算，{{nowYear}}年我国医疗器械物流总费用约为{{data.now.total_profit}}亿元，突破百亿市场容量。其中，{{data.now.values2[0].name}}领域物流费用最高，达{{data.now.values2[0].val.profit}}亿元，{{data.now.values2[1].name}}领域物流费用次之，为{{data.now.values2[1].val.profit}}亿元，{{data.now.values2[2].name}}和{{data.now.values2[3].name}}分别为{{data.now.values2[2].val.profit}}亿元、{{data.now.values2[3].val.profit}}亿元。我国医疗器械市场发展潜力巨大，物流市场规模也将随之持续扩大。
        </el-col>
        <el-col :span="12">
            <lee-bar-echarts
                ref="bar2"
                height="200px"
                width="512px"
                :show-legend="false"
                :show-right="false"
                :bar-width="20"
            ></lee-bar-echarts>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData7",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // },
        type:{
            type:String,
            default:''
        }
    },
    created() {

    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/Hydata/getQixieYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear,endYear){
            let _this = this;

            if(this.type) {
                this.$http.post({
                    url: '/Hydata/getTypeData',
                    data: {year:startYear, type: this.type, yearlist: [startYear, endYear]},
                    success(res) {
                        _this.data = res;

                        let _bar2 = {bar:[],xaxis:[]};

                        let _list1 = res.list;

                        for(let i=0;i < _list1.length;i++) {
                            _bar2.bar.push(_list1[i].total_profit);
                            _bar2.xaxis.push(_list1[i].year.toString());
                        }

                        if(_this.$refs.bar2) {
                            _this.$refs.bar2.initData(
                                [
                                    {name: '物流费用', type: 'bar', color: 'blue', data: _bar2.bar,dw:'亿元'},
                                ],
                                _bar2.xaxis
                            );
                        }
                    }
                })
            }
        },
    }
}
</script>
