<template>
	<lee-page bg-img="/images/bg_head_4.jpg" :bread="[{title:'行业数据',url:'/hydata'}]" title="医药数据" sub-title="INDUSTRY DATA" height="250" title-class="margin-top-90" :search="false">
		<div class="wrap1100 mg-t-b-50">
			<div class="text-center padding-bottom-100">
				<div class="cl"></div>
				<span class="rel inline-block margin-top-50">
          <img src="/images/sj_bg.png" width="100%"/>
          <lee-map-hot size="157" top="246" left="21" @click.native="$router.push({path:'/hydata/medical/zygy'})">工业端</lee-map-hot>
          <lee-map-hot size="157" top="21" left="150" @click.native="$router.push({path:'/hydata/medical/inout'})">进出口</lee-map-hot>
          <lee-map-hot size="157" top="246" left="249" @click.native="$router.push({path:'/hydata/medical/business'})">商业端</lee-map-hot>
          <lee-map-hot size="157" top="21" left="378" @click.native="$router.push({path:'/hydata/medical/client'})">终端</lee-map-hot>
          <lee-map-hot size="157" top="246" left="477" @click.native="$router.push({path:'/hydata/medical/wlfy'})">物流费用</lee-map-hot>
          <lee-map-hot size="157" top="21" left="606" @click.native="$router.push({path:'/hydata/medical/ccwlcl'})">物流仓储</lee-map-hot>
          <lee-map-hot size="157" top="250" left="701" @click.native="$router.push({path:'/hydata/medical/ccwlcl2'})">物流车辆</lee-map-hot>
          <lee-map-hot size="157" top="20" left="831" @click.native="$router.push({path:'/hydata/medical/llscgm'})">冷链规模</lee-map-hot>
					<lee-map-hot size="157" top="246" left="923" @click.native="$router.push({path:'/hydata/park'})">产业园区</lee-map-hot>
        </span>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            hyList:[
                {title:'冷藏车',icon:'/images/icon/icon_9_on.png',path:'/hydata/coldcar'},
                {title:'冷库',icon:'/images/icon/icon_10_on.png',path:'/hydata/coldstorage'},
                {title:'冷链物流',icon:'/images/icon/icon_11_on.png',path:'/hydata/coldchain'},
                {title:'产量',icon:'/images/icon/icon_12_on.png',path:'/hydata/harvest'},
                {title:'进出口',icon:'/images/icon/icon_13_on.png',path:'/hydata/inout'},
                {title:'农批',icon:'/images/icon/icon_14_on.png',path:'/hydata/agriculture'},
                {title:'特殊农品',icon:'/images/icon/icon_15_on.png',path:'/hydata/characteristic'},
            ]
        }
    },
    methods:{
        prevSwiper(){
            this.$refs['swiper'].prev();
        },
        nextSwiper(){
            this.$refs['swiper'].next();
        }
    }
}
</script>
