<template>
	<lee-page bg-img="/images/bg_head_6.jpg" title="我的回答" height="250" title-class="margin-top-90" sub-title="MY QUESTION" :search="false" :show-search-type="false" :bread="[{title:'问答中心',url:'/qa'},{title:'我的回答'}]">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<template v-if="list.length > 0">
				<div class="bg-gray-5 margin-bottom-20 padding-20" v-for="(item,index) in list">
					<div class="cl">
						<div class="z f16 dark-5">问题：{{item.title}}</div>
						<div class="y">
							<span class="inline-block vm orange margin-right hand" @click="toDel(item.id)">
								<i class="el-icon-delete"></i>删除
							</span>
						</div>
					</div>
					<div class="margin-top" v-html="'我的回答：'+item.content">{{item.content}}</div>
				</div>
				<div class="text-center margin-top-100">
					<el-pagination
						background
						layout="prev, pager, next"
						:page-size="pageSize"
						:current-page.sync="page"
						:total="total"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</template>
			<div class="text-center padding-top-bottom-40 f20 dark-3" v-else>
				暂无数据
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            list:[],
            page:1,
            pageSize:15,
            total:0,

            uid:this.$user.getUid()
        }
    },
    created() {
        this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    },
    mounted() {
        this.getList();
    },
    methods:{
      toDel(id){
        let _this = this;
        this.$confirm('是否删除此问题?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post({
            url:'/AuthAnswer/del',
            data:{id:id},
            success(res){
              _this.$message.success('删除成功');
              _this.getList();
            }
          })
        })
      },
        getList(){
            let _this = this;

            this.$http.post({
                url: '/User/getMyAnswer',
                data: {page:this.page,pageSize:this.pageSize},
                success(res) {
                  console.log(res);
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.page = page;
            this.$router.replace({query:{page:this.page}});
        },
    }
}
</script>
