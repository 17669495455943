<template>
    <lee-page bg-img="/images/bg_head_9.jpg" :type="$isMobile ? 'dark' : 'light'" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '50' : '280'" :back="$isMobile" :title="$isMobile ? '' : '医疗器械数据'" :bread="[{title:'行业数据',url:'/hydata'},{title:'医疗器械数据'}]">
        <div :class="$isMobile ? 'padding-left-right bg-light' : 'bg-img-11 padding-top-bottom-50 padding-top-50'" :style="{backgroundColor:$isMobile ? '' : '#f8f9fa'}">
            <check-vip akey="hydata-qixie">
                <div class="wrap1100">
                    <div class="text-center" style="margin-left: -10px;margin-right: -10px;">
                        <span class="margin-bottom-20 padding-left padding-right inline-block" style="width:20%;padding-left:10px;padding-right:10px;" v-for="(item,index) in tabList" @click="onChangeDataTab(item.type)">
                            <div :class="active === item.type ? 'bg-blue light' : 'dark-1'" class="padding-top-bottom box-shadow-blue-small border-radius-10 f16 hand">
<!--                            <lee-img-icon class="vm img" :color="active === item.type ? '#fff' : '#fff'" :url="'/images/icon/'+item.img" size="64"></lee-img-icon>-->
                                <span class="vm bg-light border-radius text-center inline-block" style="width:40px;height:40px;">
                                    <img :src="'/images/icon/'+item.img" width="32" height="32" style="margin-top:4px;"/>
                                </span>
                                {{item.title}}
                            </div>
                        </span>
                    </div>
                    <!--                    <lee-hash-tab v-model="active" class="margin-top" :list="tabList" :active-index="active" @change="onChangeDataTab"></lee-hash-tab>-->
                </div>
                <div :class="$isMobile ? '' : 'wrap1100'" class="padding-bottom-100">
    <!--                <lee-year-tab :list="yearList" :class="$isMobile ? 'margin-top' : ''" @change="changeTab"></lee-year-tab>-->
                    <lee-year-tab :list="yearList" class="margin-top-20"></lee-year-tab>
                    <div :class="$isMobile ? '' : 'box-shadow padding-30 bg-light margin-top-30'">
                        <div class="cl f34">
                            <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#00A3DC" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                            <div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">行业数据 - 器械{{title}}</div>
                        </div>
<!--                        <div class="f16 dark-2">-->
<!--                            <lee-hash-tab v-model="active" class="margin-top" :list="$isMobile ? tabMobileList : tabList" :active-index="active" @change="onChangeDataTab"></lee-hash-tab>-->
<!--                        </div>-->
                        <div class="f16 dark-2" v-if="active === 'ylqxsc'">
                          <p v-if="data && data.ylqxsc && data.ylqxsc.now">中物联医疗器械供应链分会经公开数据整理，不完全统计推算，{{nowYear}}年我国医疗器械行业市场规模为{{data.ylqxsc.now.total_income}}亿元，同比增长{{data.ylqxsc.now.income_rate.toFixed(2)}}%。其中，{{data.ylqxsc.now.values[0].name}}领域市场规模为{{data.ylqxsc.now.values[0].val.income}}亿元，{{data.ylqxsc.now.values[1].name}}领域市场规模为{{data.ylqxsc.now.values[1].val.income}}亿元，{{data.ylqxsc.now.values[2].name}}领域市场规模为{{data.ylqxsc.now.values[2].val.income}}亿元，{{data.ylqxsc.now.values[3].name}}领域市场规模为{{data.ylqxsc.now.values[3].val.income}}亿元。随着我国医疗器械行业的技术革新及产业链成熟,市场容量将会不断扩大。</p>
<!--                            <p v-if="data && data.ylqxsc && data.ylqxsc.now">近年来医疗器械市场也正保持着稳步增长，中国物流与采购联合会医疗器械供应链分会（以下简称“中物联医疗器械供应链分会”）经公开数据整理，不完全统计推算，{{nowYear}}年我国医疗器械行业市场规模为{{data.ylqxsc.now.total_income}}亿元，同比增长{{(data.ylqxsc.now.income_rate * 100).toFixed(2)}}%。其中，{{data.ylqxsc.now.values[0].name}}领域市场规模比重仍保持最高，为{{data.ylqxsc.now.values[0].val.income}}亿元，{{data.ylqxsc.now.values[1].name}}领域市场规模达{{data.ylqxsc.now.values[1].val.income}}亿元，{{data.ylqxsc.now.values[2].name}}领域市场规模位居细分第二，为{{data.ylqxsc.now.values[2].val.income}}亿元，{{data.ylqxsc.now.values[3].name}}领域市场增速为四大细分领域最高，实现了{{data.ylqxsc.now.values[3].val.income}}亿元的市场规模。随着我国医疗器械行业的技术革新及产业链成熟,市场容量将会不断扩大。根据Evaluate MedTech数据，全球医疗器械市场规模增速仅为5%左右，可见，未来中国医疗器械行业发展大有可为。</p>-->
    <!--                        <p>{{ (parseFloat(data.ylqxsc.now.income_rate) * 100).toFixed(2)}}</p>-->
                            <div class="margin-auto" style="width: 100%;">
                                <lee-bar-echarts
                                    ref="bar1"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-legend="false"
                                    :show-left="true"
                                    :show-right="false"></lee-bar-echarts>
                            </div>
                          <div class="margin-auto" style="width: 100%;">
                            <lee-bar-echarts
                                ref="bar5"
                                :grid-bottom="$isMobile ? 50 : 30"
                                :height="$isMobile ? '250px' : '350px'"
                                :show-legend="false"
                                :show-left="true"
                                :show-right="false"></lee-bar-echarts>
                          </div>
                        </div>
                        <div class="f16 dark-2" v-if="active === 'ylqxsc2'">
                          <p v-if="data && data.ylqxsc && data.ylqxsc.now">中物联医疗器械供应链分会经公开数据整理，据不完全统计推算，{{nowYear}}年我国医疗器械物流总费用约为{{data.ylqxsc.now.total_profit}}亿元。其中，{{data.ylqxsc.now.values2[0].name}}领域物流费用为{{data.ylqxsc.now.values2[0].val.profit}}亿元，{{data.ylqxsc.now.values2[1].name}}领域物流费用为{{data.ylqxsc.now.values2[1].val.profit}}亿元，{{data.ylqxsc.now.values2[2].name}}和{{data.ylqxsc.now.values2[3].name}}分别为{{data.ylqxsc.now.values2[2].val.profit}}亿元、{{data.ylqxsc.now.values2[3].val.profit}}亿元。我国医疗器械市场发展潜力巨大，物流市场规模也将随之持续扩大。</p>
<!--                            <p v-if="data && data.ylqxsc && data.ylqxsc.now">近年来，一系列政策频繁调整，驱动着医疗器械流通变革。中物联医疗器械供应链分会经公开数据整理，据不完全统计推算，{{nowYear}}年我国医疗器械物流总费用约为{{data.ylqxsc.now.total_profit}}亿元，突破百亿市场容量。其中，{{data.ylqxsc.now.values2[0].name}}领域物流费用最高，达{{data.ylqxsc.now.values2[0].val.profit}}亿元，{{data.ylqxsc.now.values2[1].name}}领域物流费用次之，为{{data.ylqxsc.now.values2[1].val.profit}}亿元，{{data.ylqxsc.now.values2[2].name}}和{{data.ylqxsc.now.values2[3].name}}分别为{{data.ylqxsc.now.values2[2].val.profit}}亿元、{{data.ylqxsc.now.values2[3].val.profit}}亿元。我国医疗器械市场发展潜力巨大，物流市场规模也将随之持续扩大。</p>-->
                            <div class="margin-auto" style="width: 100%;">
                                <lee-bar-echarts
                                    ref="bar2"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-legend="false"
                                    :show-left="true"
                                    :show-right="false"></lee-bar-echarts>
                            </div>
                          <div class="margin-auto" style="width: 100%;">
                            <lee-bar-echarts
                                ref="bar4"
                                :grid-bottom="$isMobile ? 50 : 30"
                                :height="$isMobile ? '250px' : '350px'"
                                :show-legend="false"
                                :show-left="true"
                                :show-right="false"></lee-bar-echarts>
                          </div>
                        </div>
                        <div class="f16 dark-2" v-if="active === 'ylqxwlcl'">
                          <p v-if="data && data.ylqxsc && data.ylqxsc.now">中物联医疗器械供应链分会经公开数据整理，不完全统计推算，截止{{nowYear}}年底，我国医疗器械物流仓储总面积约为{{data.ylqxwlcl.now.total_income}}万平方米。其中，在各种类仓库中，{{data.ylqxwlcl.now.values[0].name}}占比最大，为{{ (data.ylqxwlcl.now.values[0].val.income_percent * 100).toFixed(2)}}%。{{data.ylqxwlcl.now.values[1].name}}和{{data.ylqxwlcl.now.values[2].name}}面积占比分别为{{ (data.ylqxwlcl.now.values[1].val.income_percent * 100).toFixed(2)}}%和{{ (data.ylqxwlcl.now.values[2].val.income_percent * 100).toFixed(2)}}%。</p>
<!--                            <p v-if="data && data.ylqxsc && data.ylqxsc.now">根据中物联医疗器械供应链分会不完全统计推算，截止{{nowYear}}年底，我国医疗器械物流仓储总面积约为{{data.ylqxwlcl.now.total_income}}万平方米。其中，在各种类仓库中，{{data.ylqxwlcl.now.values[0].name}}占比最大，为{{ (data.ylqxwlcl.now.values[0].val.income_percent * 100).toFixed(2)}}%。{{data.ylqxwlcl.now.values[1].name}}和{{data.ylqxwlcl.now.values[2].name}}面积占比分别为{{ (data.ylqxwlcl.now.values[1].val.income_percent * 100).toFixed(2)}}%和{{ (data.ylqxwlcl.now.values[2].val.income_percent * 100).toFixed(2)}}%。</p>-->
                            <div class="margin-auto" style="width: 100%">
                                <lee-pie-echarts
                                    ref="pie1"
                                    :grid-bottom="$isMobile ? 100 : 30"
                                    :height="$isMobile ? '300px' : '350px'"
                                    :show-legend="false"
                                    label-format="{b}:{d}%"
                                    label-pos="outside"
                                    :radius="['0%','50%']"></lee-pie-echarts>
                            </div>
                        </div>
                        <div class="f16 dark-2" v-if="active === 'ylqxwlcl2'">
                          <p v-if="data && data.ylqxsc && data.ylqxsc.now">随着医疗器械行业的稳步发展，医疗器械运输车辆逐步增加。车辆增长主要来源于自有车辆的补给。<template v-if="data && data.ylqxwlcl && data.ylqxwlcl.now">中物联医疗器械供应链分会不完全统计推算，{{nowYear}}年我国医疗器械行业自有车辆总数约{{data.ylqxwlcl.now.sub_total_income}}辆，其中自有冷藏车总数约为{{data.ylqxwlcl.now.sub_values[1].val.income}}辆。</template></p>
<!--                            <p v-if="data && data.ylqxsc && data.ylqxsc.now">随着医疗器械行业的稳步发展，企业拓展业务，增加仓储面积，提高配送服务能力等，使得运输车辆相应增加。其中，车辆增长主要来源于自有车辆的补给，据中物联医疗器械供应链分会不完全统计推算，{{nowYear}}年我国医疗器械行业自有车辆总数约{{data.ylqxwlcl.now.sub_total_income}}辆，呈快速上升趋势。<br/>-->
<!--                                其中，除体外诊断试剂及部分植入类高值耗材需要冷链仓储和运输以外，其他医疗器械产品运输基本跟普货运输方式区别不大。IVD产业近年来保持着持续平稳增长，未来市场发展可观，加之行业监管趋严，冷链运输愈加受到重视，医疗器械物流领域冷藏车数量进而大幅增长。<template v-if="data && data.ylqxwlcl && data.ylqxwlcl.now">经中物联医疗器械供应链分会不完全统计推算，{{nowYear}}年我国医疗器械行业自有冷藏车总数约为{{data.ylqxwlcl.now.sub_values[1].val.income}}辆</template></p>-->
                            <el-row>
                              <el-col :span="12">
                                <div class="text-center">
                                  <lee-bar-echarts
                                      ref="bar3"
                                      :grid-bottom="$isMobile ? 50 : 30"
                                      :height="$isMobile ? '250px' : '350px'"
                                      :show-legend="false"
                                      :show-left="true"
                                      :show-right="false"></lee-bar-echarts>
                                </div>
                              </el-col>
                              <el-col :span="12">
                                <div class="text-center">
                                  <lee-pie-echarts
                                      ref="pie2"
                                      :grid-bottom="$isMobile ? 100 : 30"
                                      :height="$isMobile ? '300px' : '350px'"
                                      :show-legend="false"
                                      label-format="{b}:{d}%"
                                      label-pos="outside"
                                      :radius="['0%','50%']"></lee-pie-echarts>
                                </div>
                              </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            yearRange:[],

            tabList:[
                // {title:'市场规模',link:'#a1'},
                // {title:'物流市场规模',link:'#a2'},
                // {title:'物流仓储',link:'#a3'},
                // {title:'物流运输车辆',link:'#a4'},

                {title:'市场规模',link:'#a1',type:'ylqxsc',img:'icon_9.png'},
                {title:'物流总费用',link:'#a2',type:'ylqxsc2',img:'icon_10.png'},
                {title:'仓储',link:'#a3',type:'ylqxwlcl',img:'icon_11.png'},
                {title:'车辆',link:'#a4',type:'ylqxwlcl2',img:'icon_12.png'},
                // {title:'器械第三方物流',link:'#a5',type:'wlfy',img:'icon_13.png'},
            ],
            tabMobileList:[
                {title:'市场规模',link:'#a1'},
                {title:'物流市场规模',link:'#a2'},
                {title:'物流仓储',link:'#a3'},
                {title:'物流运输车辆',link:'#a4'},
            ],

            title:'',

            total1:null,
            total3_sub:[],

            data:null,
            data1:null,
            data2:null,
            data3:null,
            data4:null,
            data5:null,

            active:'ylqxsc',
        }
    },
    created() {
        this.active = this.$route.params.type ? this.$route.params.type : 'ylqxsc';

        for(let i=0;i < this.tabList.length;i++){
            if(this.tabList[i].type === this.active){
                this.title = this.tabList[i].title;
            }
        }
        // let _now = new Date();
        // let _startYear = _now.getFullYear() - 1;
        // let _endYear = _startYear - 8;
        //
        // this.startYear = _startYear;
        //
        // for (let i = _startYear;i > _endYear;i--){
        //     this.yearList.push({label:i+'年',value:i});
        // }
        //
        // this.endYear = this.yearList[this.yearList.length - 1].value;
        // this.yearRange = [_startYear-4,_startYear];

        // this.nowYear = this.$route.query.year ? this.$route.query.year : this.yearList[0].value;
    },
    mounted() {
        // this.getAll(this.nowYear);
        this.getYears();
    },
    methods:{
        onChangeDataTab(e){
            // this.getTotal1(this.nowYear);
            this.$router.push({path:'/hydata/apparatus/'+e});
        },
        changeTab(v){
            this.nowYear = v.value;
            this.getAll(this.nowYear);
        },
        getAll(year){
            for(let i in this){
                if(i.indexOf('getTotal') === 0){
                    this[i](year);
                }
            }
        },

        findYear(year,list){
            if(list.length > 0){
                for(let i=0;i < list.length;i++){
                    if(list[i].year === year){
                        return list[i];
                    }
                }
                return false;
            }
            return false;
        },

        getYears() {
            let _this = this;

            let _type = 'ylqxsc';

            if(this.active === 'ylqxsc2'){
              _type = 'ylqxsc';
            }else if(this.active === 'ylqxwlcl2'){
              _type = 'ylqxwlcl';
            }else{
              _type = this.active
            }

            this.$http.post({
                url: '/Hydata/getQixieYears',
                data:{type:_type},
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.endYear = res[res.length - 1];

                    _this.nowYear = _this.$route.query.year ? _this.$route.query.year : res[0];

                    _this.getTotal1(_this.nowYear);
                }
            });
        },

        getTotal1(year) {
            let _this = this;
            this.$http.post({
                url: '/Hydata/getQixieData',
                data: {year: year,yearlist:[year,year - 4]},
                success(res) {
                    // console.log(res);
                    _this.data = res;

                    //bar1  bar2
                    let _bar1 = {bar:[],xaxis:[]};
                    let _bar2 = {bar:[],xaxis:[]};
                  let _bar4 = {bar:[],xaxis:[]};
                  let _bar5 = {bar:[],xaxis:[]};


                    let _list1 = res.ylqxsc.list;
                  let _list2 = res.ylqxsc.now;

                    for(let i=0;i < _list1.length;i++) {
                        _bar1.bar.push(_list1[i].total_income);
                        _bar1.xaxis.push(_list1[i].year.toString());

                        _bar2.bar.push(_list1[i].total_profit);
                        _bar2.xaxis.push(_list1[i].year.toString());
                    }

                  for(let i=0;i < _list2.values.length;i++) {
                    _bar4.bar.push(_list2.values[i].val.profit);
                    _bar4.xaxis.push(_list2.values[i].name);

                    _bar5.bar.push(_list2.values[i].val.income);
                    _bar5.xaxis.push(_list2.values[i].name);
                  }

                  if(_this.$refs.bar4) {
                    _this.$refs.bar4.initData(
                        [
                          {name: '物流费用', type: 'bar', color: 'most', data: _bar4.bar,dw:'亿元'},
                        ],
                        _bar4.xaxis
                    );
                  }

                  if(_this.$refs.bar5) {
                    _this.$refs.bar5.initData(
                        [
                          {name: '市场规模', type: 'bar', color: 'most', data: _bar5.bar,dw:'亿元'},
                        ],
                        _bar5.xaxis
                    );
                  }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '市场规模', type: 'bar', color: 'blue', data: _bar1.bar,dw:'亿元'},
                            ],
                            _bar1.xaxis
                        );
                    }

                    if(_this.$refs.bar2) {
                        _this.$refs.bar2.initData(
                            [
                                {name: '物流费用', type: 'bar', color: 'blue', data: _bar2.bar,dw:'亿元'},
                            ],
                            _bar2.xaxis
                        );
                    }

                    //pie1  pie2
                    let _pie1 = [];
                    let _pie2 = [];
                    let _bar3 = {bar:[],bar2:[],xaxis:[]};

                    let _pie1list = res.ylqxwlcl.now;
                    let _pie1list2 = res.ylqxwlcl.list;

                    for (let i=0;i< _pie1list.values.length;i++){
                        _pie1.push({value:_pie1list.values[i].val.income,name:_pie1list.values[i].name});
                    }

                    for (let i=0;i< _pie1list.sub_values.length;i++){
                        _pie2.push({value:_pie1list.sub_values[i].val.income,name:_pie1list.sub_values[i].name});
                    }

                    for (let i=0;i < _pie1list2.length;i++){
                      _bar3.bar.push(parseInt(_pie1list2[i].sub_values[0].val.income));
                      _bar3.bar2.push(parseInt(_pie1list2[i].sub_values[1].val.income));
                      _bar3.xaxis.push(_pie1list2[i].year.toString());
                    }

                    if(_this.$refs.pie1){
                        _this.$refs.pie1.initData(_pie1,'万平方米');
                    }

                    if(_this.$refs.pie2){
                        _this.$refs.pie2.initData(_pie2,'辆');
                    }

                  if(_this.$refs.bar3) {
                    _this.$refs.bar3.initData(
                        [
                          {name: '自有车辆', type: 'bar', color: 'blue', data: _bar3.bar,dw:'辆'},
                          {name: '自有冷藏车车辆', type: 'bar', color: 'green', data: _bar3.bar2,dw:'辆'},
                        ],
                        _bar3.xaxis
                    );
                  }

                }
            });
        },
    }
}
</script>
