<template>
    <div>
        <div class="cl border-bottom" style="line-height: 50px;height: 50px;" v-if="!$isMobile">
            <div class="z c-b-2 f18">{{$route.meta}}</div>
            <div class="y" :class="rightClass">
                <slot name="headRight"></slot>
            </div>
        </div>
        <div class="margin-top"><slot></slot></div>
    </div>
</template>

<script>
export default {
    name: "UserPage",
    props:{
        rightClass:String
    }
}
</script>
