<template>
	<lee-page bg-img="/images/bg_head_1.jpg" title="课件视频" sub-title="COURSEWARE VIDEO" height="250" title-class="margin-top-90">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<template v-if="list.length > 0">
				<div>
					<el-row :gutter="20">
						<el-col :span="6" v-for="(item,index) in list" class="mg-b-20">
							<div class="bd-w-1 bd-dark-8 bd-style-solid rel hand" @click="$router.push({path:'/zhuanlan/video/detail',query:{speakerid:item.speaker_id,zhuantiid:searchForm.zhuanti_id}})">
								<img :src="$utils.formatUrl(item.speaker.head_img)" style="width: 100%;height: 200px;" v-if="item.speaker"/>
								<div class="pd-10 mg-t-20">
									<div class="f-18">{{item.title}}</div>
									<div class="f-14 f-thin mg-t-10" v-if="item.speaker">{{item.speaker.company}}</div>
									<div class="f-14 f-thin" v-if="item.speaker">{{item.speaker.job}} {{item.speaker.name}}</div>
<!--									<div class="f-12 dark-6 mg-t-10"><i class="el-icon-time"></i>2023年5月6日15：00-16:00</div>-->
								</div>
								<div class="abs bg-yellow pd-t-b-5 pd-l-r-15" style="top:184px;left:0;" v-if="item.speaker">{{item.speaker.name}}</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="text-center margin-top-100">
					<el-pagination
						background
						:page-size="searchForm.pageSize"
						:current-page.sync="searchForm.page"
						:total="totalCount"
						layout="prev, pager, next"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</template>
			<template v-else>
				<div class="text-center pd-t-b-50 f20 dark-3">
					未查询到相关内容
				</div>
			</template>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "videolist",
	data() {
		return {
			list: ['1'],
			totalCount: 0,
			searchForm: {
				zhuanti_id:'',
				pageSize: 10,
				page: 1,
				title: ''
			}
		}
	},
	created() {
		this.searchForm.zhuanti_id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		if(this.searchForm.zhuanti_id){
			this.getList();
		}
	},
	methods: {
		getList() {
			let _this = this;
			
			this.$http.post({
				url: '/ZhuantiVideo/listPage',
				data: {...this.searchForm,state:1},
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			});
		},
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
	}
}
</script>
