<template>
	<div class="margin-top bg-light bd-radius-6 user-menu pd-t-b-30">
		<div class="hand" :class="$route.path.indexOf(item.path) >= 0  ? 'c-b on' : 'dark-5'" style="padding-left:30px;height: 50px;line-height: 50px;" v-for="(item,index) in $config.userMenu" @click="$router.push({path:item.path})">
<!--			<img :src="item.icon + ($route.path.indexOf(item.path) >= 0 ? '_on.png' : '_off.png')" height="18" width="18" class="margin-right"/>-->
			<i class="iconfont c-b-2 mg-r-10 f-14" :class="item.icon"></i>
			<span class="vm f-16">{{ item.title }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "UserMenu",
	data() {
		return {
			top: 0,
			activeIndex: 0
		}
	},
	props: {},
	mounted() {
	},
	methods: {}
}
</script>
