<template>
    <lee-user-main-page type="dark">
        <lee-user-page class="padding-bottom-100">
            <template v-if="list.length > 0">
                <lee-base-cell :right-title="$isMobile ? '' : ''" v-for="(item,index) in list" title-class="green f14 f-n" img="/images/icon/icon_q.png">
                    <div slot="subTitle">
                        <div class="f16 dark-1" :class="$isMobile ? 'text-line-2' : 'text-line-1'" style="line-height: 24px;">{{item.title}}</div>
                        <div class="dark-1 blue margin-top" :class="$isMobile ? 'f12 text-line-1' : 'f14'" style="line-height: 20px;" v-if="item.is_pay === 1">{{item.answer}}</div>
                        <div class="dark-3 margin-top" v-else-if="item.answer && item.is_pay === 0" style="line-height: 20px;">已有回答，请先购买</div>
                        <div class="dark-3 f-thin" :class="$isMobile ? 'f12' : 'f14 margin-top'" style="line-height: 20px;">时间：{{item.create_time}}</div>
                    </div>
                    <div slot="rightButton" class="text-right">
                        <lee-state-tag title="已付款" type="blue" v-if="item.is_pay === 1"></lee-state-tag>
                        <lee-state-tag title="购买回答" type="red" @click.native="buyAnswer(item)" class="hand" v-else-if="item.is_pay === 0 && item.is_answer === 1"></lee-state-tag>
                        <div class="gray-2" v-else>.</div>
                    </div>
                </lee-base-cell>
                <div class="text-center margin-top-100" v-if="!$isMobile">
                    <el-pagination
                        background
                        layout="pager"
                        :page-size="pageSize"
                        :current-page.sync="page"
                        :total="total"
                        @current-change="pageChange">
                    </el-pagination>
                </div>
            </template>
            <div class="text-center padding-top-bottom-40 f18 dark-2" v-else>
                暂无记录
            </div>
        </lee-user-page>
        <el-dialog
            title="支付"
            :visible.sync="show" @close="closePay">
            <lee-pay-view :name="buyData.title" :price="buyData.price" title="购买答案" sub-title="答案金额" file-size="" :qrcode="'data:image/jpeg;base64,'+qrcode" v-if="buyData"></lee-pay-view>
            <!--                <lee-pay-view :price="buyData.price.amount" :name="buyData.title" title="购买答案" sub-title="答案金额" file-size="" @submit="paySubmit"></lee-pay-view>-->
        </el-dialog>
    </lee-user-main-page>
</template>

<script>
export default {
    name: "BuyLog",
    data(){
        return{
            list:[],
            page:1,
            pageSize:20,
            total:0,
            show:false,
            buyData:null,
            qrcode:'',
            time:null,
            order_id:''
        }
    },
    created() {
        this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.post({
                url: '/User/getMyQuestion',
                data: {page:this.page},
                success(res) {
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.page = page;
            this.$router.replace({query:{page:this.page}});
        },
        buyAnswer(item){
            // this.show = true;
            this.buyData = item;

            console.log(item);

            this.getPayCode();
            event.preventDefault();
        },
        getPayCode(){
            let _this = this;
            this.$http.post({
                url:'/Order/getPayCode',
                data:{id:this.buyData.id,order_type:'question'},
                success(res){
                    _this.qrcode = res.qrcode;
                    _this.order_id = res.order_id;
                    _this.show = true;
                    _this.timeDown('checkState');
                }
            })
        },
        timeDown(type){
            if(type === 'checkState') {
                this.time = setInterval(this.checkState, 3000)
            }
        },
        closePay(){
            this.show = false;
            clearInterval(this.time);
            this.time = null;
            this.qrcode = '';
            this.order_id = '';
        },
        checkState(){
            let _this = this;
            this.$http.post({
                url:'/Common/checkOrder',
                data:{id:this.order_id},
                success(res){
                    if(res.state === 2){
                        clearInterval(_this.time);
                        _this.time = null;
                        // _this.info.bid_state = 1;
                        _this.show = false;
                        _this.$message.success('支付成功');

                        _this.getList();
                        // _this.getDetail();
                    }
                }
            })
        },
    }
}
</script>
