<template>
	<!--    <lee-page bg-img="/images/bg_head_9.jpg" :type="$isMobile ? 'dark' : 'light'" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '50' : '280'" :back="$isMobile" :title="$isMobile ? '' : '产业园区数据'" :bread="[{title:'行业数据',url:'/hydata'},{title:'产业园区数据'}]">-->
	<lee-page bg-img="/images/bg_head_4.jpg" title="产业园数据" sub-title="INDUSTRIAL PARK DATA" :height="250" :bread="[{title:'行业数据',url:'/hydata'},{title:'产业园数据'}]" title-class="margin-top-90">
		<check-vip akey="hydata-yuanqu">
			<div class="wrap1100 mg-t-50 mg-b-100">
				<lee-tabs :list="tabList" :default-index="mm_type" @change="onTabChange" class="margin-auto" style="width: 500px;"></lee-tabs>
				<div class="margin-top-20">
					<template v-if="list.length > 0">
						<div class="margin-bottom-20 hand padding box-shadow bg-light border-radius-5" v-for="(item,index) in list" style="height: 42px;" @click="$router.push({path:'/park/detail/'+item.id})">
							<img src="/images/icon/icon-10.png" height="20" class="z margin-right"/>
							{{ item.title }}
							<span class="y dark-2">&gt;</span>
						</div>
						<div class="text-center margin-top-100" v-if="list.length > 0">
							<el-pagination
								:hide-on-single-page="true"
								background
								layout="pager"
								:page-size="pageSize"
								:current-page.sync="page"
								:total="totalCount"
								@current-change="pageChange">
							</el-pagination>
						</div>
					</template>
					<template v-else>
						<div class="text-center bg-light padding-top-bottom-40 f-thin f20 dark-3" v-if="!$isMobile">
							暂无数据
						</div>
						<van-empty description="暂无数据" v-else/>
					</template>
				</div>
			</div>
		</check-vip>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			tabList: [
				{label: '经济技术开发区', value: 'kfq', icon: 'icon-icon_xinyong_xianxing_jijin-1'},
				{label: '生物医药产业园区', value: 'sw', icon: 'icon-icon_xinyong_xianxing_jijin-'},
				// {label:'医疗器械产业园区',value:'qixie',icon:'/images/icon/icon_57_'}
			],
			mm_type: 'kfq',
			list: [],
			page: 1,
			pageSize: 15,
			totalCount: 0,
		}
	},
	created() {
		this.mm_type = this.$route.query.mmtype ? this.$route.query.mmtype : 'kfq';
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;
			
			this.$http.post({
				url: '/Park/listPage',
				data: {page: this.page, mm_type: this.mm_type, pageSize: this.pageSize},
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			});
		},
		pageChange(page) {
			this.page = page;
			this.getList();
		},
		onTabChange(i) {
			console.log(i);
			this.mm_type = i;
			this.getList();
		}
	}
}
</script>
