<template>
    <span class="bg-blue f14 light padding border-radius-5 hand" :class="$isMobile ? 'block' : 'inline-block'" @click="toUrl">
        <i class="margin-right" :class="leftIcon"></i>
        附件：{{filename}}
        <i class="margin-left" aria-hidden="true" :class="rightIcon"></i>
    </span>
</template>

<script>
export default {
    name: "FileDown",
    props:{
        filename:String,
        url:String,
        leftIcon:{
            type:String,
            default:'el-icon-tickets'
        },
        rightIcon:{
            type:String,
            default:'fa fa-download'
        }
    },
    methods:{
        toUrl(){
          if(this.url) {
            window.open(this.url.indexOf('http') >= 0 ? this.url : this.$config.assetsUrl + this.url, '_blank');
          }
        }
    }
}
</script>
