<template>
    <lee-page :bg-img="$isMobile ? '/images/bg_head_9.jpg' : '/images/bg_head_3.jpg'" :title="$isMobile ? '' : '细分领域报告'" :sub-title="$isMobile ? '' : 'Drug policy'" :bread="['fds','fdsf']" :back="$isMobile" :height="$isMobile ? '120' : '400'">
        <div :class="$isMobile ? 'padding-left-right bg-light' : 'bg-img-11 padding-top-50 padding-bottom-100'">
            <div class="cl"></div>
            <div :class="$isMobile ? '' : 'margin-top-100 margin-auto'" :style="{width: $isMobile ? '' : '1500px',marginTop:$isMobile ? '-40px' : ''}">
                <el-row :gutter="10">
                    <el-col :md="12" :xs="24" v-for="(item,index) in 10" class="margin-bottom">
                        <div class="bg-light border-radius-5 hand border-w-1 border-dark-6" :class="$isMobile ? 'padding' : 'padding-20'" @click="$router.push({path:'/yanjiu/xifen/detail/'+item})">
                            <div class="cl"></div>
                            <div class="border-bottom padding-bottom" style="height:60px;line-height: 20px;">
                                <img src="/images/icon/icon-36.png" width="20" height="20" class="vm"/>
                                <div class="f18 margin-left-30" style="height:50px;line-height: 20px;margin-top:-20px;">我是报告名称报告名称报告名称的报告称的报告称的报告称的</div>
                            </div>
                            <el-row :gutter="10" class="margin-top">
                                <el-col :md="24" :xs="12" class="margin-bottom">
                                    <img src="/images/icon/icon_37.png" width="20" height="20" class="margin-right"/>
                                    <span class="vm dark-3" :class="$isMobile ? 'f12' : 'f16'">作者名，物流行业协会</span>
                                </el-col>
                                <el-col :md="24" :xs="12" class="margin-bottom">
                                    <img src="/images/icon/icon_38.png" width="20" height="20" class="margin-right"/>
                                    <span class="vm dark-3" :class="$isMobile ? 'f12' : 'f16'">2020-12-12</span>
                                </el-col>
                                <el-col :md="24" :xs="12" class="margin-bottom" v-if="!$isMobile">
                                    <img src="/images/icon/icon_39.png" width="20" height="20" class="vm"/>
                                    <div class="margin-left-30 text-line-3 vm f16 dark-3" style="height: 40px;line-height: 20px;margin-top: -20px">我是摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容</div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="text-center margin-top-100" v-if="!$isMobile">
                <el-pagination
                    background
                    layout="pager"
                    :total="1000">
                </el-pagination>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    methods:{

    }
}
</script>
