<template>
    <lee-page bg-img="/images/bg_head_9.jpg" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '100' : '280'" :back="$isMobile" :title="$isMobile ? '' : title" :bread="['新闻',data.title]" v-if="data">
        <div :class="!$isMobile ? 'bg-img-1 padding-bottom-100 padding-top-100' : 'padding-left-right'" :style="{backgroundColor: !$isMobile ? '#fff' : '',marginTop:$isMobile ? '-40px' : ''}">
            <div :class="$isMobile ? '' : 'wrap1100'">
                <div class="box-shadow bg-light" :class="$isMobile ? 'padding border-radius-5' : 'padding-30'">
                    <div class="green-line">
                        <div class="cl" :class="$isMobile ? 'f18' : 'f34'">
                            <img src="/images/icon/icon_30.png" height="22" class="no-v-mid" v-if="!$isMobile"/>
                            {{data.title}}
                        </div>
                        <el-row :gutter="20" class="dark-3 margin-top" :class="$isMobile ? 'f12' : 'f14'">
                            <el-col :md="5" :xs="12">来源：{{data.source}}</el-col>
                        </el-row>
                    </div>
                    <div class="f16 dark-1" v-html="data.html_content ? data.html_content : ''"></div>
<!--                    <div class="margin-top-20">-->
<!--                        <lee-file-down :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.attachment" class="margin-right margin-bottom"></lee-file-down>-->
<!--                    </div>-->
                    <lee-good-view class="margin-top-50" :id="data.id" akey="news" :open-like="data.open_like === 1" :open-favorite="data.open_favorite === 1" :open-comment="data.open_comment === 1"></lee-good-view>
                    <lee-comment-view total-count="30" akey="news" :id="data.id" v-if="data.open_comment === 1"></lee-comment-view>
                </div>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            title:'',
            id:'',
            data:null
        }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        this.getDetail();
    },
    methods:{
        getDetail(){
            let _this = this;

            if(this.id) {
                this.$http.post({
                    url: '/News/detail',
                    data: {id: this.id},
                    success(res) {
                        _this.data = res;
                        _this.title = res.title;
                    }
                });
            }
        }
    }
}
</script>
