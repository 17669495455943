<template>
    <el-form :inline="true" :model="searchForm" class="wrap1100 bg-light box-shadow padding-20 rel">
<!--	    <div class="auto-width">-->
<!--		    <div style="width: 100px;">121</div>-->
<!--		    <div style="margin-left: 100px;">333</div>-->
<!--	    </div>-->
	    <div style="display: flex;flex-direction: row;">
        <el-form-item style="margin-bottom: 0 !important;flex:1;" class="width-100-form-content">
            <el-input clearable v-model="searchForm.title" placeholder="输入关键字" class="lee-input" @clear="clear"></el-input>
        </el-form-item>
		    <div>
			    <el-form-item style="margin-bottom: 0 !important;" v-if="showYear">
				    <lee-select clearable :options="searchYearList" v-model="year" @change="changeYear" style="width: 184px;"></lee-select>
			    </el-form-item>
			    <el-form-item style="margin-bottom: 0 !important;" v-if="showSearchType">
				    <lee-select :options="[{label:'标题',value:'title'},{label:'关键字',value:'key'}]" v-model="searchForm.title_type" style="width: 184px;"></lee-select>
			    </el-form-item>
			    <el-form-item style="margin-bottom: 0 !important;margin-right: 0 !important;">
				    <el-button type="primary" size="medium" class="vm lee-button margin-left f-lq" @click="onSubmit" style="width: 186px;">
					    <i class="el-icon-s-promotion"></i>查询
				    </el-button>
			    </el-form-item>
		    </div>
	    </div>
<!--        <div class="blue margin-top-5 hand" style="display: inline-block;" v-if="searchForm.title_type === 'key'" @click="changeSort">相关性<i :class="searchForm.sortBy === 'desc' ? 'el-icon-sort-down' : 'el-icon-sort-up'"></i></div>-->
    </el-form>
</template>

<script>
export default {
    name: "Search",
    data(){
        return{
            searchForm:{
                title:'',
                title_type:'title',
                slugs:[],
                sortBy:'desc',
                page:1
            },
            searchYearList:[],
            year:''
            // value:''
        }
    },
    // model:{
    //     prop:'values',
    //     event:'input'
    // },
    props:{
        showYear:{
            type:Boolean,
            default:false
        },
	    showSearchType:{
            type:Boolean,
            default:true
        },
        showSort:{
            type:Boolean,
            default:false
        },
        searchPath:{
            type:String,
            default:'',
        },
        searchSlugs:{
            type:Array,
            default(){
                return []
            }
        },
        stopSearch:{
            type:Boolean,
            default:false
        },
        toPath:{
            type:String,
            default:'',
        },
      searchTimeKey:{
        type:String,
        default:'create_time',
      },
        // values:{
        //     type:String,
        //     default:''
        // },
        // placeholder:{
        //     type:String,
        //     default:'请输入'
        // },
        // fontSize:{
        //     type:[String,Number],
        //     default:'20px'
        // },
    },
    created() {
        // this.value = this.values;
        // this.searchForm = this.$utils.isEmptyObject(this.$route.query) ? {} : this.$route.query;
        this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
        this.searchForm.title_type = this.$route.query.title_type ? this.$route.query.title_type : 'title';
        this.searchForm.sortBy = this.$route.query.sortBy ? this.$route.query.sortBy : 'desc';

        if(this.$route.query[this.searchTimeKey]){
            this.searchForm[this.searchTimeKey] = this.$route.query[this.searchTimeKey];

            this.year = this.$moment(this.$route.query[this.searchTimeKey][0]).format('YYYY');
        }

        let _nowYear = this.$moment().year();
  
      this.searchYearList.push({label:'全部年份',value:''})
        for(let i = _nowYear; i > _nowYear - 30;i--){
            this.searchYearList.push({value:i.toString(),label:i.toString()+'年'});
        }
    },
    mounted() {
        if(this.searchForm.title) {
            // this.$emit('search',{title:this.searchForm.title,title_type:this.searchForm.title_type});
        }
    },
    methods:{
        changeYear(e){
            if(e) {
                let _start = e + '-1-1 00:00:00'; //this.$moment(e+'-1-1 00:00:00').unix();
                let _end = e + '-12-31 23:59:59'; //this.$moment(e+'-12-31 23:59:59').unix();

                this.searchForm[this.searchTimeKey] = [_start, _end];
            }else{
                delete this.searchForm[this.searchTimeKey];
              // this.$router.replace({query:{...this.searchForm}});
            }
        },
        onSubmit(){
            let _this = this;

            // this.$router.replace({query:{...this.searchForm}});

            if(this.stopSearch){
                // if(this.searchForm.title) {
                    this.$router.push({path: this.toPath, query:{...this.searchForm}});
                // }else{
                //     this.$message.error({message: '请输入关键字', duration: 2000});
                // }
            }else {
                this.$router.replace({query:{...this.searchForm}});
                // this.$emit('search',{title:this.searchForm.title,title_type:this.searchForm.title_type});
            }
        },
        clear(){
            this.$emit('clear');
            this.searchForm.title = '';
            this.$router.replace({query:{...this.searchForm}});
        },
        toSearch(){
            // this.$emit('search',{title:this.searchForm.title,title_type:this.searchForm.title_type});

        },
        changeSort(){
            if(this.searchForm.sortBy === 'desc'){
                this.searchForm.sortBy = 'asc';
            }else{
                this.searchForm.sortBy = 'desc';
            }
            this.$router.replace({query:{...this.searchForm}});
        }
    }
}
</script>
