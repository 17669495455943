<template>
	<lee-page bg-img="/images/bg_head_13.jpg" title="行业标杆" sub-title="INDUSTRY BENCHMARK" height="400" :bread="[{title:'行业标杆',url:'/prize'},{title:'搜索'}]" search show-year>
<!--	<lee-page bg-img="/images/bg_head_3.jpg" title="研究报告" sub-title="The Top Hundred Corporations" des="" :search="false" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'搜索'}]" :height="400">-->
		<div class="bg-img-11 padding-top-bottom-100">
			<div class="wrap1100">
				<template v-if="list.length > 0">
					<div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{ totalCount }}</b> 条相关内容</div>
					<el-row class="border-left">
						<el-col :span="8" v-for="(item,index) in list" class="border-top border-right border-bottom">
							<div class="bg-light padding-20 bg-blue-hover hand" @click="$router.push({path:'/prize/detail/'+item.id})">
								<img :src="item.head_img.indexOf('http') >= 0 ? item.head_img : $config.assetsUrl + item.head_img" width="100%" height="200"/>
								<div class="margin-top dark-1 f18 text-line-2" style="height: 50px;line-height: 25px;" v-html="item.title"></div>
								<div class="margin-top dark-3 f14 text-line-2 f-thin" style="height: 40px;line-height: 20px;">
									{{ item.content }}
								</div>
								<div class="margin-top">
									<el-button type="primary" size="mini">详情<i class="el-icon-right el-icon--right"></i></el-button>
								</div>
							</div>
						</el-col>
					</el-row>
					<div class="text-center margin-top-100">
						<el-pagination
							:hide-on-single-page="true"
							background
							layout="pager"
							:page-size="pageSize"
							:current-page.sync="page"
							:total="totalCount"
							@current-change="pageChange">
						</el-pagination>
					</div>
				</template>
				<div class="text-center padding-top-bottom-40 f18 dark-2" v-else>
					未查询到相关内容
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			title: '',
			list: [],
			page: 1,
			pageSize: 20,
			totalCount: 0,
			
			searchForm: {
				title: '',
				mm_type: ''
			}
		}
	},
	created() {
		this.searchForm.mm_type = this.$route.params.type;
		this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
		this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
		
		if (this.$route.query.create_time) {
			this.searchForm.create_time = this.$route.query.create_time;
		}
		
		if (this.searchForm.mm_type === 'medicine') {
			this.title = '医药奖项'
		} else if (this.searchForm.mm_type === 'apparatus') {
			this.title = '医疗器械奖项'
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;
			this.$http.post({
				url: '/Prize/listPage',
				data: this.searchForm,
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			});
		},
		pageChange(page) {
			this.page = page;
			this.getList();
		},
	}
}
</script>
