<template>
    <div>
        <van-cell class="lee-picker box-shadow border-radius" :title-class="showValue === placeholder ? 'dark-3' : ''" :title="showValue" is-link arrow-direction="down" :border="false" @click="showPop"/>
        <van-popup v-model="show" position="bottom" style="width: 100%;" @click-overlay="hidePop" @click-close-icon="hidePop">
            <van-picker
                :default-index="defaultIndex"
                title="标题"
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                @cancel="onCancel"
                @change="onChange"
            />
        </van-popup>
    </div>
</template>

<script>
export default {
    name: "DatePicker",
    data() {
        return {
            columns: [],
            show:false,
            defaultIndex:0,
            value:''
        };
    },
    model:{
        prop:'values',
        event:'confirm'
    },
    props:{
        minYear:{
            type:Number,
            default:1940
        },
        maxYear:{
            type:Number,
            default: new Date().getFullYear()
        },
        values:{
            type:[String,Number],
            default: new Date().getFullYear()
        },
        placeholder:{
            type:String,
            default:'-请选择-'
        }
    },
    created() {
        for (let i=this.maxYear;i >= this.minYear;i--){
            this.columns.push(i);
        }

        this.value = this.values;

        this.defaultIndex = this.columns.indexOf(this.value);
    },
    computed:{
        showValue(){
            return this.defaultIndex >= 0 ? this.columns[this.defaultIndex]+'年' : this.placeholder;
        }
    },
    watch:{
        values(n,o){
            this.value = n;
            this.defaultIndex = this.columns.indexOf(n);
        }
    },
    methods: {
        onConfirm(value, index) {
            this.$emit('confirm',value);
            this.show = false;
        },
        onChange(picker, value, index) {
            this.$emit('change',value);
        },
        onCancel() {
            this.$emit('cancel');
            this.show = false;
        },
        showPop(){
            this.show = true;
        },
        hidePop(){
            this.show = false;
        }
    },
}
</script>
