<template>
	<lee-page bg-img="/images/bg_head_5.jpg" title="研究报告" sub-title="SUBDIVISION REPORTS AND DATA" des="" :search="true" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'搜索'}]" :height="250">
		<div class="wrap1100 padding-bottom-100 padding-top-50">
			<div class="cl"></div>
			<template v-if="list.length > 0">
<!--				<div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{ total }}</b> 条相关内容</div>-->
				<div class="bg-gray-5 pd-20 cl mg-b-20 hand" v-for="(item,index) in list" @click="$router.push({path:(item.type === 'domain' ? '/domain/detail/' : '/industry/detail/')+item.id})">
					<div class="z bd-r-w-1 bd-dark-8 bd-style-solid hide" style="width: 100px;height: 60px;">
						<div class="dark-7 f-28 lh-40">{{$moment(item.create_time).format('DD')}}</div>
						<div class="dark-7 f-14 lh-20">{{$moment(item.create_time).format('YYYY.M')}}</div>
					</div>
					<div class="z pd-l-r-30" style="width: 900px">
						<div class="f-18" v-html="item.title">{{item.title}}</div>
						<div class="mg-t-10" v-if="item.tags && item.tags.length > 0"><el-tag type="info" class="bd-radius-0 h-24 lh-24 hand c-b mg-r-10" v-for="(st,si) in item.tags">{{st}}</el-tag></div>
					</div>
					<div class="y lh-50 f-30 dakr-2">&gt;</div>
				</div>
				<div class="text-center margin-top-100">
					<el-pagination
						background
						layout="pager"
						:page-size="pageSize"
						:current-page.sync="searchForm.page"
						:total="total"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</template>
			<div class="text-center padding-top-bottom-40 f20 dark-3" v-else>
				未查询到相关内容
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			list: [],
			pageSize: 12,
			total: 0,
			searchForm: {
				page: 1,
				title: '',
				title_type: ''
			}
		}
	},
	created() {
		if (!this.$utils.isEmptyObject(this.$route.query)) {
			this.searchForm = {...this.$route.query};
		}
		
		this.searchForm.page = this.searchForm.page ? parseInt(this.searchForm.page) : 1;
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;
			
			this.$http.post({
				url: '/Report/listPage',
				data: {...this.searchForm, pageSize: this.pageSize},
				success(res) {
					_this.list = res.list;
					_this.total = res.totalCount;
				}
			})
		},
		pageChange(page) {
			this.searchForm.page = page;
			
			this.$router.replace({query: {...this.searchForm}});
			// this.getList();
		}
	}
}
</script>
