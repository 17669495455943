<template>
	<lee-page bg-img="/images/bg_head_4.jpg" title="医药数据" sub-title="MEDICAL DATA" :height="250" title-class="margin-top-90" :bread="[{title:'行业数据',url:'/hydata'},{title:breadList[active]}]">
		<div :class="$isMobile ? 'padding-left-right bg-light' : 'bg-img-11 padding-top-bottom-50 padding-top-50'" :style="{backgroundColor:$isMobile ? '' : '#f8f9fa'}">
			<check-vip akey="hydata-yiyao">
<!--				<div class="wrap1100">-->
<!--					<el-row :gutter="10">-->
<!--						<el-col :span="3" v-for="(item,index) in tabList">-->
<!--							<div :class="active === item.type ? 'bg-c-b light' : 'dark-1'" class="text-center padding-top-bottom box-shadow-blue-small bd-radius-6 f-14 hand" @click="onChangeDataTab(item.type)">-->
<!--								<span class="vm bg-light border-radius text-center inline-block" style="width:24px;height:24px;line-height: 20px;">-->
<!--									<img :src="'/images/icon/'+item.img" width="20" height="20"/>-->
<!--								</span>-->
<!--								<span class="vm mg-l-5">{{ item.title }}</span>-->
<!--							</div>-->
<!--						</el-col>-->
<!--					</el-row>-->
<!--				</div>-->
				<div class="wrap1100 padding-bottom-100">
					<lee-year-tab :list="yearList"></lee-year-tab>
					<div class="box-shadow padding-30 bg-light margin-top-20">
						<div class="cl f34">
							<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#00A3DC" class="z margin-right"></lee-img-icon>
							<div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'"><!--医药/医疗器械数据平台行业数据-->行业数据 -
								医药{{ title }}
							</div>
						</div>
						<div class="f16 dark-2" v-if="active === 'zygy'">
							<p v-if="data && data.zygy && data.zygy.now">
								根据国家统计局和工信部公布数据的数据，{{ nowYear }}年规模以上制药工业企业实现主营业务收入{{ data.zygy.now.total_income }}亿元，按照可比因素计算同比{{ data.zygy.now.income_rate >= 0 ? '增长' : '减少' }}{{ data.zygy.now.income_rate ? Math.abs(data.zygy.now.income_rate / 100).toFixed(2) : 0 }}%，增速较上年{{ data.zygy.now.income_rate ? (data.zygy.now.income_rate - data.zygy.last.income_rate >= 0 ? '上升' : '下降') : '上升' }}{{ data.zygy.now.income_rate ? (Math.abs((data.zygy.now.income_rate - data.zygy.last.income_rate) / 100).toFixed(2)) : 0 }}%个百分点。实现销售利润{{ data.zygy.now.total_profit ? data.zygy.now.total_profit : 0 }}亿元，按照可比口径计算同比{{ data.zygy.now.profit_rate ? (data.zygy.now.profit_rate >= 0 ? '增长' : '减少') : '增长' }}{{ data.zygy.now.profit_rate ? Math.abs(data.zygy.now.profit_rate / 100).toFixed(2) : 0 }}%，增速较去年{{ data.zygy.now.profit_rate ? (data.zygy.now.profit_rate - data.zygy.last.profit_rate >= 0 ? '上升' : '下降') : '上升' }}{{ data.zygy.now.profit_rate ? (Math.abs((data.zygy.now.profit_rate - data.zygy.last.profit_rate) / 100).toFixed(2)) : 0 }}%个百分点。
							</p>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar1"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar14"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
						</div>
						<div class="f16 dark-2" v-if="active === 'inout'">
							<p v-if="data && data.inout">
								{{ nowYear }}年，我国医药保健品进出口总额{{ data.inout.now.total_income + data.inout.now.total_profit }}亿元，同比增长{{ parseFloat(data.inout.now.income_rate + data.inout.now.profit_rate).toFixed(2) }}%。其中，出口{{ data.inout.now.total_profit }}亿元，{{ data.inout.now.profit_rate >= 0 ? '增长' : '减少' }}{{ Math.abs(data.inout.now.profit_rate) }}%；进口{{ data.inout.now.total_income }}亿元，{{ data.inout.now.income_rate >= 0 ? '增长' : '减少' }}{{ Math.abs(data.inout.now.income_rate) }}%。</p>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar2_2"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar2"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
						</div>
						<div class="f16 dark-2" v-if="active === 'business'">
							<p v-if="data && data.business">
								经中物联医药物流分会推算，{{ nowYear }}年全国医药销售规模为{{ data.business.now.total_income }}亿元，较{{ nowYear - 1 }}年{{ data.business.now.income_rate >= 0 ? '增长' : '减少' }}{{ Math.abs(data.business.now.income_rate) }}%，同比增长率{{ data.business.now.income_rate - data.business.last.income_rate >= 0 ? '增长' : '回落' }}{{ Math.abs(data.business.now.income_rate - data.business.last.income_rate).toFixed(2) }}个百分点，整体保持平稳增长态势。受“两票制”等医改政策的影响，从2018年开始，全国医药销售规模增速开始放缓。{{ nowYear }}年药品集中带量采购持续扩围扩面，且药品价格空前下降，虽然集采品种不多，但对医药市场整体的影响不容忽略。</p>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar3"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
						</div>
						<div class="f16 dark-2" v-if="active === 'client'">
							<p v-if="data && data.client">
								{{ nowYear }}年度我国公立医院、零售药店和公立基层医疗三大终端销售金额{{ data.client.now.total_income }}亿元，同比{{ nowYear - 1 }}年{{ data.client.now.income_rate >= 0 ? '增长' : '减少' }}{{ Math.abs(data.client.now.income_rate) }}%，终端销售增速持续放缓。</p>
							<!--                        <p>如果加入未统计的“民营医院、私人诊所、村卫生室”，则{{nowYear}}年我国药品终端总销售额超过{{data.client.now.total_income}}20000亿元。</p>-->
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar4"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
							<p v-if="data && data.client && data.client.now.values">
								从实现药品销售的三大终端的销售额分布来看，{{ data.client.now.values[0].name }}市场份额最大，{{ nowYear }}年销售占比为{{ (parseFloat(data.client.now.values[0].val.income_percent) * 100).toFixed(2) }}%，较{{ nowYear - 1 }}年{{ parseFloat(data.client.now.values[0].val.income_percent) - parseFloat(data.client.last.values[0].val.income_percent) >= 0 ? '上升' : '下降' }}{{ (Math.abs(parseFloat(data.client.now.values[0].val.income_percent) - parseFloat(data.client.last.values[0].val.income_percent)) * 100).toFixed(2) }}%，市场份额占比继续走低，但是仍然居于绝对主导地位；{{ data.client.now.values[1].name }}市场份额占比为{{ (parseFloat(data.client.now.values[1].val.income_percent) * 100).toFixed(2) }}%，较{{ nowYear - 1 }}年{{ parseFloat(data.client.now.values[1].val.income_percent) - parseFloat(data.client.last.values[1].val.income_percent) >= 0 ? '上升' : '下降' }}{{ (Math.abs(parseFloat(data.client.now.values[1].val.income_percent) - parseFloat(data.client.last.values[1].val.income_percent)) * 100).toFixed(2) }}%，终端销售金额持续提升；受医改强基层和分级诊疗政策的影响，{{ data.client.now.values[2].name }}{{ nowYear }}年市场份额较{{ nowYear - 1 }}年提升0.3%，首次达到{{ (parseFloat(data.client.now.values[2].val.income_percent) * 100).toFixed(2) }}%，市场份额连续5年持续提升。</p>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar5"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
						</div>
						<div class="f16 dark-2" v-if="active === 'wlfy'">
							<p v-if="data && data.wlfy && data.wlfy.now">
								{{ nowYear }}年我国医药物流总费用为{{ data.wlfy.now.total_income }}亿元，较{{ nowYear - 1 }}年{{ data.wlfy.now.income_rate >= 0 ? '增长' : '减少' }}{{ Math.abs(data.wlfy.now.income_rate) }}%。
								<!--受宏观经济下行和医改政策等因素的影响，同比增速回落2.51个百分点。整体上看，虽然增速略有放缓，但是我国医药物流费用规模呈稳步持续上升的趋势。--></p>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar6"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
						</div>
						<div class="f16 dark-2" v-if="active === 'ccwlcl'">
							<p v-if="data && data.ccwlcl && data.ccwlcl.now.values">
								各种类仓库中，{{ data.ccwlcl.now.values[0].name }}占比最大，总面积达到{{ data.ccwlcl.now.values[0].val.income }}万平方米，占比达到{{ (parseFloat(data.ccwlcl.now.values[0].val.income_percent) * 100).toFixed(2) }}%。{{ data.ccwlcl.now.values[1].name }}和{{ data.ccwlcl.now.values[2].name }}面积分别为{{ data.ccwlcl.now.values[1].val.income }}万平方米和{{ data.ccwlcl.now.values[2].val.income }}万平方米，占比分别为{{ (parseFloat(data.ccwlcl.now.values[1].val.income_percent) * 100).toFixed(2) }}%和{{ (parseFloat(data.ccwlcl.now.values[2].val.income_percent) * 100).toFixed(2) }}%。</p>
							<div class="margin-auto" style="width: 100%">
								<lee-pie-echarts
									ref="pie1"
									:grid-bottom="$isMobile ? 100 : 30"
									:height="$isMobile ? '300px' : '350px'"
									:show-legend="false"
									label-format="{b}:{d}%"
									label-pos="outside"
									:radius="['0%','50%']"></lee-pie-echarts>
							</div>
							<p v-if="data && data.ccwlcl">
								截止{{ nowYear }}年底，我国医药物流仓储面积为{{ data.ccwlcl.now.total_income }}万平方米，较{{ nowYear - 1 }}年{{ data.ccwlcl.now.income_rate >= 0 ? '增长' : '减少' }}{{ data.ccwlcl.now.income_rate }}%
								<template
									v-if="data.ccwlcl.last">
									，增长率同比{{ data.ccwlcl.last.income_rate >= data.ccwlcl.now.income_rate ? '回落' : '增幅' }}{{ Math.abs(data.ccwlcl.last.income_rate - data.ccwlcl.now.income_rate).toFixed(2) }}个百分点
								</template>
								。
							</p>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar7"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
						</div>
						<div class="f16 dark-2" v-if="active === 'ccwlcl2'">
							<p v-if="data && data.ccwlcl">
								{{ nowYear }}年我国医药物流行业自有车辆大幅度增加，其总数为{{ data.ccwlcl.now.sub_total_income }}辆，较去年同比{{ data.ccwlcl.now.sub_income_rate >= 0 ? '增长' : '减少' }}{{ Math.abs(data.ccwlcl.now.sub_income_rate) }}%，增速有所回落。</p>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar8"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
							<!--                            <p v-if="data && data.ccwlcl && data.ccwlcl.now.sub_values">在{{nowYear}}年我国医药物流自有运输车辆{{data.ccwlcl.now.sub_total_income}}辆中，其中冷藏车{{data.ccwlcl.now.sub_values[1].val.income}}辆，占比为{{ (parseFloat(data.ccwlcl.now.sub_values[1].val.income_percent) * 100).toFixed(2)}}%，较{{nowYear - 1}}年同比{{(parseFloat(data.ccwlcl.now.sub_values[1].val.income) / parseFloat(data.ccwlcl.last.sub_values[1].val.income) - 1) >= 0 ? '增长' : '减少'}}{{ ((parseFloat(data.ccwlcl.now.sub_values[1].val.income) / parseFloat(data.ccwlcl.last.sub_values[1].val.income) - 1) * 100).toFixed(2)}}%。</p>-->
							<!--                            <el-row :gutter="20">-->
							<!--                                <el-col :md="12" :xs="24">-->
							<!--                                    <div class="margin-auto" style="width: 100%;">-->
							<!--                                        <lee-bar-echarts-->
							<!--                                            ref="bar12"-->
							<!--                                            :grid-bottom="$isMobile ? 50 : 30"-->
							<!--                                            :height="$isMobile ? '250px' : '350px'"-->
							<!--                                            :show-legend="true"-->
							<!--                                            :show-left="true"-->
							<!--                                            :show-right="false"-->
							<!--                                        ></lee-bar-echarts>-->
							<!--                                    </div>-->
							<!--                                </el-col>-->
							<!--                                <el-col :md="12" :xs="24">-->
							<!--                                    <div class="margin-auto" style="width: 100%;">-->
							<!--                                        <lee-bar-echarts-->
							<!--                                            ref="bar13"-->
							<!--                                            :grid-bottom="$isMobile ? 50 : 30"-->
							<!--                                            :height="$isMobile ? '250px' : '350px'"-->
							<!--                                            :show-legend="true"-->
							<!--                                            :show-left="true"-->
							<!--                                            :show-right="false"-->
							<!--                                        ></lee-bar-echarts>-->
							<!--                                    </div>-->
							<!--                                </el-col>-->
							<!--                            </el-row>-->
						</div>
						<div class="f16 dark-2" v-if="active === 'llscgm'">
							<p v-if="data && data.llscgm && data.llscgm.now && data.llscgm.now.values">
								近几年，医药冷链市场规模持续扩大，据分会推算，{{ nowYear }}年医药冷链销售总额约为{{ data.llscgm.now.total_income }}亿元，与{{ nowYear - 1 }}年相比，同比{{ parseFloat(data.llscgm.now.income_rate) >= 0 ? '增长' : '减少' }}{{ Math.abs(data.llscgm.now.income_rate) }}%。按照15%的复合增长率计算，预计{{ parseInt(nowYear) + 1 }}年医药冷链市场规模约为{{ (parseFloat(data.llscgm.now.total_income) * 1.15).toFixed(2) }}亿元。根据不同产品占比来看，{{ data.llscgm.now.values[0].name }}生物制品占比最高，达到{{ (parseFloat(data.llscgm.now.values[0].val.income_percent) * 100).toFixed(2) }}%。从品类划分来看，疫苗和血液制品都属于生物制品，为了更清晰统计各细分品类占比，故将疫苗和血液制品单独进行统计。</p>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar9"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar10"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
							<div class="margin-auto" style="width: 100%">
								<lee-pie-echarts
									ref="pie2"
									:grid-bottom="$isMobile ? 100 : 30"
									:height="$isMobile ? '300px' : '350px'"
									:show-legend="false"
									label-format="{b}:{d}%"
									label-pos="outside"
									:radius="['0%','50%']"></lee-pie-echarts>
							</div>
							<p v-if="data && data.llscgm && data.llscgm.now">
								{{ nowYear }}年医药冷链物流费用约为{{ data.llscgm.now.total_profit }}亿元，与{{ nowYear - 1 }}年同比{{ parseFloat(data.llscgm.now.profit_rate) >= 0 ? '增长' : '减少' }}{{ Math.abs(data.llscgm.now.profit_rate) }}%。医药冷链市场占医药物流行业整体市场占比为{{ ((data.llscgm.now.total_income / data.llscgm.now.total_profit)).toFixed(2) }}%左右，却消耗掉20%的物流费用。</p>
							<div class="margin-auto" style="width: 100%;">
								<lee-bar-echarts
									ref="bar11"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="true"
									:show-left="true"
									:show-right="false"
								></lee-bar-echarts>
							</div>
						</div>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			yearList: [],
			nowYear: '',
			
			startYear: '',
			endYear: '',
			
			yearRange: [],
			breadList: {
				zygy:'工业端',
				inout:'进出口',
				business:'商业端',
				client:'终端',
				wlfy:'物流总费用',
				ccwlcl:'物流仓储',
				ccwlcl2:'物流车辆',
				llscgm:'冷链市场规模'
			},
			
			tabList: [
				{title: '工业端', link: '#a1', type: 'zygy', img: 'icon_9.png'},
				{title: '进出口', link: '#a2', type: 'inout', img: 'icon_10.png'},
				{title: '商业端', link: '#a3', type: 'business', img: 'icon_11.png'},
				{title: '终端', link: '#a4', type: 'client', img: 'icon_12.png'},
				{title: '物流总费用', link: '#a5', type: 'wlfy', img: 'icon_13.png'},
				{title: '物流仓储', link: '#a6', type: 'ccwlcl', img: 'icon_14.png'},
				{title: '物流车辆', link: '#a6', type: 'ccwlcl2', img: 'icon_15.png'},
				{title: '冷链市场规模', link: '#a7', type: 'llscgm', img: 'icon_12.png'},
			],
			tabMobileList: [
				{title: '工业端', link: '#a1'},
				{title: '进出口', link: '#a2'},
				{title: '商业端', link: '#a3'},
				{title: '终端', link: '#a4'},
				{title: '物流费用', link: '#a5'},
				{title: '仓储及车辆', link: '#a6'},
				{title: '冷链市场', link: '#a7'},
			],
			
			active: 'zygy',
			
			title: '',
			
			data: null,
		}
	},
	created() {
		this.active = this.$route.params.type ? this.$route.params.type : 'zygy';
		
		for (let i = 0; i < this.tabList.length; i++) {
			if (this.tabList[i].type === this.active) {
				this.title = this.tabList[i].title;
			}
		}
		
		// this.nowYear = this.$route.query.year ? this.$route.query.year : '';
		// let _now = new Date();
		// let _startYear = _now.getFullYear() - 1;
		// let _endYear = _startYear - 8;
		//
		// this.startYear = _startYear;
		//
		// for (let i = _startYear;i > _endYear;i--){
		//     this.yearList.push({label:i+'年',value:i});
		// }
		//
		// this.endYear = this.yearList[this.yearList.length - 1].value;
		// this.yearRange = [_startYear-4,_startYear];
		
		// this.nowYear = this.$route.query.year ? this.$route.query.year : this.yearList[0].value;
	},
	mounted() {
		// this.getAll(this.nowYear);
		this.getYears();
	},
	methods: {
		onChangeDataTab(e) {
			// this.getTotal1(this.nowYear);
			this.$router.push({path: '/hydata/medical/' + e});
		},
		changeTab(v) {
			this.nowYear = v.value;
			// this.getAll(this.nowYear);
			this.$router.replace({query: {year: v.value}});
		},
		getAll(year) {
			for (let i in this) {
				if (i.indexOf('getTotal') === 0) {
					this[i](year);
				}
			}
		},
		
		findYear(year, list) {
			if (list.length > 0) {
				for (let i = 0; i < list.length; i++) {
					if (list[i].year === year) {
						return list[i];
					}
				}
				return false;
			}
			return false;
		},
		
		getYears() {
			let _this = this;
			
			this.$http.post({
				url: '/Hydata/getYiyaoYears',
				data: {type: this.active === 'ccwlcl2' ? 'ccwlcl' : this.active},
				success(res) {
					for (let i = 0; i < res.length; i++) {
						_this.yearList.push({label: res[i] + '年', value: res[i]});
					}
					
					_this.endYear = res[res.length - 1];
					
					_this.nowYear = _this.$route.query.year ? _this.$route.query.year : res[0];
					
					_this.getTotal1(_this.nowYear);
				}
			});
		},
		
		getTotal1(year) {
			let _this = this;
			this.$http.post({
				url: '/Hydata/getYiyaoData',
				data: {year: year, yearlist: [year, year - 4]},
				success(res) {
					// console.log(res);
					_this.data = res;
					
					let _bar1 = {bar: [], line: [], xaxis: []};
					let _bar14 = {bar: [], line: [], xaxis: []};
					let _list1 = res.zygy.list;
					
					if (_list1.length > 0) {
						for (let i = 0; i < _list1.length; i++) {
							_bar1.bar.push(_list1[i].total_income);
							_bar14.bar.push(_list1[i].total_profit);
							_bar1.line.push(parseFloat(_list1[i].income_rate).toFixed(2));
							_bar14.line.push(parseFloat(_list1[i].profit_rate).toFixed(2));
							_bar1.xaxis.push(_list1[i].year.toString());
							_bar14.xaxis.push(_list1[i].year.toString());
						}
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '收入', type: 'bar', color: 'blue', data: _bar1.bar, dw: '亿元'},
									{name: '收入增长率', type: 'line', color: 'purple', data: _bar1.line, dw: '%'},
								],
								_bar1.xaxis
							);
						}
						if (_this.$refs.bar14) {
							_this.$refs.bar14.initData(
								[
									{name: '利润', type: 'bar', color: 'green', data: _bar14.bar, dw: '亿元'},
									{name: '利润增长率', type: 'line', color: 'red', data: _bar14.line, dw: '%'},
								],
								_bar14.xaxis
							);
						}
					} else {
						_this.$refs.bar1.style.display = 'none';
						_this.$refs.bar1.style.width = '100px';
					}
					
					//进出口
					let _bar2 = {bar: [], line: [], xaxis: []};
					let _bar2_2 = {bar: [], line: [], xaxis: []};
					let _list2 = res.inout.list;
					
					for (let i = 0; i < _list2.length; i++) {
						_bar2.bar.push(_list2[i].total_income);
						_bar2.line.push(_list2[i].income_rate);
						_bar2.xaxis.push(_list2[i].year.toString());
						
						_bar2_2.bar.push(_list2[i].total_profit);
						_bar2_2.line.push(_list2[i].profit_rate);
						_bar2_2.xaxis.push(_list2[i].year.toString());
					}
					if (_this.$refs.bar2) {
						_this.$refs.bar2.initData(
							[
								{name: '进口额', type: 'bar', color: 'blue', data: _bar2.bar, dw: '亿元'},
								{name: '增长率', type: 'line', color: 'purple', data: _bar2.line, dw: '%'},
							],
							_bar2.xaxis
						);
					}
					
					if (_this.$refs.bar2_2) {
						_this.$refs.bar2_2.initData(
							[
								{name: '出口额', type: 'bar', color: 'blue', data: _bar2_2.bar, dw: '亿元'},
								{name: '增长率', type: 'line', color: 'purple', data: _bar2_2.line, dw: '%'},
							],
							_bar2.xaxis
						);
					}
					
					//bar3
					let _bar3 = {bar: [], line: [], xaxis: []};
					let _list3 = res.business.list;
					
					for (let i = 0; i < _list3.length; i++) {
						_bar3.bar.push(_list3[i].total_income);
						_bar3.line.push(_list3[i].income_rate);
						_bar3.xaxis.push(_list3[i].year.toString());
					}
					if (_this.$refs.bar3) {
						_this.$refs.bar3.initData(
							[
								{name: '医药商业总额', type: 'bar', color: 'blue', data: _bar3.bar, dw: '亿元'},
								{name: '增长率', type: 'line', color: 'purple', data: _bar3.line, dw: '%'},
							],
							_bar3.xaxis
						);
					}
					
					//bar4  bar5
					let _bar4 = {bar: [], line: [], xaxis: []};
					let _list4 = res.client.list;
					
					let _bar5 = {bar: [{name: '公立医药终端', type: 'bar', color: 'jj', data: [], stack: 'total', dw: '亿元'}, {name: '零食药店终端', type: 'bar', color: 'jj', data: [], stack: 'total', dw: '亿元'}, {name: '基层医疗机构终端', type: 'bar', color: 'jj', data: [], stack: 'total', dw: '亿元'}], xaxis: []};
					
					for (let i = 0; i < _list4.length; i++) {
						_bar4.bar.push(_list4[i].total_income);
						_bar4.line.push(_list4[i].income_rate);
						_bar4.xaxis.push(_list4[i].year.toString());
						
						_bar5.bar[0].data.push(_list4[i].values[0].val.income);
						_bar5.bar[1].data.push(_list4[i].values[1].val.income);
						_bar5.bar[2].data.push(_list4[i].values[2].val.income);
						
						_bar5.xaxis.push(_list4[i].year.toString());
					}
					
					if (_this.$refs.bar4) {
						_this.$refs.bar4.initData(
							[
								{name: '销售额', type: 'bar', color: 'blue', data: _bar4.bar, dw: '亿元'},
								{name: '增长率', type: 'line', color: 'purple', data: _bar4.line, dw: '%'},
							],
							_bar4.xaxis
						);
					}
					
					if (_this.$refs.bar5) {
						_this.$refs.bar5.initData(
							_bar5.bar,
							_bar5.xaxis
						);
					}
					
					//bar6
					let _bar6 = {bar: [], line: [], xaxis: []};
					let _list6 = res.wlfy.list;
					
					for (let i = 0; i < _list6.length; i++) {
						_bar6.bar.push(_list6[i].total_income);
						_bar6.line.push(_list6[i].income_rate);
						_bar6.xaxis.push(_list6[i].year.toString());
					}
					
					if (_this.$refs.bar6) {
						_this.$refs.bar6.initData(
							[
								{name: '医药物流费用', type: 'bar', color: 'blue', data: _bar6.bar, dw: '亿元'},
								{name: '增长率', type: 'line', color: 'purple', data: _bar6.line, dw: '%'},
							],
							_bar6.xaxis
						);
					}
					
					//bar7 bar8 bar12 bar13
					let _bar7 = {bar: [], line: [], xaxis: []};
					let _bar8 = {bar: [], bar2: [], line: [], xaxis: []};
					let _bar12 = {bar: [], line: [], xaxis: []};
					let _bar13 = {bar: [], xaxis: []};
					
					let _list7 = res.ccwlcl.list;
					
					for (let i = 0; i < _list7.length; i++) {
						_bar7.bar.push(_list7[i].total_income);
						_bar7.line.push(_list7[i].income_rate);
						_bar7.xaxis.push(_list7[i].year.toString());
						
						_bar8.bar.push(_list7[i].sub_values[0].val.income);
						_bar8.bar2.push(_list7[i].sub_values[1].val.income);
						_bar8.line.push(_list7[i].sub_income_rate);
						_bar8.xaxis.push(_list7[i].year.toString());
						
						_bar12.bar.push(_list7[i].sub_values[0].val.income);
						_bar12.line.push((_list7[i].sub_values[1].val.income_percent * 100).toFixed(2));
						_bar12.xaxis.push(_list7[i].year.toString());
						
						_bar13.bar.push((_list7[i].sub_values[1].val.income_percent * 100).toFixed(2));
						_bar13.xaxis.push(_list7[i].year.toString());
					}
					
					if (_this.$refs.bar7) {
						_this.$refs.bar7.initData(
							[
								{name: '仓储面积', type: 'bar', color: 'blue', data: _bar7.bar, dw: '万平方米'},
								{name: '增长率', type: 'line', color: 'purple', data: _bar7.line, dw: '%'},
							],
							_bar7.xaxis
						);
					}
					
					if (_this.$refs.bar8) {
						_this.$refs.bar8.initData(
							[
								{name: '自有车辆', type: 'bar', color: 'blue', data: _bar8.bar, dw: '辆'},
								{name: '自有冷藏车', type: 'bar', color: 'darkblue', data: _bar8.bar2, dw: '辆'},
								{name: '增长率', type: 'line', color: 'purple', data: _bar8.line, dw: '%'},
							],
							_bar8.xaxis
						);
					}
					
					if (_this.$refs.bar12) {
						_this.$refs.bar12.initData(
							[
								{name: '冷藏车', type: 'bar', color: 'blue', data: _bar12.bar, dw: '辆'},
								{name: '占自有车辆总数比例', type: 'line', color: 'purple', data: _bar12.line, dw: '%'},
							],
							_bar12.xaxis
						);
					}
					
					if (_this.$refs.bar13) {
						_this.$refs.bar13.initData(
							[
								{name: '占自有车辆总数比例', type: 'bar', color: 'blue', data: _bar13.bar, dw: '%'},
							],
							_bar13.xaxis
						);
					}
					
					//pie1
					let _pie1 = [];
					let _pielist1 = res.ccwlcl.now;
					
					for (let i = 0; i < _pielist1.values.length; i++) {
						_pie1.push({name: _pielist1.values[i].name, value: _pielist1.values[i].val.income});
					}
					
					if (_this.$refs.pie1) {
						_this.$refs.pie1.initData(_pie1, '万平方米');
					}
					
					//bar9
					let _bar9 = {bar: [], xaxis: []};
					let _list9 = res.llscgm.list;
					
					for (let i = 0; i < _list9.length; i++) {
						_bar9.bar.push(_list9[i].total_income);
						_bar9.xaxis.push(_list9[i].year.toString());
					}
					
					if (_this.$refs.bar9) {
						_this.$refs.bar9.initData(
							[
								{name: '医药冷链销售总额', type: 'bar', color: 'blue', data: _bar9.bar, dw: '亿元'},
							],
							_bar9.xaxis
						);
					}
					
					//bar10  bar11 pie2
					let _bar10 = {bar: [], bar2: [], xaxis: []};
					let _list10_now = res.llscgm.now;
					let _list10_last = res.llscgm.last;
					
					let _bar11 = {bar: [], bar2: [], xaxis: []};
					
					let _pie2 = [];
					let _pie2list = res.llscgm.now;
					
					if (_list10_now && _list10_last) {
						for (let i = 0; i < _list10_last.values.length; i++) {
							_bar10.bar.push(_list10_last.values[i].val.income);
							_bar10.xaxis.push(_list10_last.values[i].name);
							
							_bar11.bar.push(_list10_last.values[i].val.profit);
							_bar11.xaxis.push(_list10_last.values[i].name);
						}
						
						for (let i = 0; i < _list10_now.values.length; i++) {
							_bar10.bar2.push(_list10_now.values[i].val.income);
							
							_bar11.bar2.push(_list10_now.values[i].val.profit);
						}
						
						if (_this.$refs.bar10) {
							_this.$refs.bar10.initData(
								[
									{
										name: (parseInt(year) - 1).toString(),
										type: 'bar',
										color: 'blue',
										data: _bar10.bar, dw: '亿元'
									},
									{name: year.toString(), type: 'bar', color: 'green', data: _bar10.bar2, dw: '亿元'},
								],
								_bar10.xaxis
							);
						}
						
						if (_this.$refs.bar11) {
							_this.$refs.bar11.initData(
								[
									{
										name: (parseInt(year) - 1).toString(),
										type: 'bar',
										color: 'blue',
										data: _bar11.bar, dw: '亿元'
									},
									{name: year.toString(), type: 'bar', color: 'green', data: _bar11.bar2, dw: '亿元'},
								],
								_bar11.xaxis
							);
						}
					}
					
					for (let i = 0; i < _pie2list.values.length; i++) {
						_pie2.push({name: _pie2list.values[i].name, value: _pie2list.values[i].val.income});
					}
					
					if (_this.$refs.pie2) {
						_this.$refs.pie2.initData(_pie2, '亿元');
					}
				}
			})
		},
		
		// getTotal1(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'zygy', year:this.yearRange},
		//         success(res) {
		//             _this.data1 = res;
		//
		//             let _bardata = {bar:[],bar2:[],line:[],line2:[],xaxis:[]};
		//
		//             for(let i=0;i < res.length;i++) {
		//                 _bardata.bar.push(res[i].total_income);
		//                 _bardata.bar2.push(res[i].total_profit);
		//                 _bardata.line.push(res[i].income_rate <= 0 ? '' :(res[i].income_rate * 100).toFixed(2));
		//                 _bardata.line2.push(res[i].profit_rate <= 0 ? '' : (res[i].profit_rate * 100).toFixed(2));
		//                 _bardata.xaxis.push(res[i].year.toString());
		//             }
		//             if(_this.$refs.bar1) {
		//                 _this.$refs.bar1.initData(
		//                     [
		//                         {name: '收入(亿元)', type: 'bar', color: 'blue', data: _bardata.bar},
		//                         {name: '利润(亿元)', type: 'bar', color: 'green', data: _bardata.bar2},
		//                         {name: '收入增长率', type: 'line', color: 'purple', data: _bardata.line},
		//                         {name: '利润增长率', type: 'line', color: 'red', data: _bardata.line2},
		//                     ],
		//                     _bardata.xaxis
		//                 );
		//             }
		//         }
		//     })
		// },
		// getTotal14(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'zygy', year:year},
		//         success(res) {
		//             _this.data1_1 = res;
		//         }
		//     })
		// },
		// getTotal2(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'zygy', year:year},
		//         success(res) {
		//             _this.data2 = res;
		//         }
		//     })
		// },
		// getTotal3(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'inout', year:this.yearRange},
		//         success(res) {
		//             _this.data3 = res;
		//
		//             let _bardata = {bar:[],line:[],xaxis:[]};
		//
		//             for(let i=0;i < res.length;i++) {
		//                 _bardata.bar.push(res[i].total_income);
		//                 _bardata.line.push((res[i].income_rate * 100).toFixed(2));
		//                 _bardata.xaxis.push(res[i].year.toString());
		//             }
		//             if(_this.$refs.bar2) {
		//                 _this.$refs.bar2.initData(
		//                     [
		//                         {name: '进口额(亿美元)', type: 'bar', color: 'blue', data: _bardata.bar},
		//                         {name: '增长率', type: 'line', color: 'purple', data: _bardata.line},
		//                     ],
		//                     _bardata.xaxis
		//                 );
		//             }
		//         }
		//     })
		// },
		// getTotal16(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'inout', year:year},
		//         success(res) {
		//             _this.data3_3 = res;
		//         }
		//     })
		// },
		// getTotal4(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'business', year:this.yearRange},
		//         success(res) {
		//             _this.data4 = res;
		//
		//             let _bardata = {bar:[],line:[],xaxis:[]};
		//
		//             for(let i=0;i < res.length;i++) {
		//                 _bardata.bar.push(res[i].total_income);
		//                 _bardata.line.push((res[i].income_rate * 100).toFixed(2));
		//                 _bardata.xaxis.push(res[i].year.toString());
		//             }
		//             if(_this.$refs.bar3) {
		//                 _this.$refs.bar3.initData(
		//                     [
		//                         {name: '医药商业总额(亿元)', type: 'bar', color: 'blue', data: _bardata.bar},
		//                         {name: '增长率', type: 'line', color: 'purple', data: _bardata.line},
		//                     ],
		//                     _bardata.xaxis
		//                 );
		//             }
		//         }
		//     })
		// },
		// getTotal44(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'business', year:year},
		//         success(res) {
		//             _this.data4_4 = res;
		//         }
		//     })
		// },
		// getTotal5(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'client', year:this.yearRange},
		//         success(res) {
		//             _this.data5 = res;
		//
		//             let _bardata = {bar:[],line:[],xaxis:[]};
		//
		//             let _bardata2 = {bar:[{name:'公立医药终端',type:'bar',color:'jj',data:[],stack:'total'},{name:'零食药店终端',type:'bar',color:'jj',data:[],stack:'total'},{name:'基层医疗机构终端',type:'bar',color:'jj',data:[],stack:'total'}],xaxis:[]};
		//
		//             for(let i=0;i < res.length;i++) {
		//                 _bardata.bar.push(res[i].total_income);
		//                 _bardata.line.push((res[i].income_rate * 100).toFixed(2));
		//                 _bardata.xaxis.push(res[i].year.toString());
		//
		//                 _bardata2.bar[0].data.push(res[i].values[0].val.income);
		//                 _bardata2.bar[1].data.push(res[i].values[1].val.income);
		//                 _bardata2.bar[2].data.push(res[i].values[2].val.income);
		//
		//                 _bardata2.xaxis.push(res[i].year.toString());
		//             }
		//
		//             if (_this.$refs.bar4) {
		//                 _this.$refs.bar4.initData(
		//                     [
		//                         {name: '销售额(亿元)', type: 'bar', color: 'blue', data: _bardata.bar},
		//                         {name: '增长率', type: 'line', color: 'purple', data: _bardata.line},
		//                     ],
		//                     _bardata.xaxis
		//                 );
		//             }
		//
		//             if (_this.$refs.bar5) {
		//                 _this.$refs.bar5.initData(
		//                     _bardata2.bar,
		//                     _bardata2.xaxis
		//                 );
		//             }
		//         }
		//     })
		// },
		// getTotal55(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'client', year:year},
		//         success(res) {
		//             _this.data5_5 = res;
		//         }
		//     })
		// },
		// getTotal7(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'wlfy', year:this.yearRange},
		//         success(res) {
		//             _this.data6 = res;
		//
		//             let _bardata = {bar:[],line:[],xaxis:[]};
		//
		//             for(let i=0;i < res.length;i++) {
		//                 _bardata.bar.push(res[i].total_income);
		//                 _bardata.line.push((res[i].income_rate * 100).toFixed(2));
		//                 _bardata.xaxis.push(res[i].year.toString());
		//             }
		//
		//             if(_this.$refs.bar6) {
		//                 _this.$refs.bar6.initData(
		//                     [
		//                         {name: '医药物流费用(亿元)', type: 'bar', color: 'blue', data: _bardata.bar},
		//                         {name: '增长率', type: 'line', color: 'purple', data: _bardata.line},
		//                     ],
		//                     _bardata.xaxis
		//                 );
		//             }
		//         }
		//     })
		// },
		// getTotal77(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'wlfy', year:year},
		//         success(res) {
		//             _this.data6_6 = res;
		//         }
		//     })
		// },
		// getTotal8(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'ccwlcl', year:this.yearRange},
		//         success(res) {
		//             _this.data7 = res;
		//             let _bardata = {bar:[],line:[],xaxis:[]};
		//
		//             for(let i=0;i < res.length;i++) {
		//                 _bardata.bar.push(res[i].total_income);
		//                 _bardata.line.push((res[i].income_rate * 100).toFixed(2));
		//                 _bardata.xaxis.push(res[i].year.toString());
		//             }
		//
		//             if(_this.$refs.bar7) {
		//                 _this.$refs.bar7.initData(
		//                     [
		//                         {name: '仓储面积(万平方米)', type: 'bar', color: 'blue', data: _bardata.bar},
		//                         {name: '增长率', type: 'line', color: 'purple', data: _bardata.line},
		//                     ],
		//                     _bardata.xaxis
		//                 );
		//             }
		//         }
		//     })
		// },
		// getTotal88(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'ccwlcl', year:year},
		//         success(res) {
		//             _this.data7_7 = res;
		//         }
		//     })
		// },
		// getTotal9(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'ccwlcl', year:year},
		//         success(res) {
		//
		//             _this.data8 = res;
		//             let _pie1 = [];
		//
		//             for(let i=0;i < res.values.length;i++) {
		//                 _pie1.push({name:res.values[i].name,value:res.values[i].val.income});
		//             }
		//
		//             if(_this.$refs.pie1) {
		//                 _this.$refs.pie1.initData(_pie1);
		//             }
		//         }
		//     })
		// },
		// getTotal10(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'ccwlcl', year:this.yearRange},
		//         success(res) {
		//             _this.data9 = res;
		//             let _bardata = {bar:[],line:[],xaxis:[]};
		//
		//             let _bardata2 = {bar:[],line:[],xaxis:[]};
		//
		//             let _bardata3 = {bar:[],xaxis:[]};
		//
		//             for(let i=0;i < res.length;i++) {
		//                 _bardata.bar.push(res[i].sub_total_income);
		//                 _bardata.line.push((res[i].sub_income_rate * 100).toFixed(2));
		//                 _bardata.xaxis.push(res[i].year.toString());
		//
		//                 _bardata2.bar.push(res[i].sub_values[1].val.income);
		//                 _bardata2.line.push((res[i].sub_values[1].val.income_percent * 100).toFixed(2));
		//                 _bardata2.xaxis.push(res[i].year.toString());
		//
		//                 _bardata3.bar.push((res[i].sub_values[1].val.income_percent * 100).toFixed(2));
		//                 _bardata3.xaxis.push(res[i].year.toString());
		//             }
		//
		//             if(_this.$refs.bar8) {
		//                 _this.$refs.bar8.initData(
		//                     [
		//                         {name: '自有车辆(辆)', type: 'bar', color: 'blue', data: _bardata.bar},
		//                         {name: '增长率', type: 'line', color: 'purple', data: _bardata.line},
		//                     ],
		//                     _bardata.xaxis
		//                 );
		//             }
		//
		//             if(_this.$refs.bar12) {
		//                 _this.$refs.bar12.initData(
		//                     [
		//                         {name: '冷藏车(辆)', type: 'bar', color: 'blue', data: _bardata2.bar},
		//                         {name: '占自有车辆总数比例', type: 'line', color: 'purple', data: _bardata2.line},
		//                     ],
		//                     _bardata2.xaxis
		//                 );
		//             }
		//
		//             if(_this.$refs.bar13) {
		//                 _this.$refs.bar13.initData(
		//                     [
		//                         {name: '占自有车辆总数比例', type: 'bar', color: 'blue', data: _bardata3.bar},
		//                     ],
		//                     _bardata3.xaxis
		//                 );
		//             }
		//         }
		//     })
		// },
		// getTotal1010(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'ccwlcl', year:year},
		//         success(res) {
		//             _this.data9_9 = res;
		//         }
		//     })
		// },
		// getTotal11(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'llscgm', year:this.yearRange},
		//         success(res) {
		//             _this.data10 = res;
		//             let _bardata = {bar:[],xaxis:[]};
		//
		//             for(let i=0;i < res.length;i++) {
		//                 _bardata.bar.push(res[i].total_income);
		//                 _bardata.xaxis.push(res[i].year.toString());
		//             }
		//
		//             if(_this.$refs.bar9) {
		//                 _this.$refs.bar9.initData(
		//                     [
		//                         {name: '医药冷链销售总额(亿元)', type: 'bar', color: 'blue', data: _bardata.bar},
		//                     ],
		//                     _bardata.xaxis
		//                 );
		//             }
		//         }
		//     })
		// },
		// getTotal12(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'llscgm', year:[parseInt(year)-1,year]},
		//         success(res) {
		//             _this.data11 = res;
		//             let _bardata = {bar:[],bar2:[],xaxis:[]};
		//
		//             let _bardata2 = {bar:[],bar2:[],xaxis:[]};
		//
		//             if(_this.data11[0] && _this.data11[1]) {
		//                 for (let i = 0; i < res[0].values.length; i++) {
		//                     _bardata.bar.push(res[0].values[i].val.income);
		//                     _bardata.xaxis.push(res[0].values[i].name);
		//
		//                     _bardata2.bar.push(res[0].values[i].val.profit);
		//                     _bardata2.xaxis.push(res[0].values[i].name);
		//                 }
		//
		//                 for (let i = 0; i < res[1].values.length; i++) {
		//                     _bardata.bar2.push(res[1].values[i].val.income);
		//
		//                     _bardata2.bar2.push(res[1].values[i].val.profit);
		//                 }
		//
		//                 if (_this.$refs.bar10) {
		//                     _this.$refs.bar10.initData(
		//                         [
		//                             {
		//                                 name: (parseInt(year) - 1).toString(),
		//                                 type: 'bar',
		//                                 color: 'blue',
		//                                 data: _bardata.bar
		//                             },
		//                             {name: year, type: 'bar', color: 'green', data: _bardata.bar2},
		//                         ],
		//                         _bardata.xaxis
		//                     );
		//                 }
		//
		//                 if (_this.$refs.bar11) {
		//                     _this.$refs.bar11.initData(
		//                         [
		//                             {
		//                                 name: (parseInt(year) - 1).toString(),
		//                                 type: 'bar',
		//                                 color: 'blue',
		//                                 data: _bardata2.bar
		//                             },
		//                             {name: year, type: 'bar', color: 'green', data: _bardata2.bar2},
		//                         ],
		//                         _bardata.xaxis
		//                     );
		//                 }
		//             }
		//         }
		//     })
		// },
		// getTotal13(year){
		//     let _this = this;
		//     this.$http.post({
		//         url: '/Hydata/getData',
		//         data: {type: 'llscgm', year:year},
		//         success(res) {
		//             _this.data12 = res;
		//             let _pie = [];
		//
		//             for(let i=0;i < res.values.length;i++) {
		//                 _pie.push({name:res.values[i].name,value:res.values[i].val.income});
		//             }
		//
		//             if(_this.$refs.pie2) {
		//                 _this.$refs.pie2.initData(_pie);
		//             }
		//         }
		//     })
		// },
	}
}
</script>
