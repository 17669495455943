import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Moment from 'moment';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/main.css'
import './assets/css/all.scss'
import 'vant/lib/index.css';
import 'font-awesome/css/font-awesome.min.css'

import Config from './script/config';
import Utils from './script/utils';
import User from './script/user';
import Http from './script/http';
import EchartsJs from './echartScript/echartsJs';
import RegExp from './script/RegExp';

Vue.use(ElementUI);

import {
	Popup,
	Divider,
	Icon,
	DropdownMenu,
	DropdownItem,
	Cell,
	CellGroup,
	Picker,
	Tag,
	Tab,
	Tabs,
	Toast,
	Button,
	Area,
	Field,
	RadioGroup,
	Radio,
	List,
	Empty
} from 'vant';

Vue.use(Popup);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Picker);
Vue.use(Tag);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(List);
Vue.use(Toast);
Vue.use(Button);
Vue.use(Area);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);

// import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';

// Vue.use(regionData);
// Vue.use(CodeToText);

// Vue.prototype.$regionData = regionData;
// Vue.prototype.$CodeToText = CodeToText;

//components
import leeHeader from './components/Header';
import leePage from './components/Page';
import BlockItem from './components/item/BlockItem';
import Select from './components/Select';
import Input from './components/Input';
import Search from './components/Search';
import ImgIcon from './components/ImgIcon';
import headTItle from './components/headTItle';
import YearPicker from './components/YearPicker';
import MapHot from './components/MapHot';
import FileDown from "@/components/FileDown";
import GoodView from "@/components/GoodView";
import UpDownTag from "@/components/UpDownTag";
import CommentView from "@/components/CommentView";
import HashTab from "@/components/HashTab";
import YearTab from "@/components/YearTab";
import StateTag from "@/components/StateTag";
import UserMenu from "@/components/UserMenu";
import UserPage from "@/components/UserPage";
import FormCell from '@/components/Cell';
import UserTopTab from "@/components/UserTopTab";
import Swiper from "@/components/Swiper";
import TimeLine from "@/components/TimeLine";
import PayView from "@/components/PayView";
import BarEcharts from "@/components/BarEcharts";
import PieEcharts from "@/components/PieEcharts";
import BarEchartsHor from "@/components/BarEchartsHor";
import ChinaEcharts from "@/components/ChinaEcharts";
import FooterFix from "@/components/FooterFix";
import UserMainPage from "@/components/UserMainPage";
import SafeProgress from "@/components/SafeProgress";
import BaseCell from "@/components/BaseCell";
import LeeTabs from "@/components/Tabs";
import AddOrder from "@/components/AddOrder";
import CheckVip from "@/components/CheckVip";
import IndexHyData from "@/components/IndexHyData";
import IndexHyData1 from "@/components/IndexHyData1";
import IndexHyData2 from "@/components/IndexHyData2";
import IndexHyData3 from "@/components/IndexHyData3";
import IndexHyData4 from "@/components/IndexHyData4";
import IndexHyData5 from "@/components/IndexHyData5";
import IndexHyData6 from "@/components/IndexHyData6";
import IndexHyData7 from "@/components/IndexHyData7";
import HeadImgUpload from "@/components/HeadImgUpload";
import VipFileDown from "@/components/VipFileDown";
import LoginButton from "@/components/LoginButton";
import LoginDialog from "@/components/LoginDialog";
import VipCheckButton from "@/components/VipCheckButton";
import VipCheckBox from './components/VipCheckBox'
import VipBuyDialog from './components/VipBuyDialog'
import Video from './components/Video'
import FileUpload from './components/FileUpload'

Vue.component('lee-header', leeHeader);
Vue.component('lee-page', leePage);
Vue.component('BlockItem', BlockItem);
Vue.component('lee-select', Select);
Vue.component('lee-input', Input);
Vue.component('lee-search', Search);
Vue.component('lee-img-icon', ImgIcon);
Vue.component('lee-head-title', headTItle);
Vue.component('lee-year-picker', YearPicker);
Vue.component('lee-map-hot', MapHot);
Vue.component('lee-file-down', FileDown);
Vue.component('lee-vip-file-down', VipFileDown);
Vue.component('lee-good-view', GoodView);
Vue.component('lee-updown-tag', UpDownTag);
Vue.component('lee-comment-view', CommentView);
Vue.component('lee-hash-tab', HashTab);
Vue.component('lee-year-tab', YearTab);
Vue.component('lee-state-tag', StateTag);
Vue.component('lee-user-menu', UserMenu);
Vue.component('lee-user-page', UserPage);
Vue.component('lee-cell', FormCell);
Vue.component('lee-user-top-tab', UserTopTab);
Vue.component('lee-swiper', Swiper);
Vue.component('lee-time-line', TimeLine);
Vue.component('lee-pay-view', PayView);
Vue.component('lee-bar-echarts', BarEcharts);
Vue.component('lee-pie-echarts', PieEcharts);
Vue.component('lee-bar-echarts-hor', BarEchartsHor);
Vue.component('lee-china-echarts', ChinaEcharts);
Vue.component('lee-footer-fix', FooterFix);
Vue.component('lee-user-main-page', UserMainPage);
Vue.component('lee-safe-progress', SafeProgress);
Vue.component('lee-base-cell', BaseCell);
Vue.component('lee-tabs', LeeTabs);
Vue.component('lee-add-order', AddOrder);
Vue.component('check-vip', CheckVip);
Vue.component('lee-index-hydata',IndexHyData);
Vue.component('lee-index-hydata1',IndexHyData1);
Vue.component('lee-index-hydata2',IndexHyData2);
Vue.component('lee-index-hydata3',IndexHyData3);
Vue.component('lee-index-hydata4',IndexHyData4);
Vue.component('lee-index-hydata5',IndexHyData5);
Vue.component('lee-index-hydata6',IndexHyData6);
Vue.component('lee-index-hydata7',IndexHyData7);
Vue.component('head-img-upload', HeadImgUpload);
Vue.component('login-button', LoginButton);
Vue.component('login-dialog', LoginDialog);
Vue.component('vip-check-button', VipCheckButton);
Vue.component('vip-check-box', VipCheckBox);
Vue.component('vip-buy-dialog', VipBuyDialog);
Vue.component('Video', Video);
Vue.component('file-upload',FileUpload);

let echarts = require('echarts');
let chinaData = require('./assets/json/china.json')


router.beforeEach((to, from, next) => {
	if (Utils.isMobile().versions.mobile) {
		if(to.path === '/miniqrcode'){
			next();
		}else {
			next('/miniqrcode');
		}
	} else {
		let _notToken = ['/login', '/register', '/qrlogin', '/findpwd'];
		let _belogin = ['/qa/me'];

		if (User.getToken()) {
			if (_notToken.indexOf(to.path) >= 0) {
				next('/');
			} else {
				next();
			}
		} else {
			if (to.path.indexOf('/user') === 0 || _belogin.indexOf(to.path) >= 0) {
				ElementUI.Message.error('请先登录');
				next('/login');
			} else {
				next();
			}
		}
	}
});

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts;
Vue.prototype.$china = chinaData;
Vue.prototype.$moment = Moment;
Vue.prototype.$config = Config;
Vue.prototype.$utils = Utils;
Vue.prototype.$user = User;
Vue.prototype.$echartsJs = EchartsJs;
Vue.prototype.$http = Http;
Vue.prototype.$regexp = RegExp;

let browser = Utils.isMobile();
Vue.prototype.$isMobile = false;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
