<template>
  <lee-page bg-img="/images/bg_head_1.jpg" :title="info.title" height="250" title-class="margin-top-90" :bread="[{title:'问卷调查',url:'/survey'},{title:info.title}]" v-if="info">
	  <div class="wrap1100 mg-t-50 mg-b-100">
		  <div class="cl"></div>
		  <template v-if="$user.getToken()">
			  <div class="bg-light padding-20 box-shadow margin-bottom-20" v-if="info.des_html" v-html="info.des_html"></div>
			  <el-form :model="formData" ref="form">
				  <div class="bg-light padding-20 box-shadow" style="margin-bottom: 20px;" v-for="(item,index) in list" v-if="jumpList.indexOf(item.id) >= 0 || item.is_jump === 0" ref="collist">
					  {{index + 1}}、{{item.q_required === 1 ? '(必答) ' : ''}}{{item.title}}
					  <el-form-item :prop="'list.'+index+'.value'" :rules="[{required:item.q_required === 1,message:'请选择答案'}]" v-if="item.type === 'radio'" :show-message="true">
						  <el-radio-group v-model="formData.list[index].value[0]" style="margin-top:10px;width:100%;">
							  <div style="border:1px solid #eee;margin-bottom:10px;" v-for="(st,si) in item.child">
								  <el-radio sty :label="st.id" style="display: block;padding:10px;" @change="(e)=>onChangeRadio(e,item,st,index,si)">{{st.title}}</el-radio>
								  <el-form-item style="margin:0 10px 10px;" :required="st.is_required === 1" :prop="'list.'+index+'.input.'+st.id+'.value'" :rules="[{required:st.is_required === 1,message:'请输入内容'}]" v-if="formData.list[index].value[0] === st.id && st.is_input === 1" :show-message="false">
									  <el-input v-model="formData.list[index].input[st.id].value" :placeholder="'请输入内容'+(st.is_required === 1 ? '(必填)' : '')" manxlength="100" show-word-limit></el-input>
								  </el-form-item>
							  </div>
						  </el-radio-group>
					  </el-form-item>
					  <el-form-item :prop="'list.'+index+'.value'" :rules="[{required:item.q_required === 1,message:'请选择答案'}]" v-else-if="item.type === 'multi'" :show-message="true">
						  <el-checkbox-group v-model="formData.list[index].value" style="margin-top:10px;width:100%;">
							  <div style="border:1px solid #eee;margin-bottom:10px;" v-for="(st,si) in item.child">
								  <el-checkbox :label="st.id" style="display: block;line-height: 1 !important;padding:10px;" @change="(e)=>onChangeRadio(e,item,st,index,si)">{{st.title}}</el-checkbox>
								  <el-form-item style="margin:0 10px 10px;" :required="st.is_required === 1" :prop="'list.'+index+'.input.'+st.id+'.value'" :rules="[{required:st.is_required === 1,message:'请输入内容'}]" v-if="formData.list[index].value.indexOf(st.id) >= 0 && st.is_input === 1" :show-message="false">
									  <el-input v-model="formData.list[index].input[st.id].value" :placeholder="'请输入内容'+(st.is_required === 1 ? '(必填)' : '')" manxlength="100" show-word-limit></el-input>
								  </el-form-item>
							  </div>
						  </el-checkbox-group>
					  </el-form-item>
					  <el-form-item :show-message="true" :prop="'list.'+index+'.value'" :rules="[{required:item.q_required === 1,message:'请输入内容'}]" v-else>
						  <el-input class="margin-top" v-model="formData.list[index].value[0]" :placeholder="'请输入内容'+(item.q_required === 1 ? '(必填)' : '')" maxlength="100" show-word-limit></el-input>
					  </el-form-item>
					  <el-form-item label="上传附件(可选)" v-if="item.is_files === 1">
						  <file-upload v-model="formData.list[index].files_ids"></file-upload>
						  <!--		            <image-upload v-model="formData.list[index].files_ids"></image-upload>-->
					  </el-form-item>
				  </div>
			  </el-form>
			  <div class="text-center">
				  <el-button type="primary" @click="submit">提 交</el-button>
			  </div>
		  </template>
		  <div class="box-shadow bg-light green padding-20 text-center border-radius-5 margin-auto margin-top-30 margin-bottom-30" style="width: 500px;" v-else><i class="orange margin-right el-icon-warning"></i>你无权查看该信息，请登录<login-button></login-button></div>
	  </div>
  </lee-page>
</template>

<script>
export default {
	name: "detail",
	data() {
		return {
			id:'',
			info:null,
			list:[],
			formData:{
				list:[],
				start_time:0
			},
			jumpList:[]
		}
	},
	created() {
		this.id = this.$route.params.id ? this.$route.params.id : '';
	},
	mounted() {
		this.getDetail();
		this.getList();
	},
	methods: {
		resetJumpValue(){
			// console.log(this.jumpList);
			if(this.jumpList.length > 0){
				for(let i=0;i < this.formData.list.length;i++){
					if(this.jumpList.indexOf(this.formData.list[i].id) < 0 && this.formData.list[i].is_jump === 1){
						
						this.formData.list[i].value = [];
						
						for(let j in this.formData.list[i].input){
							this.formData.list[i].input[j].value = '';
						}
					}
				}
			}
		},
		
		cleanJump(question){
			if(question){
				if(question.jumpid && question.is_jump === 0){
					this.jumpList.splice(this.jumpList.indexOf(question.jumpid), 1);
				}else {
					if (question.child.length > 0) {
						for (let i = 0; i < question.child.length; i++) {
							if (this.jumpList.indexOf(question.child[i].jumpid) >= 0) {
								this.jumpList.splice(this.jumpList.indexOf(question.child[i].jumpid), 1);
							}
							
							for (let j = 0; j < this.list.length; j++) {
								if (this.list[j].id === question.child[i].jumpid && this.list[j].is_jump === 1) {
									this.cleanJump(this.list[j]);
								}
							}
						}
					}
				}
			}
		},
		
		onChangeRadio(e,qi,ai,qindex,aindex){
			if(qi.type === 'radio'){
				if(qi.jump_type === 1){
					for (let i=0;i < qi.child.length;i++){
						if(qi.child[i].jumpid && qi.child[i].jumpid !== '0'){
							let _index = this.jumpList.indexOf(qi.child[i].jumpid);
							if(_index >= 0){
								this.jumpList.splice(_index,1);
							}
						}
					}
					this.jumpList.push(ai.jumpid)
				}else{
					if (qi.jumpid && qi.jumpid !== '0' && this.jumpList.indexOf(qi.jumpid) < 0) {
						this.jumpList.push(qi.jumpid);
					}
				}
				
				for (let j in this.formData.list[qindex].input){
					if(j !== ai.id){
						this.formData.list[qindex].input[j].value = '';
					}
				}
				
			}else if(qi.type === 'multi'){
				if(e){
					if (qi.jump_type === 2) {
						if (qi.jumpid && qi.jumpid !== '0' && this.jumpList.indexOf(qi.jumpid) < 0) {
							this.jumpList.push(qi.jumpid);
						}
					} else if (qi.jump_type === 1) {
						if (ai.jumpid && ai.jumpid !== '0' && this.jumpList.indexOf(ai.jumpid) < 0) {
							this.jumpList.push(ai.jumpid);
						}
					}
				}else{
					if (qi.jump_type === 2) {
						if (qi.jumpid && qi.jumpid !== '0' && this.jumpList.indexOf(qi.jumpid) >= 0) {
							this.jumpList.splice(this.jumpList.indexOf(qi.jumpid),1);
						}
					} else if (qi.jump_type === 1) {
						if (ai.jumpid && ai.jumpid !== '0' && this.jumpList.indexOf(ai.jumpid) >= 0) {
							this.jumpList.splice(this.jumpList.indexOf(ai.jumpid),1);
						}
					}
					
					if(this.formData.list[qindex].input[ai.id]){
						this.formData.list[qindex].input[ai.id].value = '';
					}
					
					console.log(ai);
				}
			}
			
			this.resetJumpValue();
		},
		submit(){
			let _this = this;
			
			console.log(this.formData.start_time)
			
			this.$refs['form'].validate((valid) => {
				console.log(valid);
				if (valid) {
					_this.$http.post({
						url:'/SurveyLog/add',
						data:{tid:this.id,content:JSON.stringify(this.formData.list),start_time:this.formData.start_time,platform:'pc'},
						success(res){
							console.log(res);
							// _this.$alert('问卷提交成功，返回', '提示', {
							//   confirmButtonText: '立即返回',
							//   showClose:false,
							//   callback: action => {
							//     _this.$router.back();
							//   }
							// });
							_this.$router.replace({path:'/survey/done'});
						}
					})
				} else {
					_this.$message.error('调查问卷请填写完整');
					return false;
				}
			});
		},
		getDetail(){
			let _this = this;
			
			this.$http.post({
				url:'/SurveyTitle/detail',
				data:{id:this.id},
				success(res){
					
					if(res.state === 0 || res.state === 2){
						_this.$alert('此问卷已关闭', '提示', {
							confirmButtonText: '立即返回',
							showClose:false,
							callback: action => {
								_this.$router.back();
							}
						});
						return;
					}
					
					_this.info = res;
					_this.formData.start_time = _this.$moment().unix();
				},
				fail(err){
					if(err.code === -100){
						_this.$alert(err.msg, '提示', {
							confirmButtonText: '立即返回',
							showClose:false,
							callback: action => {
								_this.$router.back();
							}
						});
					}else{
						_this.$message.error(err.msg);
					}
				}
			})
		},
		
		getList(){
			///SurveyCol/listDigui
			let _this = this;
			
			this.$http.post({
				url:'/SurveyCol/listDigui',
				data:{id:this.id},
				success(res){
					console.log(res);
					let _form = [];
					
					for(let i=0;i < res.length;i++){
						let _data = {
							id:res[i].id,
							pid:res[i].pid,
							tid:res[i].tid,
							q_required:res[i].q_required,
							is_files:res[i].is_files,
							files_ids:[],
							type:res[i].type,
							title:res[i].title,
							value:res[i].type === 'input' || res[i] === 'radio' ? [] : [],
							is_jump:res[i].is_jump,
							input:{}
						}
						
						for(let j=0;j < res[i].child.length;j++){
							let _child = res[i].child[j];
							
							if(_child.is_input === 1){
								_data.input[_child.id] = {
									tid:_child.tid,
									pid:_child.pid,
									qid:res[i].id,
									aid:_child.id,
									is_input:_child.is_input,
									is_required:_child.is_required,
									value:''
								};
							}
						}
						
						_form.push(_data);
					}
					
					_this.$nextTick(()=>{
						_this.list = res;
						_this.formData.list = _form;
					})
				}
			})
		}
	},
	computed:{
		// getIndex(){
		//   this.$nextTick(()=>{
		//     let _cols = this.$refs['collist'];
		//     console.log(_cols);
		//     return _cols.length;
		//   })
		// }
	}
}
</script>
