<template>
	<lee-page bg-img="/images/bg_head_1.jpg" title="课件视频" sub-title="COURSEWARE VIDEO" height="250" title-class="margin-top-90">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<div class="bd-radius-10 hide bg-blue-line-0 pd-30 cl" v-if="speaker">
				<div class="z bd-radius hide h-200 w-200">
					<img :src="$utils.formatUrl(speaker.head_img)" style="width: 100%;height: 200px;"/>
				</div>
				<div class="z mg-l-50" style="width: 700px;">
					<div class="f-24">{{speaker.name}}老师</div>
					<div class="f-18 blue-0 mg-t-20">{{speaker.job}} {{speaker.name}}</div>
					<div class="f-16 dark-5 mg-t-20" style="white-space: pre-line;" v-html="speaker.content">{{speaker.content}}</div>
				</div>
			</div>
			<el-row :gutter="20" class="mg-t-20">
				<el-col :span="12" v-for="(item,index) in list" class="mg-b-20">
					<div class="bd-radius-10 hide bg-blue-line-0 pd-20 cl hand" style="height:220px;" @click="toShowVideo(item)">
						<div class="z bg-dark-4 hide rel" style="width: 280px;height: 180px;">
							<img :src="$utils.formatUrl(item.head_img)" style="height: 100%;width: 100%;"/>
							<div class="abs" style="top:0;bottom:0;left:0;right:0;width: 100%;height: 100%;background-color: rgba(0,0,0,.1)">
								<div class="rel text-center" style="height: 100%;width: 100%;">
									<i class="el-icon-video-play light" style="font-size: 50px;margin-top:65px;" v-if="item.type === 'video'"></i>
									<div style="line-height:46px;height: 50px;width: 50px;margin-top:65px;border-radius: 9999px;border:3px solid #fff;color:#fff;" class="text-center inline-block" v-if="item.type === 'ppt'">
										PPT
									</div>
								</div>
							</div>
						</div>
						<div class="z mg-l-10" style="width: 210px;">
							<div class="f-16">{{item.title}}</div>
							<div class="f-14 mg-t-10 dark-5 h-120">{{item.content}}</div>
							<div class="text-right">
								<el-button round size="mini" type="primary" class="bg-blue-0 light">点击预览&gt;</el-button>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<div class="text-center margin-top-100">
				<el-pagination
					background
					:page-size="searchForm.pageSize"
					:current-page.sync="searchForm.page"
					:total="totalCount"
					layout="prev, pager, next"
					@current-change="pageChange">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="视频播放" :visible.sync="show" width="840px" @close="onClose">
			<Video :src="video.url" :second="3" autoplay v-if="video"/>
		</el-dialog>
<!--		<vue-ppt :url="$utils.formatUrl(ppt.url)" v-if="ppt.url"/>-->
	</lee-page>
</template>

<script>
export default {
	name: "videodetail",
	data() {
		return {
			show:false,
			video:null,
			speaker_id:'',
			zhuanti_id:'',
			speaker:null,
			list:[],
			searchForm: {
				zhuanti_id:'',
				pageSize: 10,
				page: 1,
				speaker_id:''
			},
			totalCount:0
		}
	},
	created() {
		this.speaker_id = this.$route.query.speakerid ? this.$route.query.speakerid : '';
		this.searchForm.speaker_id = this.$route.query.speakerid ? this.$route.query.speakerid : '';
		this.zhuanti_id = this.$route.query.zhuantiid ? this.$route.query.zhuantiid : '';
	},
	mounted() {
		if(this.speaker_id){
			this.getSpeaker()
		}
		
		if(this.searchForm.speaker_id){
			this.getList();
		}
	},
	methods: {
		getSpeaker() {
			let _this = this;
			
			this.$http.post({
				url: '/Speaker/detail',
				data: {id:this.speaker_id},
				success(res) {
					_this.speaker = res;
				}
			});
		},
		getList() {
			let _this = this;
			
			this.$http.post({
				url: '/ZhuantiVideo/listPage',
				data: {...this.searchForm,state:1},
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			});
		},
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
		toShowVideo(item){
			if(item.type === 'video') {
				this.video = item;
				this.show = true;
			}else if(item.type === 'ppt'){
				window.open('https://view.officeapps.live.com/op/view.aspx?src='+item.url);
			}
		},
		onClose(){
			this.video = null;
		}
	}
}
</script>
