<template>
    <div class="y light margin-right sort-tag text-center f20" :class="type === 'up' ? 'bg-red' : 'bg-green'">
        <img :src="'/images/icon/icon_sort_'+type+'.png'" height="12"/>
        {{number}}
    </div>
</template>

<script>
export default {
    name: "UpDownTab",
    props:{
        type:{
            type:String,
            default:'up'
        },
        number:{
            type:[String,Number],
            default:10
        }
    }
}
</script>
