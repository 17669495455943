<template>
	<div>
		<div class="bg-light box-shadow-base pd-10" v-if="data">
			<div class="lh-40 f-14 bd-b-w-1 bd-dark-7 bd-style-solid">数据平台行业数据分析报告—医药商业</div>
			<div class="mg-t-10 f-14 dark-3">经中物联医药物流分会推算，{{nowYear}}年全国医药销售规模为{{data.now.total_income}}亿元，较{{nowYear-1}}年{{data.now.income_rate >= 0 ? '增长' : '减少'}}{{Math.abs(data.now.income_rate)}}%，同比增长率{{data.now.income_rate - data.last.income_rate >= 0 ? '增长' : '回落'}}{{ Math.abs(data.now.income_rate - data.last.income_rate).toFixed(2)}}个百分点，整体保持平稳增长态势。受“两票制”等医改政策的影响，从2018年开始，全国医药销售规模增速开始放缓。{{nowYear}}年药品集中带量采购持续扩围扩面，且药品价格空前下降，虽然集采品种不多，但对医药市场整体的影响不容忽略。</div>
		</div>
		<div class="mg-t-30 bg-light box-shadow-base pd-30">
			<lee-bar-echarts
				ref="bar3"
				height="400px"
				width="1000px"
				:show-legend="false"
				:show-right="false"
				:bar-width="40"
			></lee-bar-echarts>
		</div>
	</div>
</template>

<script>
export default {
    name: "IndexHyData2",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // },
        type:{
            type:String,
            default:''
        }
    },
    created() {

    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/Hydata/getYiyaoYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear,endYear){
            let _this = this;

            if(this.type) {
                this.$http.post({
                    url: '/Hydata/getTypeData',
                    data: {year: startYear, type: this.type, yearlist: [startYear, endYear]},
                    success(res) {
                        _this.data = res;

                        let _bar3 = {bar:[],line:[],xaxis:[]};
                        let _list3 = res.list;

                        for(let i=0;i < _list3.length;i++) {
                            _bar3.bar.push(_list3[i].total_income);
                            _bar3.line.push(_list3[i].income_rate);
                            _bar3.xaxis.push(_list3[i].year.toString());
                        }

                        if(_this.$refs.bar3) {
                            _this.$refs.bar3.initData(
                                [
                                    {name: '医药商业总额', type: 'bar', color: 'blue', data: _bar3.bar,dw:'亿元'},
                                    // {name: '增长率', type: 'line', color: 'purple', data: _bar3.line,dw:'%'},
                                ],
                                _bar3.xaxis
                            );
                        }
                    }
                })
            }
        },
    }
}
</script>
