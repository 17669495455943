<template>
	<lee-page bg-img="/images/bg_head_9.jpg" title="活动专栏" sub-title="CONTENT COLUMN" height="250" title-class="margin-top-90" class="bg-gray-5">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<lee-tabs :list="tabList" :default-index="atype" @change="onTabChange" class="margin-auto" style="width: 500px;"></lee-tabs>
			<template v-if="list.length > 0">
				<el-row :gutter="20" class="mg-t-30">
					<el-col :span="8" v-for="(item,index) in list">
						<div class="bg-light mg-b-20 hand" @click="$router.push({path:'/zhuanlan/detail',query:{id:item.id}})" style="height: 395px;">
							<img :src="$utils.formatUrl(item.head_img)" style="width: 100%;height: 200px;"/>
							<div class="pd-10">
								<div class="f-18 text-line-2" style="height: 50px;">{{item.title}}</div>
								<div class="f-14 dark-4 text-line-2 mg-t-5" style="height: 40px;">{{item.content}}</div>
								<div class="f-12 dark-4 mg-t-10">
									<i class="el-icon-location-information mg-r-10 f-14 vm"></i>{{item.address}}
								</div>
								<div class="f-12 dark-4 mg-t-5">
									<i class="el-icon-time mg-r-10 f-14 vm"></i>{{$moment(item.start_time).format('YYYY年MM月DD日')}}-{{$moment(item.end_time).format('YYYY年MM月DD日')}}
								</div>
								<div class="mg-t-15 c-b-2 f-12">查看更多&gt;</div>
							</div>
						</div>
					</el-col>
				</el-row>
				<div class="text-center margin-top-100">
					<el-pagination
						background
						:page-size="searchForm.pageSize"
						:current-page.sync="searchForm.page"
						:total="totalCount"
						layout="prev, pager, next"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</template>
			<template v-else>
				<div class="text-center pd-t-b-50 f20 dark-3">
					未查询到相关内容
				</div>
			</template>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			list: ['1'],
			totalCount: 0,
			searchForm: {
				pageSize: 10,
				page: 1,
				title: '',
			},
			tabList: [],
			atype:''
		}
	},
	created() {
	},
	mounted() {
		// this.getList();
		this.getTypeList();
	},
	methods: {
		onTabChange(i) {
			console.log(i);
			this.atype = i.value;
			this.getPolicyType();
		},
		getTypeList() {
			let _this = this;
			
			this.$http.post({
				url: '/Type/listAll',
				data: {akey:'zhuanti',state:1,orderBy:'sort',sortBy:'asc'},
				success(res) {
					console.log(res);
					_this.tabList = [];
					if(res.length > 0){
						for (let i=0;i < res.length;i++){
							_this.tabList.push({label: res[i].name, value: res[i].id})
						}
						_this.atype = _this.$route.query.mmtype ? _this.$route.query.mmtype : res[0].id;
						
						_this.getList();
					}
				}
			});
		},
		getList() {
			let _this = this;
			
			this.$http.post({
				url: '/Zhuanti/listPage',
				data: {...this.searchForm,type_zhuanti_id:this.atype,state:1},
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			});
		},
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
	}
}
</script>
