<template>
	<lee-page bg-img="/images/bg_head_1.jpg" title="问卷调查" height="250" title-class="margin-top-90" :bread="[{title:'问卷调查',url:'/survey'},{title:'提交完成'}]">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<div class="cl"></div>
			<div class="bg-light box-shadow border-radius-5 padding-top-bottom-100 text-center">
				<i class="el-icon-success blue" style="font-size: 60px;"></i>
				<div class="f16 margin-top-20 dark-2">感谢您的参与</div>
				<div class="f16 dark-2">现在将返回投票列表</div>
				<el-button type="primary" class="margin-top-20" @click="goBack">立即返回</el-button>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "done",
	data() {
		return {}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		goBack(){
			this.$router.back();
		}
	}
}
</script>
