<template>
    <div class="lee-input" :class="$isMobile ? 'mobile' : ''">
        <input @input="input" :placeholder="placeholder" v-model="value"/>
        <div class="append" v-if="$slots.append">
            <slot name="append"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Input",
    data(){
        return{
            value:''
        }
    },
    model:{
        prop:'values',
        event:'input'
    },
    props:{
        values:{
            type:String,
            default:''
        },
        placeholder:{
            type:String,
            default:'请输入'
        }
    },
    created() {
        this.value = this.values;
    },
    methods:{
        input(e){
            this.$emit('input',e.target.value);
        }
    }
}
</script>
