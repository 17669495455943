<template>
	<lee-page bg-img="/images/bg_head_1.jpg" title="问卷调查" :bread="[{title:'问卷调查'}]" height="250" title-class="margin-top-90">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<div class="cl"></div>
			<template v-if="list.length > 0">
<!--				<div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{ totalCount }}</b> 条相关内容</div>-->
				<div class="bg-gray-5 margin-bottom-20 hand rel" v-for="(item,index) in list" @click="$router.push({path:'/survey/detail/'+item.id})">
					<div class="hide">
						<div class="margin">
							<div class="cl">
								<div class="z green-line padding-right-20" style="line-height: 30px;">
									<img src="/images/icon/icon-999.png" height="22" class="margin-right"/>
									<span class="dark-0 vm f18" v-html="item.title"></span>
								</div>
							</div>
							<div class="f14 dark-2 text-line-2" v-html="item.clean_des" v-if="item.clean_des"></div>
							<div class="margin-top f14 dark-3" v-if="item.start_time">发布时间：{{ item.start_time }}</div>
						</div>
					</div>
					<span v-if="item.state === 1" class="bg-green-0 light f14" style="position: absolute;right:20px;top:0;border-radius:0 0 3px 3px;padding:3px 10px;">进行中</span>
					<span v-if="item.state === 2" class="f14" style="background-color:#eee;color:#777;position: absolute;right:20px;top:0;border-radius:0 0 3px 3px;padding:3px 10px;">已结束</span>
				</div>
				<div class="text-center margin-top-100">
					<el-pagination
						background
						layout="pager"
						:page-size="searchForm.pageSize"
						:current-page.sync="searchForm.page"
						:total="totalCount"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</template>
			<template v-else>
				<div class="text-center padding-top-bottom-40 f20 dark-3">
					未查询到相关内容
				</div>
			</template>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			list: [],
			searchForm: {
				page: 1,
				pageSize: 10,
				state: [[1, 2], 'in'],
				orderBy: 'state asc,start_time',
				sortBy: 'desc'
			},
			totalCount: 0
		}
	},
	created() {
		// this.id = this.formData.tid = this.$route.params.id ? this.$route.params.id : '';
	},
	mounted() {
		this.getList();
	},
	methods: {
		pageChange(e) {
			this.searchForm.page = e;
			this.getList();
		},
		getList() {
			let _this = this;
			
			this.$http.post({
				url: '/SurveyTitle/listPage',
				data: this.searchForm,
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		}
	}
}
</script>
