<template>
    <div class="report-tab" :class="$isMobile ? 'box-shadow border-radius-5 bg-light' : 'border-bottom-1'" :style="{borderBottomColor:color}">
        <div class="cl">
            <div class="z text-center" :class="$isMobile ? 'f12' : 'f18'" :style="{width:$utils.formatDecimal(100 / list.length,2) + '%'}" v-for="(item,index) in list">
                <a class="hand inline-block padding-top-bottom" :style="{borderBottom:activeIndex === item.link ? '5px solid '+color : '',color:activeIndex === index ? color : '#777'}" @click="click(item.link)">{{item.title}}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HashTab",
    data(){
        return{
            values:''
        }
    },
    props:{
        color:{
            type:String,
            default:'#00A3DC'
        },
        list:{
            type:Array,
            default:()=>{
                return []
            }
        },
        activeIndex:{
            type:[Number,String],
            default:''
        }
    },
    model:{
        prop:'value',
        event:'change'
    },
    created() {
        this.values = this.value;
    },
    methods:{
        click(e){
            console.log(e);
            this.values = e;

            this.$emit('change',e);
            // if(typeof e === 'string' && e.indexOf('#') === 0){
            //     document.querySelector(e).scrollIntoView();
            // }else {
            //     this.$router.push({path: item.link ? item.link : ''})
            // }
        }
    },
    watch:{
        value(n,o){
            this.values = n;
        }
    },
}
</script>
