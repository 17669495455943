<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="12" class="f16 dark-1" v-if="data">
          近年来医疗器械市场也正保持着稳步增长，中国物流与采购联合会医疗器械供应链分会经公开数据整理，不完全统计推算，{{nowYear}}年我国医疗器械行业市场规模为{{data.now.total_income}}亿元，同比增长{{data.now.income_rate.toFixed(2)}}%。其中，{{data.now.values[0].name}}领域市场规模比重仍保持最高，为{{data.now.values[0].val.income}}亿元，{{data.now.values[1].name}}领域市场规模达{{data.now.values[1].val.income}}亿元，{{data.now.values[2].name}}领域市场规模为{{data.now.values[2].val.income}}亿元，{{data.now.values[3].name}}领域市场增速为四大细分领域最高，实现了{{data.now.values[3].val.income}}亿元的市场规模。随着我国医疗器械行业的技术革新及产业链成熟，市场容量将会不断扩大。

<!--            近年来医疗器械市场也正保持着稳步增长，物流与采购联合会医疗器械供应链分会（以下简称“中物联医疗器械供应链分会”）经公开数据整理，不完全统计推算，{{nowYear}}年我国医疗器械行业市场规模为{{data.now.total_income}}亿元，同比增长{{(data.now.income_rate * 100).toFixed(2)}}%。其中，{{data.now.values[0].name}}领域市场规模比重仍保持最高，为{{data.now.values[0].val.income}}亿元，{{data.now.values[1].name}}领域市场规模达{{data.now.values[1].val.income}}亿元，{{data.now.values[2].name}}领域市场规模位居细分第二，为{{data.now.values[2].val.income}}亿元，{{data.now.values[3].name}}领域市场增速为四大细分领域最高，实现了{{data.now.values[3].val.income}}亿元的市场规模。随着我国医疗器械行业的技术革新及产业链成熟,市场容量将会不断扩大。根据Evaluate MedTech数据，全球医疗器械市场规模增速仅为5%左右，可见，未来中国医疗器械行业发展大有可为。-->
        </el-col>
        <el-col :span="12">
            <lee-bar-echarts
                ref="bar1"
                height="200px"
                width="512px"
                :show-legend="true"
                :show-left="true"
                :show-right="false"
                :bar-width="20"
            ></lee-bar-echarts>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData6",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // },
        type:{
            type:String,
            default:''
        }
    },
    created() {

    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/Hydata/getQixieYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear,endYear){
            let _this = this;

            if(this.type) {
                this.$http.post({
                    url: '/Hydata/getTypeData',
                    data: {year: startYear, type: this.type, yearlist: [startYear, endYear]},
                    success(res) {
                        _this.data = res;

                        let _bar1 = {bar:[],xaxis:[]};

                        let _list1 = res.list;

                        for(let i=0;i < _list1.length;i++) {
                            _bar1.bar.push(_list1[i].total_income);
                            _bar1.xaxis.push(_list1[i].year.toString());
                        }
                        if(_this.$refs.bar1) {
                            _this.$refs.bar1.initData(
                                [
                                    {name: '市场规模', type: 'bar', color: 'blue', data: _bar1.bar,dw:'亿元'},
                                ],
                                _bar1.xaxis
                            );
                        }
                    }
                })
            }
        },
    }
}
</script>
