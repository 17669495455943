<template>
    <div class="text-center margin-auto cl" v-if="data">
	    <div class="inline-block" style="margin:0 5px;">
		    <el-button type="info" icon="el-icon-view" circle></el-button>
		    <div class="f12 margin-top text-line-1 dark-3">{{data.view_count}}</div>
	    </div>
	    <div class="inline-block" style="margin:0 5px;" v-if="openComment">
		    <el-button type="info" icon="fa fa-commenting" circle></el-button>
		    <div class="f12 margin-top text-line-1 dark-3" v-if="!$isMobile">{{data.reply_count}}</div>
	    </div>
	    <div class="inline-block" style="margin:0 5px;" v-if="openLike">
		    <el-button :type="data.is_like === 1 ? 'danger' : 'info'" icon="fa fa-thumbs-up" circle @click="toLike"></el-button>
		    <div class="f12 margin-top text-line-1 dark-3">{{data.like_count}}</div>
	    </div>
	    <div class="inline-block" style="margin:0 5px;" v-if="openFavorite">
		    <el-button :type="data.is_favorite === 1 ? 'warning' : 'info'" icon="fa fa-star" circle @click="toFavorite"></el-button>
		    <div class="f12 margin-top text-line-1 dark-3">{{data.favorite_count}}</div>
	    </div>
	    
<!--        <el-row :gutter="$isMobile ? 10 : 30">-->
<!--            <el-col :span="6">-->
<!--                <div class="inline-block">-->
<!--                    <el-button  :size="$isMobile ? 'mini' : ''" type="info" icon="el-icon-view" circle></el-button>-->
<!--                    <div class="f12 margin-top text-line-1 dark-3" v-if="!$isMobile">{{data.view_count}}</div>-->
<!--                </div>-->
<!--            </el-col>-->
<!--            <el-col :span="6">-->
<!--                <div class="inline-block">-->
<!--                    <el-button :size="$isMobile ? 'mini' : ''" type="info" icon="fa fa-commenting" circle></el-button>-->
<!--                    <div class="f12 margin-top text-line-1 dark-3" v-if="!$isMobile">{{data.reply_count}}</div>-->
<!--                </div>-->
<!--            </el-col>-->
<!--            <el-col :span="6" v-if="openLike">-->
<!--                <div class="inline-block">-->
<!--                    <el-button :size="$isMobile ? 'mini' : ''" :type="data.is_like === 1 ? 'danger' : 'info'" icon="fa fa-thumbs-up" circle @click="toLike"></el-button>-->
<!--                    <div class="f12 margin-top text-line-1 dark-3" v-if="!$isMobile">{{data.like_count}}</div>-->
<!--                </div>-->
<!--            </el-col>-->
<!--            <el-col :span="6" v-if="openFavorite">-->
<!--                <div class="inline-block">-->
<!--                    <el-button :size="$isMobile ? 'mini' : ''" :type="data.is_favorite === 1 ? 'warning' : 'info'" icon="fa fa-star" circle @click="toFavorite"></el-button>-->
<!--                    <div class="f12 margin-top text-line-1 dark-3" v-if="!$isMobile">{{data.favorite_count}}</div>-->
<!--                </div>-->
<!--            </el-col>-->
<!--        </el-row>-->
      <login-dialog v-model="showLogin"></login-dialog>
    </div>
</template>

<script>

export default {
    name: "GoodView",
    data(){
        return{
            data:null,
          showLogin:false
        }
    },
    props:{
        id:{
            type:[Number,String],
            default:0,
            isRequired:true
        },
        akey:{
            type:String,
            default:'',
            isRequired:true
        },
	    openLike:{
					type:Boolean,
		    default:true
	    },
	    openFavorite:{
		    type:Boolean,
		    default:true
	    },
	    openComment:{
		    type:Boolean,
		    default:true
	    }
    },
    mounted() {
        this.getAll();
    },
    methods:{
        getAll(){
            let _this = this;

            if(this.id && this.akey) {
                this.$http.post({
                    url: '/total/detail',
                    data: {tid: this.id, akey: this.akey},
                    success(res) {
                        console.log(res);
                        _this.data = res;
                    }
                });
            }
        },
        toLike(){
            let _this = this;

            if(!this.$user.getToken()){
              this.showLogin = true;
              return;
            }

            if(this.id  && this.akey){
                this.$http.post({
                    url:'/UserLog/add',
                    data:{type:'like',akey:this.akey,tid:this.id},
                    success(res){
                        _this.getAll();
                    }
                })
            }
        },
        toFavorite(){
            //user/favorite/store
            let _this = this;

          if(!this.$user.getToken()){
            this.showLogin = true;
            return;
          }

            if(this.id  && this.akey){
                this.$http.post({
                    url:'/UserLog/add',
                    data:{type:'favorite',akey:this.akey,tid:this.id},
                    success(res){
                        _this.getAll();
                    }
                })
            }
        }
    }
}
</script>
