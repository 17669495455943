<template>
  <lee-page bg-img="/images/bg_head_5.jpg" title="行业发展报告" sub-title="INDUSTRY DEVELOPMENT REPORT" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'行业发展报告'}]" :height="250" title-class="margin-top-90" show-year :search="false" search-time-key="push_time">
	  <div class="wrap1100 mg-t-50 mg-b-100">
		  <template v-if="list.length > 0">
			  <div class="cl bg-gray-5 pd-20 mg-b-20 hand" v-for="(item,index) in list" @click="$router.push({path:'/industry/detail/'+item.id})">
					<div class="z">
					 <img :src="$utils.formatUrl(item.head_img)" width="100"/>
					</div>
				  <div class="z mg-l-20" style="width: 940px">
					  <div style="width: 700px">
						  <div class="f-18">{{item.title}}</div>
						  <div class="f-14 mg-t-10 dark-4 text-line-2">{{item.content}}</div>
					  </div>
					  <div class="cl mg-t-15">
						  <div class="z"></div>
						  <div class="y c-b f-14">{{$moment(item.create_time).format('YYYY.MM.DD')}}</div>
					  </div>
				  </div>
			  </div>
			  <div class="text-center margin-top-100">
				  <el-pagination
					  background
					  :hide-on-single-page="true"
					  layout="pager, jumper"
					  :page-size="searchForm.pageSize"
					  :current-page="searchForm.page"
					  :total="totalCount"
					  @current-change="pageChange">
				  </el-pagination>
			  </div>
		  </template>
		  <div class="text-center padding-top-bottom-40 f20 dark-3" v-else>
			  未查询到相关内容
		  </div>
	  </div>
  </lee-page>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      list: [],
      totalCount: 0,
      searchForm: {
	      pageSize: 10,
        page: 1,
        title: '',
	      type:'industry',
	      mm_type: 'medicine',
      },
      title: '',
      mm_type: 'medicine',
    }
  },
  created() {
    this.type = this.$route.params.type;

    this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
    this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let _this = this;
      this.$http.post({
        url: '/Report/listPage',
        data: this.searchForm,
        success(res) {
          _this.list = res.list;
          _this.totalCount = res.totalCount;
        }
      })
    },
    pageChange(page) {
      this.searchForm.page = page;
      this.$router.replace({query:{...this.searchForm}});
    },
  }
}
</script>
