<template>
    <div :id="id" :style="{height:height,width:width}"></div>
</template>

<script>
export default {
    name: "BarEcharts",
    data(){
        return{
            id:'echarts',
            option:{
                title:{
                    text:'',
                    left:'center',
                    show:false,
                    textStyle:{
                        fontWeight:'normal',
                        fontSize:18
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend:null,
                grid: {
                    left: 0,
                    right: '20%',
                    bottom: 0,
                    top:0,
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
                },
                series: []
            }
        }
    },
    props:{
        width:String,
        height:String,
        title:String,
        showLegend:{
            type:Boolean,
            default:true
        },
        legendOrient:{
            type:String,
            default:'horizontal'
        },
        titlePos:{
            type:String,
            default:'top'
        },
        titleSize:{
            type:Number,
            default:18
        },
        showTitle:{
            type:Boolean,
            default:true
        },
        barWidth:{
            type:Number,
            default:40
        },
        showLeft:{
            type:Boolean,
            default:true
        },
        showRight:{
            type:Boolean,
            default:true
        },
        data:{
            type:Array,
            default(){
                return []
            }
        },
        legendPos:{
            type:String,
            default:'bottom'
        },
        xAxis:{
            type:Array,
            default(){
                return []
            }
        },
        gridBottom:{
            type:Number,
            default:30
        }
    },
    created() {
        this.id = this.$utils.randomWords(8);

        if(this.xAxis.length > 0){
            this.option.yAxis.data = this.xAxis;
        }

        if(this.titlePos === 'top'){
            this.option.title.top = 0;
        }else if(this.titlePos === 'bottom'){
            this.option.title.bottom = 0;
        }


        this.option.grid.bottom = this.gridBottom;

        this.option.title.textStyle.fontSize = this.titleSize;
        this.option.title.text = this.title;
        this.option.title.show = this.showTitle;
        // this.option.xAxis[0].axisLabel.show = this.showLeft;
        // this.option.xAxis[1].axisLabel.show = this.showRight;
        //
        if(this.data.length > 0){
            let _legend = [];
            for (let i = 0;i < this.data.length;i++){
                let _data = this.data[i];

                _legend.push(_data.name);
                if(_data.type === 'bar'){
                    this.option.series.push(this.$echartsJs.getBar(_data.data, _data.name,_data.color,this.barWidth,'right'));
                    // if(_data.color === 'green') {
                    //     this.option.series.push(this.$echartsJs.getGreenBar(_data.data, _data.name,this.barWidth));
                    // }else if(_data.color === 'blue'){
                    //     this.option.series.push(this.$echartsJs.getBlueBar(_data.data, _data.name,this.barWidth));
                    // }
                }else if(_data.type === 'line'){
                    if(_data.color === 'purple') {
                        this.option.series.push(this.$echartsJs.getPurpleLine(_data.data,_data.name));
                    }
                }
            }

            if(this.showLegend) {
                this.option.legend = this.$echartsJs.getLegend(_legend,this.legendPos,this.legendOrient);
            }
        }
        console.log(this.option);
    },
    mounted() {
        this.echarts = this.$echarts.init(document.getElementById(this.id));
        this.echarts.setOption(this.option);
    },
    methods:{

    }
}
</script>
