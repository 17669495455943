<template>
    <lee-page bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" :height="280" title="我是细分领域报告名称" :bread="['212','2121']">
        <template v-if="$isMobile">
            <div class="margin-top padding-left-right">
                fds
            </div>
        </template>
        <template v-else>
            <div class="bg-img-2 padding-bottom-100 padding-top-50" style="background-color: #f8f9fa;">
                <div class="wrap1100">
                    <div class="box-shadow padding-30 bg-light margin-top-30">
                        <div class="green-line">
                            <div class="cl f30">
                                <img src="/images/icon/icon-36.png" height="22" class="no-v-mid"/>
                                2014年，富士康集团CEO郭台铭公开表示将纯电动汽车
                            </div>
                            <div class="f14 dark-3 cl margin-top">
                                <div class="z">发布机构：湖北省政府</div>
                                <div class="z">发布时间：2020-03-26</div>
                                <div class="z">文号：国药监注[2019]33号</div>
                            </div>
                        </div>
                        <div class="green f-b f18 margin-top-30">简介</div>
                        <div class="f16 dark-1">
                            <p>10月16日，富士康母公司鸿海集团表示，计划在2025-2027年占据全球电动汽车市场10%份额。“我们希望将台湾的电动汽车产业推向世界。” 鸿海董事长刘扬伟表示，但集团并不会打造自己的电动汽车品牌。 鸿海集团将通过建立“开放平台”的方式，向汽车制造商生产包含电池在内的电动汽车零部件，以及提供车联网服务。刘扬伟表示与车企的谈判已取得“相对良好”的进展。</p>
                        </div>
                        <div class="green f-b f18 margin-top-30">目录</div>
                        <div class="f16 dark-1" v-for="item in 6" style="line-height: 30px;">
                            {{item}}、目录目录目录目录
                        </div>
                    </div>
                    <div class="box-shadow bg-light padding-30 margin-top-30">
                        <lee-pay-view @submit="toPay"></lee-pay-view>
                    </div>
                    <div class="box-shadow padding-30 bg-light margin-top-30">
                        <lee-comment-view></lee-comment-view>
                    </div>
                </div>
            </div>
            <el-dialog
                title="填写收件人信息"
                :visible.sync="show">
                <el-form :model="formData" status-icon :rules="rules" ref="form" hide-required-asterisk>
                    <el-form-item class="green margin-bottom" label="收件人姓名" prop="consignee" style="width: 100%;">
                        <el-input v-model="formData.consignee" class="lee-input mobile" placeholder="请输入收件人姓名"></el-input>
                    </el-form-item>
                    <el-form-item class="green margin-bottom" label="收件人电话" prop="phone" style="width: 100%;">
                        <el-input v-model="formData.phone" class="lee-input mobile" placeholder="请输入收件人电话"></el-input>
                    </el-form-item>
                    <el-form-item class="green margin-bottom" label="收件人地址" prop="area_code" style="width: 100%;">
                        <el-cascader
                            class="lee-select mobile bg-gray-4 border-radius-5 vm"
                            style="width: 100%;"
                            :options="options"
                            v-model="cityData"
                            @change="handleChange">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item class="green margin-top" label="" prop="address" style="width: 100%;">
                        <el-input v-model="formData.address" class="lee-input mobile" placeholder="请输入详细地址"></el-input>
                    </el-form-item>
                </el-form>
                <div class="cl" slot="footer">
                    <el-button size="medium" type="success" class="box-shadow-green padding-left-50 padding-right-50">提交确认</el-button>
                </div>
            </el-dialog>
        </template>
    </lee-page>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
export default {
    name: "List",
    data(){
        return{
            cityData:[],
            options: regionData,
            show:false,
            formData:{
                consignee:'',
                phone:'',
                province_code:'',
                province_name:'',
                city_code:'',
                city_name:'',
                area_code:'',
                area_name:'',
                address:''
            },
            rules:{
                consignee: [{required: true}],
                phone: [{required: true}],
                province_code: [{required: true}],
                province_name: [{required: true}],
                city_code: [{required: true}],
                city_name: [{required: true}],
                area_code: [{required: true}],
                area_name: [{required: true}],
                address: [{required: true}],
            }
        }
    },
    created() {

    },
    methods:{
        toPay(v){
            this.show = true;
        },
        handleChange(e){
            this.formData.province_code = e[0];
            this.formData.city_code = e[1];
            this.formData.area_code = e[2];

            this.formData.province_name = CodeToText[e[0]];
            this.formData.city_name = CodeToText[e[1]];
            this.formData.area_name = CodeToText[e[2]];
        },
    }
}
</script>
