<template>
	<lee-page bg-img="/images/bg_head_7.jpg" sub-title="MEMBER CENTER" :height="250" :type="$isMobile ? type : 'light'" :back="$isMobile" :title="$isMobile ? '' : '会员中心'" title-class="margin-top-60" :mini-title="$route.meta" class="bg-gray-5">
		<div class="margin-top padding-left-right" v-if="$isMobile">
			<slot></slot>
		</div>
		<div class="padding-bottom-100 bg-center" v-else>
			<div class="cl"></div>
			<div class="wrap1100" style="margin-top: -70px;">
				<div class="cl">
					<div class="z" style="width: 200px;">
						<div class="padding light bg-dark-25 bd-radius-6 text-center line-height">
							<head-img-upload v-model="avatar" @change="onChangeAvatar"></head-img-upload>
							<div class="margin-top f18">{{ nickname ? nickname : '尚未设置' }}</div>
							<div class="f20 f-thin">{{ mobile ? mobile : '-' }}</div>
						</div>
						<vip-buy-dialog>
							<div class="bg-red-line-3 hand text-center light f-i bd-radius-6 hide mg-t-10 f-16 pd-t-b-15" style="user-select: none;">
								<i class="iconfont icon-huangguan mg-r-5 f-20"></i>购买VIP
							</div>
						</vip-buy-dialog>
						<lee-user-menu></lee-user-menu>
					</div>
					<div class="y bd-radius-6" style="width: 890px;">
						<div class="bd-radius-6 bg-light pd-30">
							<el-row :gutter="30">
								<el-col :span="12" v-for="(item,index) in myVipList">
									<div class="cl" :class="index === 0 ? 'bd-r-w-1 bd-dark-8 bd-style-solid' : ''">
										<img :src="item.mm_type === 'super' ? '/images/icon/icon-14.png' : '/images/icon/icon-13.png'" class="z" style="width: 44px;height: 44px;"/>
										<div class="inline-block mg-l-20">
											<div class="c-b-2 f-16">{{item.title}}</div>
											<div class="f-14 dark-5 mg-t-5">{{item.vip ? '到期时间：'+$moment.unix(item.vip.vip_end_time).format('YYYY-MM-DD') : '尚未开通VIP'}}</div>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="mg-t-10 bg-light bd-radius-6 pd-15">
							<slot></slot>
						</div>
					</div>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "UserMainPage",
	data() {
		return {
			nickname: '',
			mobile: '',
			avatar: '',
			myVipList:[]
		}
	},
	props: {
		type: {
			type: String,
			default: 'light'
		}
	},
	mounted() {
		this.getMobile();
		this.getNickname();
		this.getAvatar();
		this.getVipDetail();
	},
	methods: {
		getNickname() {
			this.nickname = this.$user.getNickName();
		},
		getMobile() {
			this.mobile = this.$user.getMobile();
		},
		getAvatar() {
			this.avatar = this.$user.getAvatar();
		},
		onChangeAvatar(e) {
			let _this = this;
			
			this.$http.post({
				url: '/UserProfile/editAvatar',
				data: {avatar: e},
				success(res) {
					_this.$user.setAvatar(e);
					_this.getAvatar();
				}
			})
		},
		getVipDetail(){
			let _this = this;
			this.$http.post({
				url:'/AuthVipPrice/listAll',
				data:null,
				success(res){
					_this.myVipList = res;
				}
			})
		},
	}
}
</script>
