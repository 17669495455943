<template>
	<div>
<!--		<div class="el-row" style="margin-left: -10px;margin-right: -10px;">-->
<!--			<div class="z el-col" style="width:14.285%;padding-left:10px;padding-right:10px;" v-for="(item,index) in dataList">-->
<!--				<div class="hand hy-data-item padding-20 text-center" :class="{on:dataIndex === index}" @click="dataIndex = index">-->
<!--					<lee-img-icon class="img" :color="dataIndex === index ? '#fff' : '#fff'" :url="'/images/icon/'+item.img" size="64"></lee-img-icon>-->
<!--					<div class="margin-top-30 text-center f18 dark-1 title" v-html="item.title"></div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
		<div class="bg-light cl box-shadow-bottom">
			<div class="inline-block text-center hy-data-item" v-for="(item,index) in dataList" style="width: 20%;" :class="{on:dataIndex === index}">
				<span @click="dataIndex = index">{{item.title}}</span>
			</div>
		</div>
		<div class="mg-t-30">
			<lee-index-hydata1 v-show="dataIndex === 0" :year="nowYear" type="zygy"></lee-index-hydata1>
			<lee-index-hydata2 v-show="dataIndex === 1" :year="nowYear" type="business"></lee-index-hydata2>
			<lee-index-hydata3 v-show="dataIndex === 2" :year="nowYear" type="client"></lee-index-hydata3>
			<lee-index-hydata4 v-show="dataIndex === 3" :year="nowYear" type="wlfy"></lee-index-hydata4>
			<lee-index-hydata5 v-show="dataIndex === 4" :year="nowYear" type="llscgm"></lee-index-hydata5>
			<lee-index-hydata6 v-show="dataIndex === 5" :year="nowYear" type="ylqxsc"></lee-index-hydata6>
			<lee-index-hydata7 v-show="dataIndex === 6" :year="nowYear" type="ylqxsc"></lee-index-hydata7>
		</div>
	</div>
</template>

<script>
export default {
	name: "IndexHyData",
	data() {
		return {
			nowYear: '',
			dataIndex: 0,
			dataList: [
				{
					img: 'icon_9.png',
					title: '医药工业',
					subTitle: '医药工业',
					url: '/hydata/medical/zygy',
					data: null
				},
				{
					img: 'icon_10.png',
					title: '医药商业',
					subTitle: '医药商业',
					url: '/hydata/medical/business',
					data: null
				},
				{
					img: 'icon_11.png',
					title: '医药终端',
					subTitle: '医药终端',
					url: '/hydata/medical/client',
					data: null
				},
				{
					img: 'icon_12.png',
					title: '医药物流',
					subTitle: '医药物流',
					url: '/hydata/medical/wlfy',
					data: null
				},
				{
					img: 'icon_14.png',
					title: '医药冷链',
					subTitle: '医药冷链',
					url: '/hydata/medical/llscgm',
					data: null
				},
			]
			// dataList: [
			// 	{
			// 		img: 'icon_9.png',
			// 		title: '医药</br>工业',
			// 		subTitle: '医药工业',
			// 		url: '/hydata/medical/zygy',
			// 		data: null
			// 	},
			// 	{
			// 		img: 'icon_10.png',
			// 		title: '医药</br>商业',
			// 		subTitle: '医药商业',
			// 		url: '/hydata/medical/business',
			// 		data: null
			// 	},
			// 	{
			// 		img: 'icon_11.png',
			// 		title: '医药</br>终端',
			// 		subTitle: '医药终端',
			// 		url: '/hydata/medical/client',
			// 		data: null
			// 	},
			// 	{
			// 		img: 'icon_12.png',
			// 		title: '医药</br>物流',
			// 		subTitle: '医药物流',
			// 		url: '/hydata/medical/wlfy',
			// 		data: null
			// 	},
			// 	{
			// 		img: 'icon_14.png',
			// 		title: '医药</br>冷链',
			// 		subTitle: '医药冷链',
			// 		url: '/hydata/medical/llscgm',
			// 		data: null
			// 	},
			// 	{
			// 		img: 'icon_15.png',
			// 		title: '器械</br>市场规模',
			// 		subTitle: '器械市场规模',
			// 		url: '/hydata/apparatus/ylqxsc',
			// 		data: null
			// 	},
			// 	{
			// 		img: 'icon_13.png',
			// 		title: '器械</br>物流',
			// 		subTitle: '器械物流',
			// 		url: '/hydata/apparatus/ylqxsc2',
			// 		data: null
			// 	},
			// 	// {
			// 	//     img:'icon_15.png',
			// 	//     title:'特色农品',
			// 	// }
			// ]
		}
	},
	created() {
		let _now = new Date();
		
		this.nowYear = _now.getFullYear() - 1;
	},
	mounted() {
	
	},
	methods: {
		toHydata() {
			let _url = this.dataList[this.dataIndex].url;
			
			if (_url) {
				this.$router.push({path: _url});
			}
		}
	}
}
</script>
