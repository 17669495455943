<template>
    <el-upload
        style="width: 500px;"
        class="upload-demo"
        :action="$config.uploadUrl"
        :data="{key:imgKey}"
        :headers="{Authorization:$user.getToken()}"
        :on-preview="onPreview"
        :on-remove="onRemove"
        :on-success="onSuccess"
        :before-remove="beforeRemove"
        accept=".doc,.docx,.ppt,.pdf,.pptx,.xls,.xlsx,.zip,.rar,.7z,.rz,.tar,.zipx,.jpg,.jpeg,.png,.gif"
        multiple
        :limit="limit"
        :file-list="fileList">
        <div class="text-left">
            <el-button size="small" type="primary">点击上传</el-button>
<!--            <div>格式：doc,docx,ppt,pdf,pptx,xls,xlsx,zip,rar,7z,rz,tar,zipx；最大不能超过20Mb；</div>-->
        </div>
    </el-upload>
</template>

<script>
export default {
    name: "FileUpload",
    data() {
        return {
            fileList:[],
            values:'',
            imgKey:'file',
            ids:[]
        };
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        files:{
            type:Array,
            default(){
                return []
            }
        },
        value:{
            type:[Array,String],
            default(){
                return []
            }
        },
        limit:{
            type:Number,
            default:10
        }
    },
    created() {
        // this.values = this.files;
        this.fileList = this.files;
        this.ids = this.value ? this.value : [];
    },
    watch:{
        files(n,o){
            // console.log(n);
            // this.values = n;
            this.fileList = n ? n : [];

            // if(!n){
            //     this.fileList = [];
            // }
        },
        value(n,o){
            console.log(n);
            this.ids = n ? n : [];
        }
    },
    methods: {
        onRemove(file, fileList) {
            // this.values = fileList;
            this.fileList = fileList;

            this.ids = [];

            if(fileList.length > 0){
                for(let i=0;i < fileList.length;i++){
                    if(fileList[i].id){
                        this.ids.push(fileList[i].id);
                    }else if(fileList[i].response && fileList[i].response.result.id){
                        this.ids.push(fileList[i].response.result.id);
                    }
                }
            }
            this.$emit('change',this.ids);
        },
        onPreview(file) {
					// console.log(file);
					let _url = '';
					
					if(file.url){
						_url = file.url;
					}else if(file.response.result.url){
						_url = file.response.result.url;
					}
	
	        // console.log(_url);
            // let _url = file.url.indexOf('http') >= 0 ? file.url : this.$config.assetsUrl + file.url;
	
	        if(_url) {
		        _url = _url.indexOf('http') >= 0 ? _url : this.$config.assetsUrl + _url;
	        }
	
	        // console.log(_url);
	        
					if(_url) {
						window.open(_url, '_blank');
					}
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },
        onSuccess(response, file, fileList) {

            if(response.code !== 0){
                this.$utils.error('上传失败');
                this.fileList = [];
            }else{

                this.ids.push(response.result.id);

                this.$emit('change',this.ids);
            }
        },
    }
}
</script>
