<template>
    <div class="cl" :class="$isMobile ? 'margin-top' : 'margin-top-20'" :style="{height:$isMobile ? '30px' : '',lineHeight:$isMobile ? '30px' : ''}">
        <div class="z" :class="$isMobile ? 'f14' : 'f16'">安全级别</div>
        <div class="z margin-left margin-right" :class="$isMobile ? 'margin-top' : ''" style="width:50%;">
            <el-progress :text-inside="!$isMobile" :show-text="!$isMobile" :stroke-width="$isMobile ? 10 : 24" :percentage="percent"></el-progress>
        </div>
        <div class="z blue f-thin" :class="$isMobile ? 'f14' : 'f16'">较高</div>
    </div>
</template>

<script>
export default {
    name: "SafeProgress",
    data(){
        return{
            percent:0
        }
    },
    props:{
        percentage:{
            type:Number,
            default:0
        }
    },
    created() {
        this.percent = this.percentage;
    },
    watch:{
        percentage(n,o){
            this.percent = n;
        }
    }
}
</script>
