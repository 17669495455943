<template>
    <lee-user-main-page>
        <template v-if="$isMobile">
            <div class="cl light" style="margin-top: -100px;">
                <img src="/images/banner_1.jpg" width="50" height="50" class="z border-radius border-w-3 border-light"/>
                <div class="z margin-left-20">
                    <div class="f18">{{$user.getNickName()}}</div>
                    <div class="f16 f-thin">{{$user.getMobile()}}</div>
                </div>
            </div>
            <div class="border-radius-5 hide bg-light margin-top-20 box-shadow">
                <van-cell center :title="item.title" is-link v-for="(item,index) in $config.userMenu" @click.native="$router.push({path:item.path})">
                    <img :src="item.icon2" slot="icon" height="30" class="margin-right-20"/>
                </van-cell>
            </div>
        </template>
        <template v-else>
            <div>user</div>
        </template>
    </lee-user-main-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{

        }
    },
    created() {
        if(!this.$isMobile){
            this.$router.replace('/user/profile');
        }
    },
    methods:{

    }
}
</script>
