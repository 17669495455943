<template>
	<lee-page bg-img="/images/bg_head_4.jpg" title="产业园数据" sub-title="INDUSTRIAL PARK DATA" :height="250" :bread="[{title:'行业数据',url:'/hydata'},{title:'产业园数据',url:'/hydata/park'},{title:'详情'}]">
		<div class="wrap1100 mg-t-50 mg-b-100">
			<div class="bg-gray-5 pd-30">
				<div class="green-line">
					<div class="cl f-28">
						<img src="/images/icon/icon-10.png" height="22" class="no-v-mid margin-right-20"/>{{data.title}}</div>
				</div>
				<div class="f16 dark-1 base-content" v-html="data.html_content ? data.html_content : ''" v-if="data.check.type === 'ok'"></div>
				<template v-if="data.check.type === 'ok'">
					<div class="margin-top-20" v-if="data.files.length > 0">
						<lee-vip-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom" akey="standard" :tid="data.id"></lee-vip-file-down>
					</div>
				</template>
				<vip-check-button :datas="data" :check="data.check" :price="data.price" :title="data.title" pay-title="购买产业园区详情" pay-sub-title="产业园区金额" type="park" @paydone="getDetail" v-else></vip-check-button>
				<lee-good-view class="margin-top-50" :id="data.id" akey="park" :open-like="data.open_like === 1" :open-favorite="data.open_favorite === 1"></lee-good-view>
				<lee-comment-view total-count="30" akey="park" :id="data.id"></lee-comment-view>
			</div>
		</div>
	</lee-page>
<!--    <lee-page bg-img="/images/bg_head_9.jpg" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '100' : '280'" :back="$isMobile" :title="$isMobile ? '' : title" :bread="[{title:'行业数据',url:'/hydata'},{title:'产业园区数据',url:'/hydata/park'},{title:title}]">-->
<!--        <div :class="!$isMobile ? 'bg-img-11 padding-bottom-100 padding-top-100' : 'padding-left-right'" :style="{backgroundColor: !$isMobile ? '#f8f9fa' : '',marginTop:$isMobile ? '-40px' : ''}" v-if="data">-->
<!--            <div :class="$isMobile ? '' : 'wrap1100'">-->
<!--                -->
<!--            </div>-->
<!--        </div>-->
<!--    </lee-page>-->
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            title:'',
            id:'',
            data:null
        }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        this.getDetail();
    },
    methods:{
        getDetail(){
            let _this = this;

            if(this.id) {
                this.$http.post({
                    url: '/Park/detail',
                    data: {id: this.id},
                    success(res) {
                        if(res){
                            _this.data = res;

                            _this.title = res.title;
                        }
                    }
                });
            }
        },

      getPayCode(){
        let _this = this;
        this.$http.post({
          url:'/Order/getPayCode',
          data:{id:this.id,order_type:'park'},
          success(res){
            _this.qrcode = res.qrcode;
            _this.order_id = res.order_id;
            _this.showPay = true;
            _this.timeDown('checkState');
          }
        })
      },
      timeDown(type){
        if(type === 'checkState') {
          this.time = setInterval(this.checkState, 3000)
        }
      },
      closePay(){
        this.showPay = false;
        clearInterval(this.time);
        this.time = null;
        this.qrcode = '';
        this.order_id = '';
      },
      checkState(){
        let _this = this;
        this.$http.post({
          url:'/Common/checkOrder',
          data:{id:this.order_id},
          success(res){
            if(res.state === 2){
              clearInterval(_this.time);
              _this.time = null;
              // _this.info.bid_state = 1;
              _this.showPay = false;
              _this.$message.success('支付成功');

              _this.getDetail();
            }
          }
        })
      },
    }
}
</script>
