<template>
    <div class="flex flex-row flex-ai">
        <el-button icon="el-icon-arrow-left
    " circle type="primary" class="margin-right-70" @click="prevSwiper"></el-button>
        <el-carousel :indicator-position="indicatorPosition" :interval="4000" :type="type" arrow="never" :height="height+'px'" :autoplay="true" class="flex1 lee-swiper hide" ref="swiper">
            <slot></slot>
        </el-carousel>
        <el-button  icon="el-icon-arrow-right
    " circle type="primary" class="margin-left-70" @click="nextSwiper"></el-button>
    </div>
</template>

<script>
export default {
    name: "Swiper",
    props:{
        height:{
            type:[String,Number],
            default:260
        },
        type:{
            type:String,
            default:'card'
        },
        indicatorPosition:{
            type:String,
            default:'none'
        }
    },
    methods:{
        prevSwiper(){
            this.$refs['swiper'].prev();
        },
        nextSwiper(){
            this.$refs['swiper'].next();
        }
    }
}
</script>
