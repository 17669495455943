<template>
  <div>
      <div class="bg-light box-shadow-base pd-10" v-if="data">
	      <div class="lh-40 f-14 bd-b-w-1 bd-dark-7 bd-style-solid">数据平台行业数据分析报告—医药工业</div>
        <div class="mg-t-10 f-14 dark-3">根据国家统计局和工信部公布数据的数据，{{nowYear}}年规模以上制药工业企业实现主营业务收入{{data.now && data.now.total_income ? data.now.total_income : 0}}亿元，按照可比因素计算同比{{data.now && data.now.income_rate >= 0 ? '增长' : '减少'}}{{data.now && data.now.income_rate ? Math.abs(data.now.income_rate / 100).toFixed(2) : 0}}%，增速较上年{{data.now && data.now.income_rate ? (data.now.income_rate - data.last.income_rate >= 0 ? '上升' : '下降') : '上升'}}{{ data.now && data.now.income_rate ? (Math.abs((data.now.income_rate - data.last.income_rate) / 100).toFixed(2)) : 0}}%个百分点。实现销售利润{{data.now && data.now.total_profit ? data.now.total_profit : 0}}亿元，按照可比口径计算同比{{data.now && data.now.profit_rate ? (data.now.profit_rate >= 0 ? '增长' : '减少') : '增长'}}{{data.now && data.now.profit_rate ? Math.abs(data.now.profit_rate / 100).toFixed(2) : 0}}%，增速较去年{{data.now && data.now.profit_rate ? (data.now.profit_rate - data.last.profit_rate >= 0 ? '上升' : '下降') : '上升'}}{{ data.now && data.now.profit_rate ? (Math.abs((data.now.profit_rate - data.last.profit_rate) / 100).toFixed(2)) : 0}}%个百分点。</div>
      </div>
      <div class="mg-t-30 bg-light box-shadow-base pd-30">
        <el-row :gutter="30">
          <el-col :span="12">
            <lee-bar-echarts
                ref="bar1"
                height="200px"
                width="512px"
                :show-legend="false"
                :show-right="false"
                :bar-width="20"
            ></lee-bar-echarts>
          </el-col>
          <el-col :span="12">
              <lee-bar-echarts
                  ref="bar2"
                  height="200px"
                  width="512px"
                  :show-legend="false"
                  :show-right="false"
                  :bar-width="20"
              ></lee-bar-echarts>
          </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
    name: "IndexHyData1",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // },
        type:{
            type:String,
            default:''
        }
    },
    created() {

    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/Hydata/getYiyaoYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear,endYear){
            let _this = this;

            if(this.type) {
                this.$http.post({
                    url: '/Hydata/getTypeData',
                    data: {year: startYear, type: this.type, yearlist: [startYear, endYear]},
                    success(res) {
                        _this.data = res;

                        let _bar1 = {bar:[],line:[],xaxis:[]};
                      let _bar2 = {bar:[],line:[],xaxis:[]};

                        let _list1 = res.list;

                        if(_list1.length > 0) {
                            for (let i = 0; i < _list1.length; i++) {
                                _bar1.bar.push(_list1[i].total_income);
                                _bar2.bar.push(_list1[i].total_profit);
                                _bar1.line.push(_list1[i].income_rate);
                                _bar2.line.push(_list1[i].profit_rate);
                                _bar1.xaxis.push(_list1[i].year.toString());
                              _bar2.xaxis.push(_list1[i].year.toString());
                            }
                            if (_this.$refs.bar1) {
                                _this.$refs.bar1.initData(
                                    [
                                        {name: '收入', type: 'bar', color: 'blue', data: _bar1.bar,dw:'亿元'},
                                        // {name: '收入增长率', type: 'line', color: 'purple', data: _bar1.line,dw:'%'},
                                    ],
                                    _bar1.xaxis
                                );
                            }
                          if (_this.$refs.bar2) {
                            _this.$refs.bar2.initData(
                                [
                                  {name: '利润', type: 'bar', color: 'green', data: _bar2.bar,dw:'亿元'},
                                  // {name: '利润增长率', type: 'line', color: 'red', data: _bar2.line,dw:'%'},
                                ],
                                _bar2.xaxis
                            );
                          }
                        }else{
                            _this.$refs.bar1.style.display = 'none';
                            _this.$refs.bar1.style.width = '100px';
                        }
                    }
                })
            }
        },
    }
}
</script>
