import Vue from 'vue';

let vm = new Vue();

import Config from './config'

// import { JSEncrypt } from 'jsencrypt'
// import { JSEncrypt } from 'encryptlong'

export default {
  formatUrl(url) {
    if (!url) {
      return ''
    }
    return url.indexOf('http') >= 0 ? url : Config.assetsUrl + url;
  },
  isEmptyObject(data) {
    let arr = Object.keys(data);
    return arr.length === 0;
  },
  formatDecimal(num, decimal) {
    num = num.toString()
    let index = num.indexOf('.')
    if (index !== -1) {
      num = num.substring(0, decimal + index + 1)
    } else {
      num = num.substring(0)
    }
    return parseFloat(num).toFixed(decimal)
  },
  getWxCodeUrl(url) {
    var authUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize';
    authUrl += '?appid=' + Config.WX_CONFIG.appId;
    authUrl += '&redirect_uri=' + encodeURIComponent(url);
    authUrl += '&response_type=code';
    authUrl += '&scope=snsapi_base';
    authUrl += '&state=state';
    return authUrl;
  },
  randomWords(n = 6) {
    //创建26个字母数组
    let arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    let idvalue = '';
    for (let i = 0; i < n; i++) {
      idvalue += arr[Math.floor(Math.random() * 26)];
    }
    return idvalue;
  },
  isWxImg(img) {
    if (img) {
      if (!/\.(gif|jpg|jpeg|png|GIF|JPEG|JPG|PNG)$/.test(img)) {
        return true;
      }
      
      return false;
    } else {
      return false;
    }
  },
  secretStr(str) {
    if (typeof str == 'number') {
      str = str.toString();
    }
    let _start = str.substr(0, 3);
    
    let _end = str.substr(-3, 3);
    
    return _start + '****' + _end;
  },
  mustLetter(rule, value, callback) {
    if (value === '') {
      return callback(new Error('不能为空'));
    }
    
    let _v = /[a-z0-9]$/;
    if (!_v.test(value)) {
      return callback(new Error('必须是小写字母或数字'));
    } else {
      return callback();
    }
  },
  mustNumber(rule, value, callback) {
    if (value === '') {
      return callback(new Error('不能为空'));
    }
    
    let _v = /(^[1-9]\d*$)/;
    if (!_v.test(value)) {
      return callback(new Error('必须是整数'));
    } else {
      return callback();
    }
  },
  must09(rule, value, callback) {
    if (value === '') {
      return callback(new Error('不能为空'));
    }
    
    // if (!Number.isInteger(value)) {
    //     return callback(new Error('请输入数字值'));
    // }
    
    if (value.length > 1) {
      let flagRex = /^0/;
      if (flagRex.test(value)) {
        return callback(new Error('请输入正确的数字'));
      }
    }
    
    let _v = /(^[0-9]\d*$)/;
    if (!_v.test(value)) {
      return callback(new Error('必须是整数'));
    } else {
      return callback();
    }
  },
  biggerZero(rule, value, callback) {
    if (value === '') {
      return callback(new Error('不能为空'));
    }
    
    if (!Number.isInteger(value) && typeof value !== 'number') {
      return callback(new Error('请输入数字值'));
    } else {
      if (value < 0) {
        return callback(new Error('必须>=0'));
      } else {
        return callback();
      }
    }
  },
  biggerZero2(rule, value, callback) {
    if (value === '') {
      return callback(new Error('不能为空'));
    }
    
    // console.log(typeof value);
    if (!Number.isInteger(value) && typeof value !== 'number') {
      return callback(new Error('请输入数字值'));
    } else {
      if (value <= 0) {
        return callback(new Error('必须大于0'));
      } else {
        return callback();
      }
    }
  },
  rulePercent(rule, value, callback) {
    if (value === '') {
      return callback(new Error('不能为空'));
    }
    
    let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
    
    if (!_v.test(value)) {
      return callback(new Error('请输入数字值'));
    } else {
      if (parseFloat(value) < 0 || parseFloat(value) > 100) {
        return callback(new Error('必须>=0 并且 <= 100'));
      } else {
        return callback();
      }
    }
  },
  rulePriceNotZero(rule, value, callback) {
    if (value === '') {
      return callback(new Error('不能为空'));
    }
    
    let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
    
    if (!_v.test(value)) {
      return callback(new Error('请输入数字值'));
    } else {
      if (parseFloat(value) <= 0) {
        return callback(new Error('必须大于0'));
      } else {
        return callback();
      }
    }
  },
  rulePriceCanZero(rule, value, callback) {
    if (value === '') {
      return callback(new Error('不能为空'));
    }
    
    let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
    
    if (!_v.test(value)) {
      return callback(new Error('请输入数字值'));
    } else {
      if (parseFloat(value) < 0) {
        return callback(new Error('必须大于0'));
      } else {
        return callback();
      }
    }
  },
  checkParams(rule, val) {
    for (let item in rule) {
      if (rule[item].check === 'require') {
        if (this.isUndefined(val[item].trim())) {
          console.error('param ' + item + ' must be require!');
          return false;
        }
      }
      
      if (typeof val[item] !== rule[item].type && !this.isUndefined(val[item])) {
        console.error('param ' + item + ' must be ' + rule[item].type + '!');
        return false;
      }
    }
    return true;
  },
  formatPrice(price) {
    return (Math.floor(Number(price) * 100) / 100).toFixed(2);
  },
  isUndefined(variable) {
    return (typeof variable == 'undefined' || variable === null || variable === '');
  },
  sessionSet(key, val) {
    if (typeof key === 'string') {
      val = val ? val : (val === null ? '' : val);
      return sessionStorage.setItem(key, val);
    } else {
      console.error('value & key must be a string!');
    }
    return false;
  },
  sessionGet(key) {
    if (typeof key === 'string') {
      return sessionStorage.getItem(key);
    } else {
      console.error('key must be a string!');
    }
    return false;
  },
  sessionDel(key) {
    if (typeof key === 'string') {
      return sessionStorage.removeItem(key);
    } else {
      console.error('key must be a string!');
    }
    return false;
  },
  sessionClear() {
    return sessionStorage.clear();
  },
  
  localSet(key, val) {
    if (typeof key === 'string') {
      val = val ? val : (val === null ? '' : val);
      return localStorage.setItem(key, val);
    } else {
      console.error('value & key must be a string!');
    }
    return false;
  },
  localGet(key) {
    if (typeof key === 'string') {
      return localStorage.getItem(key);
    } else {
      console.error('key must be a string!');
    }
    return false;
  },
  localDel(key) {
    if (typeof key === 'string') {
      return localStorage.removeItem(key);
    } else {
      console.error('key must be a string!');
    }
    return false;
  },
  localClear() {
    return localStorage.clear();
  },
  
  copy(data) {
    let oInput = document.createElement('input');
    oInput.value = data;
    oInput.classList.add('copy-input');
    document.body.appendChild(oInput);
    vm.$nextTick(() => {
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      vm.$message({
        message: '复制成功',
        type: 'success'
      });
      oInput.remove();
    });
  },
  isMobile() {
    let u = navigator.userAgent, app = navigator.appVersion;
    let out = {
      versions: {
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') === -1 //是否web应该程序，没有头部与底部
      }, language: (navigator.browserLanguage || navigator.language).toLowerCase()
    }
    return out;
  }
}
