import Vue from 'vue'
let vm = new Vue();
import VueRouter from 'vue-router'

import Index from '../views/site/Index.vue'

import ZcIndex from '../views/zhengce/Index'
import ZcList from '../views/zhengce/List'
import ZcDetail from '../views/zhengce/Detail'
import ZcSearch from '../views/zhengce/Search'

import standardIndex from '../views/standard/Index'
import standardList from '../views/standard/List'
import standardDetail from '../views/standard/Detail'
import standardSearch from '../views/standard/Search'

import YjIndex from '../views/yanjiu/Index'
import reportList from '../views/yanjiu/list'
import industryList from '../views/yanjiu/hangye/list'
import domainList from '../views/yanjiu/xifen/list'

import QaIndex from '../views/qa/Index'
import MyQa from "@/views/qa/MyQa";

import CommentIndex from '../views/comment/Index'
import AboutIndex from '../views/about/Index'

import prizeIndex from '../views/prize/Index'
import prizeDetail from '../views/prize/Detail'
import prizeList from '../views/prize/List'
import prizeSearch from '../views/prize/Search'

import HyIndex from '../views/hydata/Index'
import Park from "@/views/hydata/Park";
import Medical from "@/views/hydata/Medical";
import MedicalIndex from "@/views/hydata/MedicalIndex";

import UserIndex from '@/views/user/Index';
import Base from "@/views/user/profile/Base";
import SafeIndex from "@/views/user/Safe/SafeIndex";
import Major from "@/views/user/profile/Major";
import Ticket from "@/views/user/profile/Ticket";
import BuyLog from "@/views/user/buylog/BuyLog";
import ReadLog from "@/views/user/readlog/ReadLog";
import MyComment from "@/views/user/mycomment/MyComment";
import MyQaLog from "@/views/user/myqa/MyQaLog";
import MyCollection from "@/views/user/mycollection/MyCollection";
import MyGood from "@/views/user/mygood/MyGood";
import MyBalance from '@/views/user/balance/Index';
import Vip from "@/views/user/vip/Vip";

import Xifen from "@/views/yanjiu/xifen/Xifen";
import Detail from "@/views/yanjiu/xifen/Detail";
import FileBuy from "@/views/yanjiu/xifen/FileBuy";
import Apparatus from "@/views/hydata/Apparatus";

import newsList from '@/views/news/list';
import newsDetail from '@/views/news/detail';

import yjSearch from "@/views/yanjiu/Search";

import Login from "@/views/site/Login";
import QrLogin from "@/views/site/QrLogin";
import Register from "@/views/site/Register";
import FindPwd from "@/views/site/FindPwd";
import BookBuy from "@/views/yanjiu/hangye/BookBuy";
import ParkDetail from "@/views/hydata/ParkDetail";
import orderPay from '@/views/order/pay';
import orderPayDone from '@/views/order/payDone';
import qrloginMobile from '@/views/site/QrLoginMobile';
import MiniQrCode from "@/views/site/MiniQrCode";
import ChangePwd from "@/views/user/Safe/ChangePwd";
import MyAnswer from "@/views/qa/MyAnswer";

import SurveyIndex from '@/views/survey/Index';
import SurveyDetail from '@/views/survey/detail';
import SurveyDone from '@/views/survey/done';
import Fapiao from '../views/user/fapiao/Fapiao';
import FapiaoDetail from '@/views/user/fapiao/detail';
import FapiaoAdd from '@/views/user/fapiao/add';
import Zhuanlan from '../views/zhuanlan/index'
import ZhuanlanDetail from '../views/zhuanlan/detail'
import ZhuanlanVideo from '../views/zhuanlan/videolist'
import ZhuanlanVideoDetail from '../views/zhuanlan/videodetail'
import ZhuanlanArticle from '../views/zhuanlan/article'

const routerPush = VueRouter.prototype.push;
const routerReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function(location){
  return routerPush.call(this, location).catch(error=> error)
};

VueRouter.prototype.replace = function(location){
  return routerReplace.call(this, location).catch(error=> error)
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/miniqrcode',
    name: 'miniqrcode',
    component: MiniQrCode
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/qrlogin',
    name: 'QrLogin',
    component: QrLogin
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/findpwd',
    name: 'FindPwd',
    component: FindPwd
  },
  {
    path: '/standard',
    name: 'standardIndex',
    component: standardIndex
  },
  {
    path: '/standard/search',
    name: 'standardSearch',
    component: standardSearch
  },
  {
    path: '/standard/list/:mm_type/:id',
    name: 'standardList',
    component: standardList
  },
  {
    path: '/standard/detail/:id',
    name: 'standardDetail',
    component: standardDetail
  },
  {
    path: '/policy',
    name: 'ZcIndex',
    component: ZcIndex
  },
  {
    path: '/policy/search',
    name: 'ZcSearch',
    component: ZcSearch
  },
  {
    path: '/policy/list/:mm_type/:id',
    name: 'ZcList',
    component: ZcList
  },
  {
    path: '/policy/detail/:id',
    name: 'ZcDetail',
    component: ZcDetail
  },
  {
    path: '/yanjiu',
    name: 'YjIndex',
    component: YjIndex
  },
  {
    path: '/yanjiu/xifen',
    name: 'Xifen',
    component: Xifen
  },
  {
    path: '/yanjiu/list/industry',
    name: 'industryList',
    component: industryList
  },
  {
    path: '/yanjiu/list/domain',
    name: 'domainList',
    component: domainList
  },
  {
    path: '/domain/detail/:id',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/yanjiu/xifen/buy/:id',
    name: 'FileBuy',
    component: FileBuy
  },
  {
    path: '/industry/detail/:id',
    name: 'BookBuy',
    component: BookBuy
  },
  {
    path: '/yanjiu/search',
    name: 'yjSearch',
    component: yjSearch
  },
  {
    path: '/qa',
    name: 'QaIndex',
    component: QaIndex
  },
  {
    path: '/qa/me',
    name: 'MyQa',
    component: MyQa
  },
  {
    path: '/qa/myanswer',
    name: 'MyAnswer',
    component: MyAnswer
  },
  {
    path: '/comment',
    name: 'CommentIndex',
    component: CommentIndex
  },
  {
    path: '/about',
    name: 'AboutIndex',
    component: AboutIndex
  },
  // {
  //   path: '/prize',
  //   name: 'prizeIndex',
  //   component: prizeIndex
  // },
  {
    path: '/prize',
    name: 'prizeList',
    component: prizeList
  },
  {
    path: '/prize/search',
    name: 'prizeSearch',
    component: prizeSearch
  },
  {
    path: '/prize/detail/:id',
    name: 'prizeDetail',
    component: prizeDetail
  },
  {
    path: '/hydata',
    name: 'HyIndex',
    component: MedicalIndex
    // component: HyIndex
  },
  {
    path: '/hydata/park',
    name: 'Park',
    component: Park
  },
  {
    path: '/park/detail/:id',
    name: 'ParkDetail',
    component: ParkDetail
  },
  {
    path: '/hydata/medical/index',
    name: 'MedicalIndex',
    component: MedicalIndex
  },
  {
    path: '/hydata/medical/:type',
    name: 'Medical',
    component: Medical
  },
  {
    path: '/hydata/apparatus',
    name: 'Apparatus',
    component: Apparatus
  },
  {
    path: '/hydata/apparatus/:type',
    name: 'Apparatus',
    component: Apparatus
  },
  {
    path: '/zhuanlan',
    name: 'Zhuanlan',
    component: Zhuanlan
  },
  {
    path: '/zhuanlan/detail',
    name: 'ZhuanlanDetail',
    component: ZhuanlanDetail
  },
  {
    path: '/zhuanlan/article',
    name: 'ZhuanlanArticle',
    component: ZhuanlanArticle
  },
  {
    path: '/zhuanlan/video',
    name: 'ZhuanlanVideo',
    component: ZhuanlanVideo
  },
  {
    path: '/zhuanlan/video/detail',
    name: 'ZhuanlanVideoDetail',
    component: ZhuanlanVideoDetail
  },
  {
    path: '/user/index',
    name: 'UserIndex',
    component: UserIndex,
  },
  {
    path: '/user',
    name: 'UserIndex',
    component: UserIndex,
    // redirect:'/user/profile',
    children:[

    ]
  },
  {
    path: '/user/profile',
    name: 'Base',
    component: Base,
    meta:'个人资料'
  },
  {
    path: '/user/profile/major',
    name: 'Major',
    component: Major,
    meta:'个人资料'
  },
  {
    path: '/user/profile/ticket',
    name: 'Ticket',
    component: Ticket,
    meta:'个人资料'
  },
  {
    path: '/user/safe',
    name: 'SafeIndex',
    component: SafeIndex,
    meta:'账号安全'
  },
  {
    path: '/user/safe/changepwd',
    name: 'changePwd',
    component: ChangePwd,
    meta:'修改密码'
  },
  {
    path: '/user/buylog',
    name: 'BuyLog',
    component: BuyLog,
    meta:'消费记录'
  },
  {
    path: '/user/fapiao',
    name: 'Fapiao',
    component: Fapiao,
    meta:'发票管理'
  },
  {
    path: '/user/fapiao/detail',
    name: 'FapiaoDetail',
    component: FapiaoDetail,
    meta:'发票明细'
  },
  {
    path: '/user/fapiao/add',
    name: 'FapiaoAdd',
    component: FapiaoAdd,
    meta:'申请发票'
  },
  {
    path: '/user/readlog',
    name: 'ReadLog',
    component: ReadLog,
    meta:'阅读记录'
  },
  {
    path: '/user/mycomment',
    name: 'MyComment',
    component: MyComment,
    meta:'我的评论'
  },
  {
    path: '/user/myqa',
    name: 'MyQaLog',
    component: MyQaLog,
    meta:'我的提问'
  },
  {
    path: '/user/mycollection',
    name: 'MyCollection',
    component: MyCollection,
    meta:'我的收藏'
  },
  {
    path: '/user/mygood',
    name: 'MyGood',
    component: MyGood,
    meta:'我的点赞'
  },
  {
    path: '/user/balance',
    name: 'MyBalance',
    component: MyBalance,
    meta:'我的余额'
  },
  {
    path: '/user/vip',
    name: 'Vip',
    component: Vip,
    meta:'我的VIP'
  },
  {
    path: '/order/pay',
    name: 'orderPay',
    component: orderPay,
  },
  {
    path: '/order/paydone',
    name: 'orderPayDone',
    component: orderPayDone,
  },
  {
    path: '/mobile/login',
    name: 'qrloginMobile',
    component: qrloginMobile,
  },
  {
    path: '/news',
    name: 'newsList',
    component: newsList,
  },
  {
    path: '/news/detail/:id',
    name: 'newsDetail',
    component: newsDetail,
  },
  {
    path: '/survey',
    name: 'SurveyIndex',
    component: SurveyIndex,
  },
  {
    path: '/survey/detail/:id',
    name: 'SurveyDetail',
    component: SurveyDetail,
  },
  {
    path: '/survey/done',
    name: 'SurveyDone',
    component: SurveyDone,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(to.path.indexOf('/user') < 0) {
      return {x: 0, y: 0};
    }
  }
})

export default router
