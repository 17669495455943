<template>
    <div>
        <el-form :model="formData" status-icon :rules="rules" ref="form" hide-required-asterisk>
            <el-form-item class="blue margin-bottom" label="收件人姓名" prop="consignee" style="width: 100%;">
                <el-input v-model="formData.consignee" class="lee-input mobile" placeholder="请输入收件人姓名"></el-input>
            </el-form-item>
            <el-form-item class="blue margin-bottom" label="收件人电话" prop="phone" style="width: 100%;">
                <el-input v-model="formData.phone" class="lee-input mobile" placeholder="请输入收件人电话"></el-input>
            </el-form-item>
            <el-form-item class="blue" label="收件人地址" prop="area_code" style="width: 100%;">
                <el-cascader
                    class="lee-select mobile bg-gray-4 border-radius-5 vm"
                    style="width: 100%;"
                    :options="options"
                    v-model="cityData"
                    @change="handleChange">
                </el-cascader>
            </el-form-item>
            <el-form-item class="blue margin-top" label="" prop="address" style="width: 100%;">
                <el-input v-model="formData.address" class="lee-input mobile" placeholder="请输入详细地址"></el-input>
            </el-form-item>
          <el-form-item class="blue margin-bottom" label="企业单位名称" prop="company_name" style="width: 100%;">
            <el-input v-model="formData.company_name" class="lee-input mobile" placeholder="请输入企业单位名称"></el-input>
          </el-form-item>
          <el-form-item class="blue margin-bottom" label="职务" prop="job" style="width: 100%;">
            <el-input v-model="formData.job" class="lee-input mobile" placeholder="请输入职务"></el-input>
          </el-form-item>
        </el-form>
        <div class="cl padding-top-20" slot="footer">
            <el-button size="medium" type="primary" class="box-shadow-blue padding-left-50 padding-right-50" :class="$isMobile ? 'block' : ''"  @click="submit('form')">提交确认</el-button>
        </div>
    </div>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
export default {
    name: "AddOrder",
    data(){
        return{
            cityData:[],
            options: regionData,
            formData:{
                id:'',
                consignee:'',
                phone:'',
                province_code:'',
                province_name:'',
                city_code:'',
                city_name:'',
                area_code:'',
                area_name:'',
                address:'',
                order_type:'',
              company_name:'',
              job:''
            },
            rules:{
                consignee: [{required: true,message:'收件人必填'}],
                phone: [{required: true,message:'收件人电话必填'}],
                province_code: [{required: true,message:'地区必选'}],
                province_name: [{required: true,message:'地区必选'}],
                city_code: [{required: true,message:'地区必选'}],
                city_name: [{required: true,message:'地区必选'}],
                area_code: [{required: true,message:'地区必选'}],
                area_name: [{required: true,message:'地区必选'}],
                address: [{required: true,message:'详细地址必填'}],
            },
	        less_kaipiao_price:0
        }
    },
    props:{
        id:{
            type:[String,Number],
            default:''
        },
        order_type:{
            type:String,
            default:''
        }
    },
    created() {
        this.formData.id = this.id;
        this.formData.order_type = this.order_type;
    },
  mounted() {
      this.getDetail();
			this.getSetting();
  },
  watch:{
        id(n,o){
            this.formData.id = n;
            this.getDetail();
        },
        order_type(n,o){
            this.formData.order_type = n;
        }
    },
    methods:{
	    getSetting() {
		    let _this = this;
		
		    this.$http.post({
			    url: '/SystemSetting/getSystem',
			    data:{skey:'less_kaipiao_price'},
			    success(res) {
				    _this.less_kaipiao_price = parseFloat(res.less_kaipiao_price);
			    }
		    });
	    },
      getDetail(){
        let _this = this;
        this.$http.post({
          url:'/UserProfile/detail',
          data:null,
          success(res){
            console.log(res);
            _this.formData.company_name = res.company ? res.company : '';
            _this.formData.job = res.job ? res.job : '';
          }
        })
      },
        handleChange(e){
            this.formData.province_code = e[0];
            this.formData.city_code = e[1];
            this.formData.area_code = e[2];

            this.formData.province_name = CodeToText[e[0]];
            this.formData.city_name = CodeToText[e[1]];
            this.formData.area_name = CodeToText[e[2]];
        },

        onAddressSubmit(e){
            this.formData.province_code = e.province.code;
            this.formData.city_code = e.city.code;
            this.formData.district_code = e.area.code;

            this.formData.province_name = e.province.name;
            this.formData.city_name = e.city.name;
            this.formData.district_name = e.area.name;
        },

        submit(formName) {
            let _this = this;
            console.log(this.formData);
            this.$refs[formName].validate(valid => {
                if (valid) {
	                _this.$confirm('购买后，最低开票金额为'+_this.less_kaipiao_price+'元, 是否继续?', '提示', {
		                confirmButtonText: '确定',
		                cancelButtonText: '取消',
		                type: 'warning'
	                }).then(() => {
		                _this.$http.post({
			                url: '/Order/add',
			                data: _this.formData,
			                success(res) {
				                console.log(res);
				                _this.$emit('onPay',res);
				                _this.reset();
			                }
		                })
	                }).catch(() => {});
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        reset(){
            this.formData.consignee='';
            this.formData.phone='';
            this.formData.province_code='';
            this.formData.province_name='';
            this.formData.city_code='';
            this.formData.city_name='';
            this.formData.area_code='';
            this.formData.area_name='';
            this.formData.address='';
          this.formData.job='';
          this.formData.company_name='';
        }
    }
}
</script>
