<template>
    <lee-page bg-img="/images/bg_head_8.jpg" title="关于我们" height="250" title-class="margin-top-90" sub-title="ABOUT US" des="">
	    <div class="pd-t-b-50" style="background: #F5F7FB">
		    <div class="wrap1100">
			    <div class="f20">
				    <div>协会简介<span class="vm f-thin margin-left dark-2 f-14">INTRODUCTION TO THE ASSOCIATION</span></div>
				    <div class="base-line"></div>
			    </div>
			    <div class="cl mg-t-20">
				    <div class="z pd-l-r-30 pd-t-b-200" style="background: #09284E;width: 250px;height: 530px;">
					    <div class="bd-t-w-4 bd-light bd-style-solid" style="width: 70px;height: 1px;"></div>
					    <div class="f-26 light mg-t-50">ABOUT US</div>
					    <div class="f-26 light mg-t-10">关于我们</div>
				    </div>
				    <div class="z pd-l-20" style="width: 850px;">
					    <div class="f-16">协会简介</div>
					    <div class="f-14 dark-3" v-html="aboutus"></div>
					    <el-row :gutter="20">
						    <el-col :span="8">
							    <div class="bg-light about-tab-item pd-l-r-10 pd-t-15">
								    <div class="">
									    <i class="iconfont icon-zxxs c-b f-40"></i>
								    </div>
								    <div class="margin-top">
									    <i class="c-b f-30">{{policyCount}}</i>
									    <b class="f-n">+</b>
								    </div>
								    <div class="c-b margin-top">政策标准</div>
							    </div>
						    </el-col>
						    <el-col :span="8">
							    <div class="bg-light about-tab-item pd-l-r-10 pd-t-15">
								    <div class="">
									    <i class="iconfont icon-ccqd c-b f-40"></i>
								    </div>
								    <div class="margin-top">
									    <i class="c-b f-30">{{hydataCount}}</i>
									    <b class="f-n">+</b>
								    </div>
								    <div class="c-b margin-top">行业数据</div>
							    </div>
						    </el-col>
						    <el-col :span="8">
							    <div class="bg-light about-tab-item pd-l-r-10 pd-t-15">
								    <div class="">
									    <i class="iconfont icon-cccx c-b f-40"></i>
								    </div>
								    <div class="margin-top">
									    <i class="c-b f-30">{{reportCount}}</i>
									    <b class="f-n">+</b>
								    </div>
								    <div class="c-b margin-top">研究报告</div>
							    </div>
						    </el-col>
					    </el-row>
				    </div>
			    </div>
		    </div>
	    </div>
	    <div class="bg-light pd-t-b-50">
		    <div class="wrap1100">
			    <img src="/images/bg_5.jpg" style="width: 100%;"/>
		    </div>
	    </div>
	    <div class="bg-light">
		    <img src="/images/bg_6.jpg" style="width: 100%;"/>
	    </div>
	    <div class="bg-light pd-t-b-50">
		    <div class="wrap1100">
			    <div class="f20">
				    <div>会员体系<span class="vm f-thin margin-left dark-2 f-14">MEMBERSHIP SYSTEM</span></div>
				    <div class="base-line"></div>
			    </div>
			    <el-row :gutter="20" class="mg-t-30">
				    <el-col :span="12">
					    <div class="bd-w-1 bd-dark-7 bd-style-solid">
						    <img src="/images/bg_7.jpg" style="width: 100%;"/>
					    </div>
				    </el-col>
				    <el-col :span="12">
					    <div class="rel pd-20" style="height: 227px;background: #E7F0FA;">
						    <div class="rel" style="z-index: 2;">
							    <div class="c-b f-16">会员单位</div>
							    <div class="f-50 mg-t-100">{{member_company_count}}<b class="f-n f-20 vm mg-l-5">+</b></div>
						    </div>
						    <img src="/images/icon/icon-11.png" class="abs" style="height: 100px;right:30px;bottom:20px;z-index: 1;"/>
					    </div>
					    <div class="rel pd-20" style="height: 227px;background: #E7F0FA;margin-top:12px;">
						    <div class="rel" style="z-index: 2;">
							    <div class="c-b f-16">医药流通企业会员单位</div>
							    <div class="f-50 mg-t-100">{{yiyaoliutong_company_count}}<b class="f-n f-20 vm mg-l-5">+</b></div>
						    </div>
						    <img src="/images/icon/icon-12.png" class="abs" style="height: 100px;right:30px;bottom:20px;z-index: 1;"/>
					    </div>
				    </el-col>
			    </el-row>
		    </div>
	    </div>
	    <div class="pd-t-b-100" style="background-image: url('/images/bg_8.jpg');background-repeat: no-repeat;background-size: 100% 100%;">
		    <div class="wrap1100">
			    <div>
				    <div class="f20 light">合作客户<span class="vm f-thin margin-left light f-14">COOPERATIVE CUSTOMER</span></div>
				    <div class="base-line-2"></div>
			    </div>
			    <div class="mg-t-80">
				    <lee-swiper type="" :height="192" indicatorPosition="none" class="swiper-round">
					    <el-carousel-item v-for="(item,index) in kehuList" :key="index">
						    <el-row :gutter="20">
							    <el-col :span="4" v-for="(st,si) in item" class="mg-b-20">
								    <div class="bd-radius-6 hide bd-w-1 bd-dark-6 bd-style-solid">
									    <img :src="$config.assetsUrl + st.head_img" style="width: 100%;height: 84px;" class="bg-light"/>
								    </div>
							    </el-col>
						    </el-row>
					    </el-carousel-item>
				    </lee-swiper>
			    </div>
		    </div>
	    </div>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            timeLineList:[],
            aboutus:'',
            reportCount:0,
            policyCount:0,
          hydataCount:0,
            kehuList:[],
            teamList:[],
          mm_type:'medicine',
          tabList:[
            {label:'医药',value:'medicine',icon:'/images/icon/icon_57_'},
            {label:'医疗器械',value:'apparatus',icon:'/images/icon/icon_58_'},
          ],
	        member_company_count:0,
	        yiyaoliutong_company_count:0
        }
    },
	created() {
		this.mm_type = this.$route.query.mmtype ? this.$route.query.mmtype : 'medicine';
	},
	mounted() {
        this.getTime();
        this.getPolicyTotal();
        this.getReportTotal();
        this.getHyDataTotal();
        this.getAboutUs();
        this.getKehu();
        this.getTeam();
				this.getCount();
    },
    methods:{
      onTabChange(i){
        // this.mm_type = i.value;
        this.getKehu();
        this.getTeam();
        this.getTime();
        this.getAboutUs();
      },
	    getCount(){
		    let _this = this;
		
		    this.$http.post({
			    url: '/SystemSetting/getSystem',
			    data:{skey:['member_company_count','yiyaoliutong_company_count']},
			    success(res) {
				    _this.member_company_count = res.member_company_count ? res.member_company_count : 0;
				    _this.yiyaoliutong_company_count = res.yiyaoliutong_company_count ? res.yiyaoliutong_company_count : 0;
			    }
		    });
	    },
        getKehu(){
            let _this = this;
            this.$http.post({
                url:'/AboutKehu/listAll',
                data:{orderBy:'sort',sortBy:'asc',state:1,mm_type:this.mm_type},
                success(res){

                  for(let i = 0;i < res.length;i++){
                    let _i = Math.floor(i / 12);
                    if(!_this.kehuList[_i]){
                      _this.kehuList[_i] = [];
                    }
                    _this.kehuList[_i].push(res[i]);
                  }
                    // _this.kehuList = res;
                }
            })
        },
        getTeam(){
            let _this = this;
            this.$http.post({
                url:'/AboutTeam/listAll',
                data:{orderBy:'sort',sortBy:'asc',state:1,mm_type:this.mm_type},
                success(res){
                    _this.teamList = res;
                }
            })
        },
        getTime(){
            let _this = this;
            this.$http.post({
                url:'/AboutHistory/listAll',
                data:{orderBy:'year',sortBy:'desc',mm_type:this.mm_type},
                success(res){
                    _this.timeLineList = res;
                }
            })
        },
        getPolicyTotal(){
            let _this = this;
            this.$http.post({
                url:'/Policy/count',
                data:{mm_type:'medicine'},
                success(res){
                    _this.policyCount = res.count;
                }
            })
        },
      getHyDataTotal(){
        let _this = this;
        this.$http.post({
          url:'/Hydata/count',
          data:null,
          success(res){
            _this.hydataCount = res.count;
          }
        })
      },
        getReportTotal(){
            let _this = this;
            this.$http.post({
                url:'/Report/count',
	            data:{mm_type:this.mm_type},
                success(res){
                    _this.reportCount = res.count;
                }
            })
        },
        getAboutUs(){
            let _this = this;

          let _data = {skey:'about'};

          if(this.mm_type === 'medicine') {
            _data.skey = 'about';
          }else{
            _data.skey = 'about_qixie';
          }

            this.$http.post({
                url:'/SystemSetting/detail',
                data:_data,
                success(res){
                    _this.aboutus = res.svalue;
                }
            })
        }
    }
}
</script>

<style scoped>
.about-tab-item{border:5px solid #fff;height: 157px;}
.about-tab-item:hover{border:5px solid #09284E;}
</style>
