<template>
  <lee-page bg-img="/images/bg_head_3.jpg" :title="title" sub-title="Drug policy" :bread="[{title:'研究报告',url:'/yanjiu'},{title:title}]" :height="400" show-year search search-time-key="push_time">
    <div class="bg-img-11 padding-top-50 padding-bottom-100">
      <div class="cl"></div>
      <lee-tabs :list="tabList" :default-index="mm_type" @change="onTabChange" class="margin-auto" style="width: 500px;"></lee-tabs>
      <template v-if="list.length > 0">
	      <div class="text-center dark-3 padding-bottom-30 margin-top-30">共查询到 <b class="red">{{ totalCount }}</b> 条相关内容</div>
        <div class="margin-top-20 margin-auto" style="width:1500px;">
          <el-row :gutter="10">
            <el-col :md="12" :xs="24" v-for="(item,index) in list" class="margin-bottom">
              <div class="bg-light border-radius-5 hand border-w-1 border-dark-6 padding-20"
                   @click="$router.push({path:(type === 'domain' ? '/domain/detail/' : '/industry/detail/')+item.id})">
                <div class="cl"></div>
                <div class="border-bottom padding-bottom" style="height:60px;line-height: 20px;">
                  <img src="/images/icon/icon-36.png" width="20" height="20" class="vm"/>
                  <div class="f18 margin-left-30" style="height:50px;line-height: 20px;margin-top:-20px;"
                       v-html="item.title">{{ item.title }}
                  </div>
                </div>
                <el-row :gutter="10" class="margin-top">
                  <el-col :md="24" :xs="12" class="margin-bottom" v-if="item.author">
                    <img src="/images/icon/icon_37.png" width="20" height="20" class="margin-right"/>
                    <span class="vm dark-3 f16">{{ item.author }}</span>
                  </el-col>
                  <el-col :md="24" :xs="12" class="margin-bottom">
                    <img src="/images/icon/icon_38.png" width="20" height="20" class="margin-right"/>
                    <span class="vm dark-3 f16">{{ item.create_time }}</span>
                  </el-col>
                  <el-col :md="24" :xs="12" class="margin-bottom">
                    <img src="/images/icon/icon_39.png" width="20" height="20" class="vm"/>
                    <div class="margin-left-30 text-line-2 vm f16 dark-3"
                         style="height: 40px;line-height: 20px;margin-top: -20px" v-html="item.content"></div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="text-center margin-top-100">
          <el-pagination
            background
            :hide-on-single-page="true"
            layout="pager, jumper"
            :page-size="pageSize"
            :current-page="searchForm.page"
            :total="totalCount"
            @current-change="pageChange">
          </el-pagination>
        </div>
      </template>
      <div class="text-center padding-top-bottom-40 f20 dark-3" v-else>
	      未查询到相关内容
      </div>
    </div>
  </lee-page>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      list: [],
      pageSize: 10,
      totalCount: 0,
      type: '',
      searchForm: {
        page: 1,
        title: ''
      },
      title: '',
      mm_type: 'medicine',
      tabList: [
        {label: '医药', value: 'medicine', icon: '/images/icon/icon_57_'},
        {label: '医疗器械', value: 'apparatus', icon: '/images/icon/icon_58_'},
      ],
    }
  },
  created() {
    this.type = this.$route.params.type;

    // if (!this.$utils.isEmptyObject(this.$route.query)) {
    //   this.searchForm = {...this.$route.query};
    // }

    this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
    this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.mm_type = this.$route.query.mmtype ? this.$route.query.mmtype : 'medicine';

    if (this.type === 'industry') {
      this.title = '行业发展报告';
    } else {
      this.title = '研究报告及资料';
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    onTabChange(i) {
			console.log(i);
      // this.mm_type = i.value;
      this.searchForm.page = 1;
      this.getList();
    },
    getList() {
      let _this = this;
      this.$http.post({
        url: '/Report/listPage',
        data: {...this.searchForm, pageSize: this.pageSize, mm_type: this.mm_type, type: this.type},
        success(res) {
          _this.list = res.list;
          _this.totalCount = res.totalCount;
        }
      })
    },
    pageChange(page) {
      this.searchForm.page = page;
      this.$router.replace({query:{...this.searchForm,mmtype:this.mm_type}});
    },
  }
}
</script>
