<template>
    <lee-page bg-img="/images/bg_head_5.jpg" title="留言板" sub-title="The Top Hundred Corporations" des="">
        <template v-if="$isMobile">
            <div class="padding-left-right margin-top">
                <div class="bg-gray-4 border-radius-5 margin-bottom qa-list mobile" v-for="(item,index) in 10">
                    <div :class="index === activeIndex ? 'on' : ''" class="box-shadow title cl bg-light padding" style="height: 60px;line-height: 40px;" @click="activeIndex = index">
                        <img src="/images/icon/icon_q.png" height="30" class="z margin-top-5"/>
                        <div class="z margin-left">
                            <div class="f14 dark-1" style="line-height: 20px;">fdsfds</div>
                            <div class="f12 dark-3" style="line-height: 20px;">2020-12-12</div>
                        </div>
                        <div class="y">
                            <van-tag color="#FEBE00" round class="padding-left-right">版块:百强企业</van-tag>
                        </div>
                    </div>
                    <div class="padding-20" :class="index === activeIndex ? '' : 'none'">
                        fdsfds
                    </div>
                </div>
            </div>
            <lee-footer-fix slot="footer">
                <div class="bg-green-line rel inline-block padding light border-radius vm" style="height: 50px;width: 200px;line-height: 30px;" @click="showAddComment = true">
                    <lee-img-icon url="/images/icon/icon_24.png" size="30" class="vm abs" style="left:10px;top:10px;"/>
                    我要留言
                </div>
            </lee-footer-fix>
            <van-popup v-model="showAddComment" closeable position="bottom">
                <div class="padding-20">
                    <div class="dark-2">写留言</div>
                    <div class="green margin-top-30">留言板块</div>
                    <lee-select v-model="formData.type" style="width: 100%;" :options="typeList" class="mobile margin-top"></lee-select>
                    <div class="green margin-top-20">留言内容</div>
                    <div class="bg-gray-4 cl">
                        <el-input
                            class="lee-textarea"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入内容"
                            v-model="formData.content">
                        </el-input>
                    </div>
                    <el-button size="medium" type="success" class="block margin-top-20">提交确认</el-button>
                </div>
            </van-popup>
        </template>
        <template v-else>
            <div style="margin-top:-45px;height: 90px;" class="rel wrap1100 margin-auto cl">
                <div class="bg-green hand box-shadow padding border-radius margin-auto" style="width:250px;height: 90px;line-height: 70px;" @click="showAddComment = true">
                    <img src="/images/icon/icon_20.png" width="65"/>
                    <span class="light f24 margin-left-20 f-lq" style="vertical-align: middle;margin-right: 40px;">我要留言</span>
                </div>
            </div>
            <div class="bg-img-2 padding-bottom-100" style="margin-top:-45px;padding-top:45px; background-color: #f8f9fa;">
                <div class="wrap1100 margin-top-30">
                    <div class="bg-light padding-20 border-radius-5 margin-bottom-20" v-for="item in 7">
                        <div class="cl" style="height: 40px;line-height: 40px;">
                            <div class="z margin-right-70 f22">用户：fhdhfhdf</div>
                            <el-tag class="z f20 border-none" size="medium" effect="dark" style="height: 40px;line-height: 40px;" color="#FEBE00" :hit="false">版块：百强企业</el-tag>
                            <div class="y f22 dark-3 f-thin">2020-12-12</div>
                        </div>
                        <div class="border-top padding-top margin-top f18">
                            我是留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容留言内容
                        </div>
                    </div>
                </div>
            </div>
            <el-dialog
                title="留言"
                :visible.sync="showAddComment">
                <div class="green">留言板块</div>
                <lee-select v-model="formData.type" style="width: 100%;" :options="typeList" class="mobile margin-top"></lee-select>
                <div class="green margin-top-20">留言内容</div>
                <div class="bg-gray-4 cl">
                    <el-input
                        class="lee-textarea"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                        v-model="formData.content">
                    </el-input>
                </div>
                <div class="cl" slot="footer">
                    <el-button size="medium" type="success" class="box-shadow-green">提交留言</el-button>
                </div>
            </el-dialog>
        </template>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            activeIndex:0,
            showAddComment:false,
            formData:{
                type:'1',
                content:''
            },
            typeList:[
                {
                    value: '1',
                    label: '黄金糕'
                },
                {
                    value: '2',
                    label: '黄金糕2'
                },
                {
                    value: '3',
                    label: '黄金糕3'
                }
            ],
        }
    },
    methods:{

    }
}
</script>
