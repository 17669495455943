<template>
	<div>
		<div class="f16 dark-1 base-content" v-html="data.html_content ? data.html_content : ''" v-if="data.check.type === 'ok'"></div>
		<template v-if="data.check.type === 'ok'">
			<div class="margin-top-20" v-if="data.files.length > 0">
				<lee-vip-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom" :akey="akey" :tid="data.id"></lee-vip-file-down>
				<!--              <lee-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom"></lee-file-down>-->
			</div>
		</template>
		<vip-check-button :datas="data" :check="data.check" :price="data.price" :title="data.title" :pay-title="payTitle" :pay-sub-title="paySubTitle" :type="type" @paydone="paydone" v-else></vip-check-button>
	</div>
</template>

<script>
export default {
	name: "VipCheckBox",
	data() {
		return {}
	},
	props:{
		data:{
			type:[Boolean,Object],
			default:null
		},
		payTitle:{
			type:String,
			default:''
		},
		paySubTitle:{
			type:String,
			default:''
		},
		type:{
			type:String,
			default:''
		},
		akey:{
			type:String,
			default:''
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		paydone(){
			this.$emit('paydone')
		}
	}
}
</script>
