<template>
	<lee-page bg-img="/images/bg_head_1.jpg" title="活动专栏" sub-title="CONTENT COLUMN" height="250" title-class="margin-top-90">
		<div class="wrap1100 mg-t-50 mg-b-100" v-if="info">
			<div class="bg-gray-5 pd-20">
				<div class="pd-b-10 f-24 bd-b-w-1 bd-dark-8 bd-style-solid">
					<img src="/images/icon/icon-36.png" height="22" class="no-v-mid"/>
					{{info.title}}
				</div>
				<lee-swiper class="margin-top-50 padding-top-bottom" height="275" v-if="info.files && info.files.length > 0">
					<el-carousel-item v-for="(item,index) in info.files" :key="item.id" class="bg-light padding-20 margin-top-bottom" style="height: 255px;">
						<div class="hide">
							<img :src="$utils.formatUrl(item.url)" style="width: 100%;height: 215px;"/>
						</div>
					</el-carousel-item>
				</lee-swiper>
				<div class="bd-l-w-4 bd-style-solid pd-l-10" style="border-color: #09284E;">
					会议介绍
				</div>
				<div class="mg-t-20 f-16">{{info.title}}</div>
				<div class="f-14 dark-4 mg-t-10" v-html="info.content" style="white-space: pre-line;"></div>
				<div class="mg-t-20">
					<el-button type="primary" icon="iconfont icon-business-icon-feeds-fill mg-r-5" @click="$router.push({path:'/zhuanlan/video',query:{id:info.id}})">课件视频</el-button>
					<el-button type="primary" icon="iconfont icon-image-text-fill mg-r-5" @click="$router.push({path:'/zhuanlan/article',query:{id:info.id}})">会后总结</el-button>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "detail",
	data() {
		return {
			id:'',
			info:null
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		if(this.id){
			this.getDetail();
		}else{
			this.$alert('内容不存在或被删除', '提示', {
				confirmButtonText: '确定',
				closeOnClickModal:false,
				closeOnPressEscape:false,
				showClose:false,
				callback: action => {
					this.$router.back();
				}
			});
		}
	},
	methods: {
		getDetail() {
			let _this = this;
			
			this.$http.post({
				url: '/Zhuanti/detail',
				data: {id:this.id},
				success(res) {
					// res.content.replaceAll(/\\n/g,'<br/>')
					// console.log(res.content);
					_this.info = res;
				}
			});
		},
	}
}
</script>
