<template>
    <lee-user-main-page type="dark">
        <lee-user-page class="padding-bottom-100">
            <div style="width: 530px;" class="padding-bottom-100">
                <el-form :model="formData" status-icon :rules="rules" ref="form" :show-message="true" :inline-message="true" :inline="false">
                    <el-form-item label="旧密码" prop="old_password">
                        <el-input v-model="formData.old_password" type="password" class="lee-input mobile" placeholder="输入旧密码" style="width: 100%;"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="new_password">
                        <el-input v-model="formData.new_password" type="password" class="lee-input mobile" placeholder="输入新密码" style="width: 100%;"></el-input>
                    </el-form-item>
                    <el-form-item label="重复新密码" prop="re_new_pass">
                        <el-input v-model="formData.re_new_pass" type="password" class="lee-input mobile" placeholder="重复新密码" style="width: 100%;"></el-input>
                    </el-form-item>
                    <el-button type="primary" class="padding-left-50 padding-right-50 f18 f-n box-shadow-blue margin-top-50" @click="submit('form')">保存</el-button>
                </el-form>
            </div>
        </lee-user-page>
    </lee-user-main-page>
</template>

<script>
export default {
    name: "ChangePwd",
    data(){
        let checkRePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formData.new_password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return{
            formData:{
                old_password:'',
                new_password:'',
                re_new_pass:''
            },
            rules:{
                old_password:[
                    {required: true, message:'请输入旧密码'},
                    {min:6,max:20,message: '密码必须是6-20位字符'},
                    {pattern:this.$regexp.password,message: '只能包含数字、字母及特殊字符(!#$%^&*.~,)'}
                ],
                new_password:[
                    {required: true, message:'请输入新密码'},
                    {min:6,max:20,message: '必须是6-20位字符'},
                    {pattern:this.$regexp.password,message: '只能包含数字、字母及特殊字符(!#$%^&*.~,)'}
                ],
                re_new_pass:[
                    {required: true, message:'请输入旧密码'},
                    {min:6,max:20,message: '必须是6-20位字符'},
                    {pattern:this.$regexp.password,message: '只能包含数字、字母及特殊字符(!#$%^&*.~,)'},
                    {validator:checkRePass,message:'两次输入密码不一致!'}
                ],
            }
        }
    },
    mounted() {
        // this.getStrong();
    },
    methods:{
        submit(formName){
            let _this = this;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.post({
                        url: '/UserProfile/changePass',
                        data: this.formData,
                        success(res) {
                            if(res === 'ok'){
                                _this.$message.success('密码修改成功，请重新登录');

                                _this.$user.logout();
                                _this.$router.push({path:'/login'});
                            }
                        }
                    })
                }
            })
        }
    }
}
</script>
<style scoped>
.el-form-item{margin-bottom:0 !important;}
</style>
