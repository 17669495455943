<template>
	<div>
		<slot></slot>
		<el-dialog title="购买会员" :visible.sync="show" @close="onCloseDialog" append-to-body v-if="list">
			<el-row>
				<el-col :span="12" v-for="(item,index) in list">
					<div class="vip-choose-tab-item f-16 hand" :class="active === item.id ? 'on' : ''" @click="changeActive(item)"><i class="iconfont icon-huangguan mg-r-5"></i>{{item.title}}</div>
				</el-col>
			</el-row>
			<div class="bg-gray-5 pd-20">
				<el-row :gutter="20">
					<el-col :span="8" v-for="(item,index) in price_list">
						<div class="vip-choose-box-item" :class="price_choose.id === item.id ? 'on' : ''" @click="choosePrice(item)">
							<div class="title">{{item.title}}</div>
							<div class="body pd-20">
								<div class="f-40 red-3 text-center"><b class="f-20 f-n">￥</b>{{item.price}}</div>
								<template v-if="item.price_diff > 0 && item.disabled === false">
									<div class="text-center f-14 dark-4" v-for="(st,si) in item.price_diff_list">{{st.vip.title}}本次抵扣{{st.diff_price}}元</div>
								</template>
								<div class="text-line-through f-n f-18 text-center" :class="price_choose.id === item.id ? 'dark-5' : 'dark-8'" style="height: 25px;">{{parseFloat(item.old_price) > 0 ? '￥'+item.old_price : ''}}</div>
							</div>
						</div>
					</el-col>
				</el-row>
				<div @click="getPayCode" class="mg-t-30 bg-blue-line-4 hand light hand text-center pd-t-b-15 f-18 bd-radius-10"><i class="iconfont icon-huangguan mg-r-5 f-22"></i>{{price_choose && price_choose.price_diff > 0 ? '升级VIP' : '立即购买/续期VIP'}}</div>
			</div>
		</el-dialog>
		<el-dialog title="支付" :visible.sync="showPay" @close="onClosePay" append-to-body>
			<lee-pay-view :name="buyInfo.title" :price="price_choose.price" title="购买VIP" sub-title="VIP金额" file-size="" :qrcode="'data:image/jpeg;base64,'+qrcode" v-if="buyInfo && price_choose"></lee-pay-view>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "VipBuyDialog",
	data() {
		return {
			show:false,
			showPay:false,
			
			time:null,
			qrcode:'',
			order_id:'',
			buyInfo:null,
			price_choose:null,
			price_list:[],
			myVipList:[],
			has_vip:false,
			vip_buy_list:[],
			vip_state:0,
			vip_end_time:'',
			active:'',
			list:[],
			less_kaipiao_price:0
		}
	},
	created() {
	},
	mounted() {
		this.$nextTick(()=>{
			this.$slots.default[0].elm.addEventListener('click', this.toShowDialog)
			this.getSetting();
		})
	},
	methods: {
		onCloseDialog(){
		
		},
		getSetting() {
			let _this = this;
			
			this.$http.post({
				url: '/SystemSetting/getSystem',
				data:{skey:'less_kaipiao_price'},
				success(res) {
					_this.less_kaipiao_price = parseFloat(res.less_kaipiao_price);
				}
			});
		},
		onClosePay(){
			this.showPay = false;
			clearInterval(this.time);
			this.time = null;
			this.qrcode = '';
			this.order_id = '';
		},
		toShowDialog(){
			console.log('=d=f=s=f')
			this.show = true;
			this.getDetail();
		},
		getDetail(){
			let _this = this;
			this.$http.post({
				url:'/AuthVipPrice/listAll',
				data:null,
				success(res){
					_this.myVipList = res;
					
					_this.getList();
				}
			})
		},
		getList(){
			let _this = this;
			this.$http.post({
				url:'/VipPrice/listAll',
				data:{orderBy:'sort',sortBy:'asc',state:1,pid:'0'},
				success(res){
					let _list = [];
					let _has_list = [];
					
					if(res.length > 0) {
						for (let i=0;i < res.length;i++){
							for (let j=0;j < _this.myVipList.length;j++){
								if(res[i].id === _this.myVipList[j].id && (_this.myVipList[j].vip || _this.myVipList[j].is_super === 1)){
									_list.push(res[i]);
								}
							}
						}
						
						for (let j=0;j < _this.myVipList.length;j++){
							if(_this.myVipList[j].vip){
								_has_list.push(_this.myVipList[j]);
							}
						}
						
						if(_list.length === 1 && _list[0].is_super === 1){
							if(_list.length !== _has_list.length) {
								_list = res;
							}
						}
						
						_this.list = _list;
						
						_this.active = _list[0].id;
						_this.buyInfo = _list[0];
						
						_this.getPrice();
					}
				}
			})
		},
		getPrice(){
			let _this = this;
			this.$http.post({
				url:'/VipPrice/listAll',
				data:{orderBy:'sort',sortBy:'asc',state:1,pid:this.active},
				success(res){
					_this.price_list = res;
					
					if(res.length > 0) {
						for(let i=0;i < res.length;i++){
							if(res[i].disabled === false){
								_this.price_choose = res[i];
								break;
							}
						}
						// _this.buyInfo = res[0];
					}
				}
			})
		},
		changeActive(item){
			this.active = item.id;
			this.buyInfo = item;
			
			this.getPrice();
		},
		choosePrice(item){
			if(item.disabled === false){
				this.price_choose = item;
			}else{
				this.$message.error('当前价格无法选择，请选择更高的时长以升级VIP')
			}
		},
		getPayCode(){
			let _this = this;
			_this.$confirm('购买后，最低开票金额为'+_this.less_kaipiao_price+'元, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.post({
					url:'/Order/getPayCode',
					data:{id:_this.price_choose.id,order_type:'vip'},
					success(res){
						_this.qrcode = res.qrcode;
						_this.order_id = res.order_id;
						_this.showPay = true;
						_this.timeDown('checkState');
					}
				})
			}).catch(() => {});
		},
		timeDown(type){
			if(type === 'checkState') {
				this.time = setInterval(this.checkState, 3000)
			}
		},
		checkState(){
			let _this = this;
			this.$http.post({
				url:'/Common/checkOrder',
				data:{id:this.order_id},
				success(res){
					if(res.state === 2){
						clearInterval(_this.time);
						_this.time = null;
						_this.showPay = false;
						_this.$message.success('支付成功');
						
						_this.getDetail();
					}
				}
			})
		},
	},
	destroyed() {
		this.$slots.default[0].elm.removeEventListener('click',this.toShowDialog)
		
		if(this.time) {
			clearInterval(this.time);
			this.time = null;
		}
	}
}
</script>
