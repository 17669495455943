<template>
  <lee-user-main-page type="dark">
    <lee-user-page class="padding-bottom-100">
	    <el-button type="primary" size="mini" @click="$router.push({path:'/user/fapiao'})" slot="headRight">管理发票</el-button>
	    <el-tabs v-model="activeName" @tab-click="handleClick">
		    <el-tab-pane label="支付记录" name="pay">
			    <template v-if="list.length > 0">
				    <lee-base-cell img="/images/icon/icon_32.png" title="" right-title="" v-for="(item,index) in list" title-class="green f14 f-n">
					    <div slot="subTitle">
						    <div class="dark-1 text-line-1" :class="$isMobile ? 'f14' : 'f16'" style="line-height: 20px;">
							    {{ item.order_body }}
						    </div>
						    <div class="dark-3 margin-top-5 f-thin" :class="$isMobile ? 'f12' : 'f14'" style="line-height: 20px;">
							    购买时间：{{ $moment.unix(item.pay_time).format('YYYY-MM-DD HH:mm:ss') }}
						    </div>
					    </div>
              <div class="text-right f16 red" slot="rightButton">
	              ￥{{item.pay_amount}}
<!--                <span class="danger hand" v-if="item.invoice_state === -1" @click="toOpenTicket(item)">申请发票</span>-->
<!--                <span class="dark-3" v-if="item.invoice_state === 0">发票申请中</span>-->
<!--                <span class="blue" v-if="item.invoice_state === 1">发票已申请</span>-->
<!--                <span class="warning hand" v-if="item.invoice_state === 2" @click="toOpenTicket(item)">重新申请发票</span>-->
              </div>
				    </lee-base-cell>
			    </template>
			    <div class="text-center padding-top-bottom-40 f18 dark-2" v-else>
				    暂无记录
			    </div>
		    </el-tab-pane>
		    <el-tab-pane label="查看记录" name="view">
			    <template v-if="list && list.length > 0">
				    <div class="cl margin-top-20 border-radius-5 bg-gray-5 padding-20" v-for="(item,index) in list">
					    <div class="z" style="width: 700px;">
						    <div class="dark-1 f16" style="line-height: 24px">{{item.slug ? item.slug.title : ''}}</div>
						    <div class="dark-3 margin-top-5 f-thin f14" style="line-height: 20px">最后浏览时间：{{$moment.unix(item.update_time).format('YYYY-MM-DD HH:mm:ss')}}</div>
					    </div>
					    <div class="y text-right" style="width: 200px;">
						    <div class="red f18" v-if="item.pre_count - item.last_count > 0">-{{item.pre_count - item.last_count}}</div>
						    <div class="dark-3 f14" style="margin-top:5px;">{{item.viptitle}}</div>
						    <div class="dark-3 f14" style="margin-top:5px;" v-if="item.last_count !== 999999">剩余次数：{{item.last_count}}</div>
					    </div>
				    </div>
			    </template>
			    <div class="text-center padding-top-bottom-40 f18 dark-2" v-else>
				    暂无记录
			    </div>
		    </el-tab-pane>
		    <el-tab-pane label="下载记录" name="down">
			    <template v-if="list && list.length > 0">
				    <div class="cl margin-top-20 border-radius-5 bg-gray-5 padding-20" v-for="(item,index) in list">
					    <div class="z" style="width: 700px;">
						    <div class="dark-1 text-line-1 f16" style="line-height: 24px">{{item.slug ? item.slug.title : ''}}</div>
						    <div class="dark-3 margin-top-5 f-thin f14" style="line-height: 20px">下载时间：{{item.create_time}}</div>
					    </div>
					    <div class="y text-right" style="width: 200px;">
						    <div class="red f18" v-if="item.pre_count - item.last_count > 0">-{{item.pre_count - item.last_count}}</div>
						    <div class="dark-3 f14" style="margin-top:5px;">{{item.viptitle}}</div>
						    <div class="dark-3 f14" style="margin-top:5px;" v-if="item.last_count !== 999999">剩余次数：{{item.last_count}}</div>
					    </div>
				    </div>
			    </template>
			    <div class="text-center padding-top-bottom-40 f18 dark-2" v-else>
				    暂无记录
			    </div>
		    </el-tab-pane>
	    </el-tabs>
	    <div class="text-center margin-top-100">
		    <el-pagination
			    background
			    layout="pager"
			    :page-size="pageSize"
			    :current-page.sync="page"
			    :total="total"
			    @current-change="pageChange">
		    </el-pagination>
	    </div>
    </lee-user-page>
    <el-dialog :visible.sync="showTicket" append-to-body @closed="onDialogClose">
      <el-form :model="formData" label-width="150px" ref="form" :rules="rules">
        <el-form-item prop="open_type" label="开票类型">
          <el-radio-group v-model="formData.open_type">
            <el-radio label="person">个人</el-radio>
            <el-radio label="company">企业</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="type" label="发票类型">
          <el-radio-group v-model="formData.type">
            <el-radio label="email">电子发票</el-radio>
            <el-radio label="paper">纸质发票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="title" label="发票名称">
          <el-input v-model="formData.title" autocomplete="off" placeholder="请填写发票名称"></el-input>
        </el-form-item>
        <el-form-item prop="number" label="纳税人识别号" :rules="[{required:true,message:'请填写纳税人识别号'},{pattern:$regexp.TINumber,message:'请填写正确的发票税号'}]" v-if="formData.open_type === 'company'">
          <el-input v-model="formData.number" autocomplete="off" placeholder="请填写发票税号"></el-input>
        </el-form-item>
        <el-form-item prop="program" label="发票事项">
          <el-input v-model="formData.program" autocomplete="off" placeholder="请填写发票事项"></el-input>
        </el-form-item>
        <template v-if="formData.type === 'email'">
          <el-form-item prop="email" label="电子邮箱" :rules="[{required:true,message:'请填写电子邮箱'},{pattern:$regexp.email,message:'请填写正确的邮箱地址'}]">
            <el-input v-model="formData.email" autocomplete="off" placeholder="请填写电子邮箱"></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item prop="consignee" label="收件人" :rules="[{required:true,message:'请填写收件人'}]">
            <el-input v-model="formData.consignee" autocomplete="off" placeholder="请填写收件人"></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="收件人电话"
                        :rules="[{required:true,message:'请填写收件人电话'},{pattern:$regexp.mobile,message:'请填写正确的收件人电话'}]">
            <el-input v-model="formData.phone" autocomplete="off" placeholder="请填写收件人电话"></el-input>
          </el-form-item>
          <el-form-item prop="address" label="收件地址" :rules="[{required:true,message:'请填写收件地址'}]">
            <el-input v-model="formData.address" autocomplete="off" placeholder="请填写收件地址"></el-input>
          </el-form-item>
        </template>
        <div v-show="showMore" v-if="formData.open_type === 'company'">
          <el-form-item prop="company_adr" label="单位地址(选填)">
            <el-input v-model="formData.company_adr" autocomplete="off" placeholder="请填写单位地址(选填)"></el-input>
          </el-form-item>
          <el-form-item prop="company_phone" label="单位电话(选填)">
            <el-input v-model="formData.company_phone" autocomplete="off" placeholder="请填写单位电话(选填)"></el-input>
          </el-form-item>
          <el-form-item prop="company_bank" label="开户银行(选填)">
            <el-input v-model="formData.company_bank" autocomplete="off" placeholder="请填写开户银行(选填)"></el-input>
          </el-form-item>
          <el-form-item prop="company_account" label="银行账户(选填)">
            <el-input v-model="formData.company_account" autocomplete="off" placeholder="请填写银行账户(选填)"></el-input>
          </el-form-item>
        </div>
        <el-divider class="hand" @click="showMore = !showMore" v-if="formData.open_type === 'company'">{{ showMore ? '隐藏' : '显示更多' }}
	        <i class="margin-left-5" :class="showMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
        </el-divider>
        <!--          <div class="text-center hand" @click="showMore = !showMore">显示更多</div>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showTicket = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </lee-user-main-page>
</template>

<script>
export default {
  name: "BuyLog",
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 20,
      total: 0,
	
	    activeName:'pay',

      showMore: false,
      showTicket: false,
      formData: {
        open_type: 'person',
        type: 'email',
        address: '',
        number: '',
        consignee: '',
        phone: '',
        program: '',
        title: '',
        email: '',
        company_adr: '',
        company_phone: '',
        company_bank: '',
        company_account: '',
        akey: '',
        tid: '',
	      pay_amount:''
      },
      rules: {
        program: [{required: true, message: '请填写发票事项'}],
        title: [{required: true, message: '请填写发票名称'}]
      }
    }
  },
  created() {
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
  },
  mounted() {
    this.getList();
    this.getInvoice();
    this.getBase();
  },
  methods: {
	  handleClick(tab, event) {
		  console.log(tab.name);
			if(tab.name === 'pay'){
				this.page = 1;
				this.getList();
			}else if(tab.name === 'view'){
				this.page = 1;
				this.getViewList();
			}else if(tab.name === 'down'){
				this.page = 1;
				this.getDownList();
			}
	  },
    onDialogClose() {
      this.formData.open_type = 'person';
      this.formData.type = 'email';
      this.formData.address = '';
      this.formData.number = '';
      this.formData.consignee = '';
      this.formData.phone = '';
      this.formData.program = '';
      this.formData.title = '';
      this.formData.email = '';
      this.formData.company_adr = '';
      this.formData.company_phone = '';
      this.formData.company_bank = '';
      this.formData.company_account = '';
      this.formData.akey = '';
      this.formData.tid = '';
			this.formData.pay_amount = '';

      this.getInvoice();
      this.getBase();
    },

    submit() {
      let _this = this;
      this.$refs['form'].validate(valid => {
        if (valid) {

          let _form = JSON.parse(JSON.stringify(_this.formData));

          if (_form.type === 'email') {
            _form.address = '';
            _form.consignee = '';
            _form.phone = '';
          } else {
            _form.email = '';
          }

          _this.$http.post({
            url: '/UserInvoiceLog/add',
            data: _form,
            success(res) {
              console.log(res);
              _this.$message.success('发票申请提交成功');
              _this.showTicket = false;
              _this.getList();
            }
          });
        }
      });
    },

    getBase() {
      let _this = this;
      this.$http.post({
        url: '/UserProfile/getBase',
        data: null,
        success(res) {
          _this.formData.email = res.email;
        }
      });
    },
    getInvoice() {
      let _this = this;
      this.$http.post({
        url: '/UserInvoice/detail',
        data: null,
        success(res) {
          console.log(res);
          _this.formData.address = res.address;
          _this.formData.consignee = res.consignee;
          _this.formData.phone = res.phone;
          _this.formData.program = res.program;
          _this.formData.title = res.title;
          _this.formData.number = res.number;
        }
      });
    },
    toOpenTicket(e) {
      console.log(e);
      this.formData.tid = e.ext_id;
      this.formData.akey = e.order_type;
			this.formData.pay_amount = e.pay_amount;
      this.showTicket = true;
    },
    getList() {
      let _this = this;
      this.$http.post({
        url: '/User/getMyOrder',
        data: {page: this.page},
        success(res) {
          _this.list = res.list;
          _this.total = res.totalCount;
        }
      });
    },
	  getViewList(){
		  let _this = this;
		  this.$http.post({
			  url: '/UserLog/listPage',
			  data: {page: this.page,uid:this.$user.getUid(),type:'unlock_view'},
			  success(res) {
				  _this.list = res.list;
				  _this.total = res.totalCount;
			  }
		  });
	  },
	  getDownList(){
		  let _this = this;
		  this.$http.post({
			  url: '/UserLog/listPage',
			  data: {page: this.page,uid:this.$user.getUid(),type:'down'},
			  success(res) {
				  _this.list = res.list;
				  _this.total = res.totalCount;
			  }
		  });
	  },
    pageChange(page) {
      this.page = page;
      this.$router.replace({query: {page: this.page}});
    },
  }
}
</script>
